import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Sentry from "@sentry/react";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
const states = ["ACT", "NSW", "VIC", "SA", "TAS", "NT", "WA"];
class AdminCateringScreenMultiplePacks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: "",
      isSuperAdminLoggedIn:
        getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
        getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
        getFromLocalStorage("isSuperAdminLoggedIn") != null
          ? getFromLocalStorage("isSuperAdminLoggedIn")
          : "false",
      isAccountantLoggedIn:
        getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
        getFromLocalStorage("isAccountantLoggedIn") != "" &&
        getFromLocalStorage("isAccountantLoggedIn") != null
          ? getFromLocalStorage("isAccountantLoggedIn")
          : "false",
      isStockAdminLoggedIn:
        getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
        getFromLocalStorage("isStockAdminLoggedIn") != "" &&
        getFromLocalStorage("isStockAdminLoggedIn") != null
          ? getFromLocalStorage("isStockAdminLoggedIn")
          : "false",
      isEventCoordinatorLoggedIn:
        getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
        getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
        getFromLocalStorage("isEventCoordinatorLoggedIn") != null
          ? getFromLocalStorage("isEventCoordinatorLoggedIn")
          : "false",
      email:
        getFromLocalStorage("email") != "undefined" &&
        getFromLocalStorage("email") != "" &&
        getFromLocalStorage("email") != null
          ? getFromLocalStorage("email")
          : "",
      password:
        getFromLocalStorage("password") != "undefined" &&
        getFromLocalStorage("password") != "" &&
        getFromLocalStorage("password") != null
          ? getFromLocalStorage("password")
          : "",
      bookingRequests: [],
      tempBookingRequests: [],
      filteredRequests: [],
      searchBooking: "",
      inviteVendorModal: true,
      vendors: [],
      valueForMoneyVendors: [],
      mostPrefferedVendors: [],
      nonCuisineVendors: [],
      tempNonCuisineVendors: [],
      tempVendors: [],
      availableStatus: [
        "pending",
        "awaiting_voting_results",
        "awaiting_vendors_response",
        "quoted_awaiting_customer_response",
        "rejected_by_vendor",
        "updated_by_vendor",
        "awaiting_admin_approval",
        "finalised",
        "canceled",
      ],
      selectedBookingNewStatus: "",
      selectedBooking: null,
      isOpenBookingStatusChangeModal: false,
      isOpenBookingStatusChangeModal: false,
      isLoadingChangeBookingStatus: false,
      isLoadingChangeBookingStatus: false,
      isLoadingSendingVendorRequest: false,
      vendorCateringPacks: [],
      valueForMoneyCateringPacks: [],
      mostPreferredCateringPacks: [],
      selectedCateringPack: {},
      mostPreferredCateringPack: {},
      valueForMoneyCateringPack: {},
      requestSent: [],
      isOpenCustomerEmailDetails: false,
      requestSentIds: [],
      isLoadingVendor: false,
      searchVendor: "",
      searchNonVendor: "",
      emails: [],
      searchNonCuisineVendor: "",
      vendorListSearchDisplay: "none",
      nonCuisineVendorListSearchDisplay: "none",
      selectedCuisine: "",
      adminNotesChanged: [],
      isLoading: false,
      showPassword: false,
      requestState: "pending",
      startDate:
        getFromLocalStorage("startDate") != "undefined" &&
        getFromLocalStorage("startDate") != "" &&
        getFromLocalStorage("startDate") != null &&
        getFromLocalStorage("startDate").toString() != "Invalid Date"
          ? new Date(getFromLocalStorage("startDate"))
          : new Date(),
      endDate:
        getFromLocalStorage("endDate") != "undefined" &&
        getFromLocalStorage("endDate") != "" &&
        getFromLocalStorage("endDate") != null &&
        getFromLocalStorage("endDate").toString() != "Invalid Date"
          ? new Date(getFromLocalStorage("endDate"))
          : new Date(),
      completed_payments: 0,
      resetFilter:
        getFromLocalStorage("resetFilter") != "undefined" &&
        getFromLocalStorage("resetFilter") != "" &&
        getFromLocalStorage("resetFilter") != null
          ? getFromLocalStorage("resetFilter")
          : "false",
      pageSelected: 1,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn, isEventCoordinatorLoggedIn } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isEventCoordinatorLoggedIn === "true"
    ) {
      this.getDashboardData(this.state.requestState);
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getVendors = (cuisine, booking_request_id) => {
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
          cuisine: cuisine,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          dataResponse.vendors.sort(function(a, b) {
            var nameA = a.title,
              nameB = b.title;
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.setState({
            vendors: dataResponse.vendors,
            valueForMoneyVendors: dataResponse.vendors,
            mostPrefferedVendors: dataResponse.vendors,
            tempVendors: dataResponse.vendors,
            nonCuisineVendors: dataResponse.non_cuisine_vendors,
            tempNonCuisineVendors: dataResponse.non_cuisine_vendors,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  saveAdminNotes = (item, notes) => {
    const { email } = this.state;
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_admin_notes",
          booking_request_id: item.id,
          admin_notes: notes,
          email: email,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          alert("Saved Successfully!");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingVendor: false });
      });
  };

  sendRequestToVendor(booking_request_id, user_ptr_id) {
    this.setState({ isLoadingSendingVendorRequest: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_request_to_vendor",
          user_ptr_id: user_ptr_id,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingSendingVendorRequest: false });
        if (dataResponse.success == true) {
          alert("Sent Successfully!");
          var arr = this.state.requestSentIds;
          arr.push(user_ptr_id);
          this.setState({ requestSentIds: arr });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoadingSendingVendorRequest: false });
      });
  }

  getDashboardData = (requestState) => {
    const { startDate, endDate, resetFilter, pageSelected } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_booking_requests",
          request_state: requestState,
          start_date: moment(startDate).format("DD MMM YYYY"),
          end_date: moment(endDate).format("DD MMM YYYY"),
          reset: resetFilter,
          pageSelected: pageSelected,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({
            bookingRequests: dataResponse.booking_requests,
            tempBookingRequests: dataResponse.booking_requests,
            filteredRequests: dataResponse.booking_requests,
            completed_payments: dataResponse.completed_payments,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  onChangeBookingStatus = (e, booking) => {
    this.setState({ isLoadingChangeBookingStatus: true });
    var newStatus = e.target.value;
    if (newStatus == "finalised") {
      if (booking.payment_status != "complete") {
        alert(
          "Error! Booking cannot be finalised until payment is completed by the customer."
        );
        return;
      }
    }
    this.setState(
      {
        selectedBooking: booking,
        selectedBookingNewStatus: e.target.value,
        isOpenBookingStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeBookingStatus: false });
      }
    );
  };

  onChangeBookingStatusOnServer = (bookingId, bookingStatus) => {
    this.setState({ isLoadingChangeBookingStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_request_status",
          booking_request_id: bookingId,
          status: bookingStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeBookingStatus: false });

        this.setState({ isOpenBookingStatusChangeModal: false });
        if (dataResponse.success) {
          alert(dataResponse.message);
          window.location.reload();
        } else {
          alert("Error! Please try again.");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenBookingStatusChangeModal: false,
          isLoadingChangeBookingStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  getVendorCateringPacks = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ vendorCateringPacks: dataResponse.catering_packs });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };
  getVendorCateringPacks2 = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({
            valueForMoneyCateringPacks: dataResponse.catering_packs,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };
  getVendorCateringPacks3 = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({
            mostPreferredCateringPacks: dataResponse.catering_packs,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };
  getVendorCateringPacks2 = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({
            vendorCateringPacks: dataResponse.catering_packs,
            valueForMoneyCateringPacks: dataResponse.catering_packs,
            mostPreferredCateringPacks: dataResponse.catering_packs,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };
  getVendorCateringPacks3 = (vendorId, selectedBooking) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_catering_packs",
          vendor_id: vendorId,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({
            vendorCateringPacks: dataResponse.catering_packs,
            valueForMoneyCateringPacks: dataResponse.catering_packs,
            mostPreferredCateringPacks: dataResponse.catering_packs,
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleChange(event) {
    const selectedState = event.target.value;
    let tempBookingRequests = this.state.bookingRequests;
    if (selectedState) {
      tempBookingRequests = this.state.bookingRequests.filter((item) =>
        item.location.includes(selectedState)
      );
    }
    this.setState({ selectedState, tempBookingRequests });
  }

  sendEmailToCustomer = (
    selectedBooking,
    selectedCateringPack,
    mostPreferredCateringPack,
    valueForMoneyCateringPack
  ) => {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_email_to_customerv2",
          booking_request_id: selectedBooking.id,
          catering_pack_id: selectedCateringPack.id,
          catering_pack_id_2: mostPreferredCateringPack.id,
          catering_pack_id_3: valueForMoneyCateringPack.id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Email has been sent to the customer.");
          this.setState({
            isOpenCustomerResponseModal: false,
            reviewEmail: false,
          });
        } else {
          alert("Something went wrong! Please try again");

          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        alert("Something went wrong! Please try again");
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  setAdminNotes = (item, notes, index) => {
    let start = new Date().valueOf();
    const { bookingRequests, adminNotesChanged } = this.state;
    let tempNotesChanged = adminNotesChanged;
    let tempBookingRequest = bookingRequests;
    tempBookingRequest[index].admin_notes = notes;
    tempNotesChanged.push(tempBookingRequest[index].id);

    this.setState(
      {
        bookingRequests: tempBookingRequest,
        adminNotesChanged: tempNotesChanged,
      },
      () => {
        let end = new Date().valueOf();
      }
    );
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isEventCoordinatorLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      tempBookingRequests,
      searchBooking,
      availableStatus,
      isLoadingChangeBookingStatus,
      adminNotesChanged,
      isLoading,
      requestState,
      startDate,
      endDate,
      completed_payments,
      pageSelected,
    } = this.state;
    const { selectedState } = this.state;

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
                minHeight: "73.5vh",
                position: "relative",
              }}
            >
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <b>Filter</b>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      onChange={(e) => {
                        this.setState({ requestState: e.target.value });
                        this.getDashboardData(e.target.value);
                      }}
                      defaultValue={requestState}
                    >
                      <option value={"all"}>All</option>
                      <option value={"canceled"}>Canceled</option>
                      <option value={"pending"}>Pending</option>
                      <option value={"quoted_awaiting_customer_response"}>
                        Quoted, Awaiting Customer's Reponse
                      </option>
                      <option value={"finalised"}>Finalised</option>
                      <option value={"awaiting_vendors_response"}>Paid</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <b>Requested Event Date:</b>
                  <Row style={{ marginTop: 10 }}>
                    <Col xs={12} md={4}>
                      <p>Start Date</p>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={startDate}
                        onChange={(date) => this.setStartDate(date)}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <p>End Date</p>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={endDate}
                        onChange={(date) => this.setEndDate(date)}
                      />
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      <p>&nbsp;</p>
                      <Button
                        onClick={() => {
                          this.setState({ resetFilter: "false" }, () => {
                            saveToLocalStorage(
                              "resetFilter",
                              this.state.resetFilter.toString()
                            );
                            this.getDashboardData(this.state.requestState);
                          });
                        }}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={8} md={4} lg={4}>
                  {this.state.resetFilter == "false" ? (
                    <p>
                      By clicking this button, you can reset all filters and it
                      will show you all the booking request in reverser
                      chronoligical order.
                    </p>
                  ) : (
                    <p>
                      Showing booking requests in the order they were made,
                      filters are not applied. To apply filter you can change
                      the Requested Event Date.
                    </p>
                  )}
                  {this.state.resetFilter == "true" ? (
                    <p>
                      <b>
                        Note: When filters are not set, it will only show you
                        details of all upcoming events. It wont show you events
                        whose dates have passed.
                      </b>
                    </p>
                  ) : null}
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button
                    onClick={() => {
                      this.setState({ resetFilter: "true" }, () => {
                        saveToLocalStorage(
                          "resetFilter",
                          this.state.resetFilter.toString()
                        );
                        this.getDashboardData(this.state.requestState);
                      });
                    }}
                  >
                    {this.state.resetFilter == "true"
                      ? "Filters are not Set"
                      : "Reset Filters"}
                  </Button>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  borderWidth: 1,
                  borderColor: "black",
                  borderStyle: "solid",
                }}
              >
                <Col xs={12} md={6} lg={6}>
                  <h4>Total Completed Payments</h4>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h4>{completed_payments}</h4>
                </Col>
              </Row>
              <InputGroup className="mb-3">
                <InputGroup.Text value={searchBooking}>
                  <img
                    style={{ width: 20 }}
                    src={require("../../assets/img/search.gif")}
                  ></img>
                </InputGroup.Text>
                <FormControl
                  placeholder="Search Customers"
                  aria-label="Search for customers"
                  onChange={(e) => {
                    var searchString = e.target.value;
                    if (searchString == "") {
                      this.setState({
                        tempBookingRequests: this.state.bookingRequests,
                      });
                    } else {
                      var result = tempBookingRequests.filter((obj) => {
                        if (
                          obj.name.search(new RegExp(searchString, "i")) != -1
                        ) {
                          return obj;
                        }
                      });
                      this.setState({ tempBookingRequests: result });
                    }
                  }}
                />
              </InputGroup>
              <Row className="table-header-row">
                <Col xs={12} md={1} lg={1} className="table-header-col">
                  Booking Request #
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Date & Time
                </Col>
                <Col xs={12} md={3} lg={3} className="table-header-col">
                  Location
                  <div>
                    <select value={selectedState} onChange={this.handleChange}>
                      <option value="">All States</option>
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Customer Name
                </Col>
                <Col xs={12} md={1} lg={1} className="table-header-col">
                  Payment Status
                </Col>
                <Col xs={12} md={3} lg={3} className="table-header-col">
                  Request Status
                </Col>
              </Row>
              {tempBookingRequests.map((item, requestIndex) => {
                let cuisine_array = [...new Set(item.cuisine_array)];
                return (
                  <Accordion key={item.id}>
                    <>
                      <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                        <Row className="table-row">
                          <Col xs={12} md={1} lg={1} className="table-col">
                            <span>
                              <b className="mobile-only">Booking request #:</b>{" "}
                              {item.id}
                            </span>
                          </Col>

                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Date & Time:</b>
                              {item.start_date +
                                " - " +
                                item.start_date +
                                " , " +
                                item.start_time +
                                " - " +
                                item.end_time}
                            </span>
                          </Col>
                          <Col xs={12} md={3} lg={3} className="table-col">
                            <span>
                              <b className="mobile-only">Location:</b>
                              {item.location}
                            </span>
                          </Col>
                          <Col xs={12} md={2} lg={2} className="table-col">
                            <span>
                              <b className="mobile-only">Customer Name:</b>
                              {item.name}
                            </span>
                          </Col>

                          <Col
                            xs={12}
                            md={1}
                            lg={1}
                            className="table-col"
                            style={{ padding: 1 }}
                          >
                            <span>
                              <b className="mobile-only">Payment Status:</b>
                              {item.payment_status}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            md={3}
                            lg={3}
                            className="table-col"
                            style={{ textAlign: "center" }}
                          >
                            <Row style={{ width: "80%", margin: "auto" }}>
                              <span>
                                <b className="mobile-only">Status:</b>
                                <>
                                  {isLoadingChangeBookingStatus ? (
                                    <div style={{ textAlign: "center" }}>
                                      <Spinner animation="border" role="status">
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </Spinner>
                                    </div>
                                  ) : (
                                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                      {item.status !=
                                      "awaiting_vendors_response" ? (
                                        <Form.Control
                                          as="select"
                                          size="sm"
                                          custom
                                          onChange={(e) => {
                                            this.onChangeBookingStatus(e, item);
                                          }}
                                          defaultValue={item.status}
                                        >
                                          {availableStatus.map((status, i) => {
                                            return (
                                              <option key={i} value={status}>
                                                {status}
                                              </option>
                                            );
                                          })}
                                        </Form.Control>
                                      ) : (
                                        <div>Paid</div>
                                      )}
                                    </Form.Group>
                                  )}
                                </>
                              </span>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                              <Col xs={12} md={12} lg={12}>
                                <span style={{ fontWeight: "bold" }}>
                                  Notes
                                </span>
                                {adminNotesChanged.find(
                                  (element) => element == item.id
                                ) ? (
                                  <span
                                    onClick={() => {
                                      this.saveAdminNotes(
                                        item,
                                        item.admin_notes
                                      );
                                    }}
                                    style={{ color: "red", marginLeft: 10 }}
                                  >
                                    SAVE
                                  </span>
                                ) : null}

                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  value={item.admin_notes}
                                  onChange={(e) => {
                                    this.setAdminNotes(
                                      item,
                                      e.target.value,
                                      requestIndex
                                    );
                                  }}
                                />
                                {item.admin_user__first_name != null &&
                                item.admin_notes != "" ? (
                                  <Form.Text className="text-muted">
                                    Last updated by{" "}
                                    {item.admin_user__first_name +
                                      " " +
                                      item.admin_user__last_name}
                                  </Form.Text>
                                ) : null}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Accordion.Collapse
                          eventKey={item.id}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Number of Guests
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.number_of_guests}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Requested Cuisines
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {cuisine_array.map((cuisine) => {
                                  return (
                                    <span
                                      onClick={() => {
                                        this.setState({
                                          inviteVendorModal: true,
                                          selectedRequest: item,
                                          selectedCuisine: cuisine,
                                        });
                                        this.getVendors(cuisine, item.id);
                                      }}
                                      style={{
                                        borderWidth: 1,
                                        borderColor: "black",
                                        borderStyle: "solid",
                                        padding: 5,
                                        marginLeft: 5,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {cuisine}
                                      <img
                                        style={{
                                          width: 20,
                                          marginLeft: 10,
                                          cursor: "pointer",
                                        }}
                                        src={require("../../assets/img/invite.png")}
                                      ></img>
                                    </span>
                                  );
                                })}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Event Type
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.event_type}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Event Type
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.customer_event_type}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Name
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.name}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Phone
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.phone}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Email
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.email}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Customer Budget
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.customer_budget}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Power Requirements
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.site_power_conditions}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Date Created
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.date_created}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Payment Status
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                {item.payment_status}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6} lg={6}>
                                Respond to this customer
                              </Col>
                              <Col xs={6} md={6} lg={6}>
                                <Button
                                  style={{
                                    width: "100%",
                                    marginBottom: 20,
                                    marginTop: 10,
                                    backgroundColor: "#ef3f6d",
                                  }}
                                  className="button-enabled-pink-style"
                                  onClick={() => {
                                    this.getVendors(item.cuisine, item.id);
                                    this.setState({
                                      isOpenCustomerResponseModal: true,
                                      selectedBooking: item,
                                    });
                                  }}
                                >
                                  Create and Send Response
                                </Button>
                                {item.atleast_one_email_sent ? (
                                  <>
                                    <p>
                                      This customer has already received a
                                      response from WTT Team.
                                    </p>
                                    <Button
                                      style={{
                                        width: "50%",
                                        marginBottom: 20,
                                        marginTop: 10,
                                        backgroundColor: "transparent",
                                        color: "black",
                                        borderColor: "transparent",
                                        padding: 0,
                                        textAlign: "left",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          isOpenCustomerEmailDetails: true,
                                          selectedBooking: item,
                                        });
                                      }}
                                    >
                                      Click here to see the details
                                    </Button>
                                  </>
                                ) : null}
                              </Col>
                            </Row>
                            {item.payment_status == "complete" ? (
                              <>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Source
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_source}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Receipt Number
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_receipt_number}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Date
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_date}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Time
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    {item.payment_time}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} md={6} lg={6}>
                                    Payment Total Amount
                                  </Col>
                                  <Col xs={6} md={6} lg={6}>
                                    $
                                    {Number(item.payment_total_amount).toFixed(
                                      2
                                    )}
                                  </Col>
                                </Row>
                              </>
                            ) : null}
                          </>
                        </Accordion.Collapse>
                      </Accordion.Toggle>
                    </>
                  </Accordion>
                );
              })}
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col xs={2} md={2} lg={2}>
                  <p>
                    <b>Showing Page:</b>
                  </p>
                </Col>
                <Col xs={10} md={10} lg={10} style={{ textAlign: "center" }}>
                  <div style={{ width: "98%" }}>
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected - 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                      <Pagination.Item active>{pageSelected}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => {
                          this.setState(
                            { pageSelected: this.state.pageSelected + 1 },
                            () => {
                              this.getDashboardData(this.state.requestState);
                            }
                          );
                        }}
                      />
                    </Pagination>
                  </div>
                </Col>
              </Row>
              {this.renderInviteModal()}
              {this.renderBookingStatusChangeModal()}
              {this.renderCustomerResponseModal()}
              {this.renderCustomerEmailModal()}
            </Container>
            {/* <Footer /> */}
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }

  renderInviteModal() {
    const {
      inviteVendorModal,
      selectedRequest,
      vendors,
      searchVendor,
      searchNonVendor,
      isLoadingSendingVendorRequest,
      nonCuisineVendors,
      isLoadingVendor,
      selectedCuisine,
    } = this.state;
    var vendorList = vendors;
    var nonCuisineVendorList = nonCuisineVendors;

    return (
      <>
        {selectedRequest && selectedRequest.id ? (
          <Modal
            show={inviteVendorModal}
            onHide={() => {
              this.setState({ inviteVendorModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Send Request to Vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              {!isLoadingVendor ? (
                <>
                  <Accordion>
                    <Accordion.Toggle as={Card.Header} eventKey={1}>
                      <>
                        <InputGroup className="mb-3">
                          <InputGroup.Text value={searchVendor}>
                            <img
                              style={{ width: 20 }}
                              src={require("../../assets/img/search.gif")}
                            ></img>
                          </InputGroup.Text>
                          <FormControl
                            placeholder="Search for vendors"
                            aria-label="Search for vendors"
                            onChange={(e) => {
                              var searchString = e.target.value;
                              if (searchString == "") {
                                this.setState({
                                  vendors: this.state.tempVendors,
                                });
                              } else {
                                var result = vendors.filter((obj) => {
                                  if (
                                    obj.title.search(
                                      new RegExp(searchString, "i")
                                    ) != -1
                                  ) {
                                    return obj;
                                  }
                                });
                                this.setState({ vendors: result });
                              }
                            }}
                          />
                        </InputGroup>
                        <p style={{ textTransform: "capitalize" }}>
                          <b>Cuisine: {selectedCuisine}</b>
                        </p>
                        <p>
                          Found {vendorList.length} vendors. Click here to view
                        </p>
                        <Accordion.Collapse
                          eventKey={1}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <div>
                              {vendorList.map((vendor, index) => {
                                return (
                                  <Row
                                    style={{
                                      textAlign: "left",
                                      backgroundColor: "#ededed",
                                      marginBottom: 10,
                                    }}
                                  >
                                    <Col xs={6} md={6} lg={6}>
                                      <p>
                                        {vendor.title}{" "}
                                        <span>
                                          <b>
                                            {"(" +
                                              vendor.catering_packs +
                                              " catering packs in the system)"}
                                          </b>
                                        </span>{" "}
                                      </p>
                                      <p>{vendor.email}</p>
                                      <p>{vendor.address}</p>
                                      <p>{vendor.phone}</p>
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ textAlign: "right" }}
                                    >
                                      {isLoadingSendingVendorRequest ? (
                                        <Spinner
                                          animation="border"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </Spinner>
                                      ) : vendor.email_sent ||
                                        this.state.requestSentIds.find(
                                          (element) =>
                                            element == vendor.user_ptr_id
                                        ) ? (
                                        <p>
                                          Sent on{" "}
                                          {vendor.email_sent_on ||
                                            moment(new Date()).format(
                                              "DD MMM YYYY hh:mm A"
                                            )}
                                        </p>
                                      ) : (
                                        <Button
                                          style={{
                                            width: "100%",
                                            marginBottom: 20,
                                            marginTop: 10,
                                            backgroundColor: "#ef3f6d",
                                          }}
                                          className="button-enabled-pink-style"
                                          onClick={() => {
                                            this.sendRequestToVendor(
                                              selectedRequest.id,
                                              vendor.user_ptr_id
                                            );
                                          }}
                                        >
                                          Send
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          </>
                        </Accordion.Collapse>
                      </>
                    </Accordion.Toggle>

                    <Accordion.Toggle as={Card.Header} eventKey={2}>
                      <>
                        <InputGroup className="mb-3">
                          <InputGroup.Text value={searchNonVendor}>
                            <img
                              style={{ width: 20 }}
                              src={require("../../assets/img/search.gif")}
                            ></img>
                          </InputGroup.Text>
                          <FormControl
                            placeholder="Search for vendors"
                            aria-label="Search for vendors"
                            onChange={(e) => {
                              var searchStringNon = e.target.value;
                              if (searchStringNon == "") {
                                this.setState({
                                  nonCuisineVendors: this.state
                                    .tempNonCuisineVendors,
                                });
                              } else {
                                var resultNon = nonCuisineVendors.filter(
                                  (obj) => {
                                    if (
                                      obj.title.search(
                                        new RegExp(searchStringNon, "i")
                                      ) != -1
                                    ) {
                                      return obj;
                                    }
                                  }
                                );
                                this.setState({ nonCuisineVendors: resultNon });
                              }
                            }}
                          />
                        </InputGroup>
                        <p>
                          <b>Cuisine: Others</b>
                        </p>
                        <p>
                          Found {nonCuisineVendorList.length} vendors. Click
                          here to view
                        </p>
                        <Accordion.Collapse
                          eventKey={2}
                          style={{ padding: 20, backgroundColor: "#eff2f5" }}
                        >
                          <>
                            <div>
                              {nonCuisineVendorList.map((vendor, index) => {
                                return (
                                  <Row
                                    style={{
                                      textAlign: "left",
                                      backgroundColor: "#ededed",
                                      marginBottom: 10,
                                    }}
                                  >
                                    <Col xs={6} md={6} lg={6}>
                                      <p>
                                        {vendor.title}{" "}
                                        <span>
                                          <b>
                                            {"(" +
                                              vendor.catering_packs +
                                              " catering packs in the system)"}
                                          </b>
                                        </span>{" "}
                                      </p>
                                      <p>{vendor.email}</p>
                                      <p>{vendor.address}</p>
                                      <p>{vendor.phone}</p>
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ textAlign: "right" }}
                                    >
                                      {isLoadingSendingVendorRequest ? (
                                        <Spinner
                                          animation="border"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </Spinner>
                                      ) : vendor.email_sent ||
                                        this.state.requestSentIds.find(
                                          (element) =>
                                            element == vendor.user_ptr_id
                                        ) ? (
                                        <p>
                                          Sent on{" "}
                                          {vendor.email_sent_on ||
                                            moment(new Date()).format(
                                              "DD MMM YYYY hh:mm A"
                                            )}
                                        </p>
                                      ) : (
                                        <Button
                                          style={{
                                            width: "100%",
                                            marginBottom: 20,
                                            marginTop: 10,
                                            backgroundColor: "#ef3f6d",
                                          }}
                                          className="button-enabled-pink-style"
                                          onClick={() => {
                                            this.sendRequestToVendor(
                                              selectedRequest.id,
                                              vendor.user_ptr_id
                                            );
                                          }}
                                        >
                                          Send
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          </>
                        </Accordion.Collapse>
                      </>
                    </Accordion.Toggle>
                  </Accordion>
                </>
              ) : (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Modal.Body>
          </Modal>
        ) : null}
      </>
    );
  }

  renderCustomerEmailModal() {
    const { isOpenCustomerEmailDetails, selectedBooking } = this.state;
    return (
      <>
        {selectedBooking && selectedBooking != null ? (
          <Modal
            show={isOpenCustomerEmailDetails}
            onHide={() => {
              this.setState({ isOpenCustomerEmailDetails: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Customer Email Details</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              {selectedBooking.email_details.map((email) => {
                return (
                  <div
                    style={{ textAlign: "left", backgroundColor: "#f7f7f7" }}
                  >
                    <p>Vendor: {email.vendor}</p>
                    <p>Catering Pack Name: {email.catering_pack_name}</p>
                    <p>
                      Catering Pack Price per person: $
                      {email.catering_pack_price}
                    </p>
                  </div>
                );
              })}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }

  renderBookingStatusChangeModal() {
    const {
      isOpenBookingStatusChangeModal,
      selectedBooking,
      selectedBookingNewStatus,
      isLoadingChangeBookingStatus,
    } = this.state;

    return (
      <>
        {selectedBooking != null && selectedBookingNewStatus != "" ? (
          <Modal
            show={isOpenBookingStatusChangeModal}
            onHide={() => {
              this.setState({ isOpenBookingStatusChangeModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change Booking Request #{selectedBooking.id} to{" "}
                {selectedBookingNewStatus}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              <p>
                Are you sure you want to change Booking Request #
                {selectedBooking.id} to {selectedBookingNewStatus}?
              </p>
            </Modal.Body>

            <Modal.Footer>
              {isLoadingChangeBookingStatus ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      this.onChangeBookingStatusOnServer(
                        selectedBooking.id,
                        selectedBookingNewStatus
                      );
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isOpenBookingStatusChangeModal: false });
                    }}
                  >
                    No
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }

  renderCustomerResponseModal() {
    const {
      isOpenCustomerResponseModal,
      selectedBooking,
      isLoadingCustomerResponse,
      vendors,
      mostPrefferedVendors,
      valueForMoneyVendors,
      vendorCateringPacks,
      selectedCateringPack,
      valueForMoneyCateringPacks,
      mostPreferredCateringPacks,
      reviewEmail,
      mostPreferredCateringPack,
      valueForMoneyCateringPack,
    } = this.state;
    let min_fee = 0;
    let sub_total = 0;
    let min_call_out_fee = 0;
    if (selectedCateringPack && selectedBooking) {
      min_fee =
        Number(selectedCateringPack.package_price) *
        Number(selectedCateringPack.package_people);
      sub_total =
        Number(selectedCateringPack.package_price) *
        Number(selectedBooking.number_of_guests);
    }
    if (min_fee - sub_total > 0) {
      min_call_out_fee = min_fee - sub_total;
    }

    return (
      <>
        {selectedBooking != null ? (
          <Modal
            show={isOpenCustomerResponseModal}
            onHide={() => {
              this.setState({ isOpenCustomerResponseModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {!reviewEmail
                  ? "Sending Message to Customer"
                  : "Review Email and Send"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              {!reviewEmail ? (
                <>
                  <p>
                    You are about to send a response to the customer regarding
                    his booking request. Please confirm and fill in the details
                    below to send the response.
                  </p>
                  <div style={{ textAlign: "left" }}>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Name
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Phone
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.phone}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Customer Email
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.email}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Booking Request Id
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.id}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Cuisine
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.cuisine}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Number of guests
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        {selectedBooking.number_of_guests}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Select the vendor
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                          <Form.Control
                            as="select"
                            size="sm"
                            custom
                            onChange={(e) => {
                              let vendor1_pack = e.target.value;
                              this.getVendorCateringPacks(
                                vendor1_pack,
                                selectedBooking
                              );
                            }}
                          >
                            <option key={0} value={-1}>
                              {"Select 1st a vendor"}
                            </option>
                            {vendors.map((vendor, index) => {
                              return (
                                <option key={index} value={vendor.user_ptr_id}>
                                  {vendor.title}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {vendorCateringPacks.length > 0 ? (
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          Select the catering pack
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                            <Form.Control
                              as="select"
                              size="sm"
                              custom
                              onChange={(e) => {
                                this.setState({
                                  selectedCateringPack:
                                    vendorCateringPacks[e.target.value],
                                });
                              }}
                            >
                              <option key={0} value={-1}>
                                {"Select a catering pack"}
                              </option>
                              {vendorCateringPacks.map((pack, index) => {
                                return (
                                  <option
                                    style={{ whiteSpace: "pre-line" }}
                                    key={index}
                                    value={index}
                                  >
                                    {pack.package_name +
                                      "\n$" +
                                      pack.package_price +
                                      "/person" +
                                      "(For min " +
                                      pack.package_people +
                                      " people )"}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          The selected vendor hasn't uploaded any catering packs
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Select 2nd the vendor
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                          <Form.Control
                            as="select"
                            size="sm"
                            custom
                            onChange={(e) => {
                              let vendor2_pack = e.target.value;
                              this.getVendorCateringPacks2(
                                vendor2_pack,

                                selectedBooking
                              );
                            }}
                          >
                            <option key={0} value={-1}>
                              {"Select a vendor"}
                            </option>
                            {valueForMoneyVendors.map((vendor, index) => {
                              return (
                                <option key={index} value={vendor.user_ptr_id}>
                                  {vendor.title}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {valueForMoneyCateringPacks.length > 0 ? (
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          Select the catering pack
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                            <Form.Control
                              as="select"
                              size="sm"
                              custom
                              onChange={(e) => {
                                this.setState({
                                  valueForMoneyCateringPack:
                                    valueForMoneyCateringPacks[e.target.value],
                                });
                              }}
                            >
                              <option key={0} value={-1}>
                                {"Select a catering pack"}
                              </option>
                              {valueForMoneyCateringPacks.map((pack, index) => {
                                return (
                                  <option
                                    style={{ whiteSpace: "pre-line" }}
                                    key={index}
                                    value={index}
                                  >
                                    {pack.package_name +
                                      "\n$" +
                                      pack.package_price +
                                      "/person" +
                                      "(For min " +
                                      pack.package_people +
                                      " people )"}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          The selected vendor hasn't uploaded any catering packs
                        </Col>
                      </Row>
                    )}

                    {/* Third Pack */}
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        Select 3rd the vendor
                      </Col>
                      <Col xs={6} md={6} lg={6}>
                        <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                          <Form.Control
                            as="select"
                            size="sm"
                            custom
                            onChange={(e) => {
                              let vendor3_pack = e.target.value;
                              this.getVendorCateringPacks3(
                                vendor3_pack,

                                selectedBooking
                              );
                            }}
                          >
                            <option key={0} value={-1}>
                              {"Select a vendor"}
                            </option>
                            {mostPrefferedVendors.map((vendor, index) => {
                              return (
                                <option key={index} value={vendor.user_ptr_id}>
                                  {vendor.title}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {mostPreferredCateringPacks.length > 0 ? (
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          Select the catering pack
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                            <Form.Control
                              as="select"
                              size="sm"
                              custom
                              onChange={(e) => {
                                this.setState({
                                  mostPreferredCateringPack:
                                    mostPreferredCateringPacks[e.target.value],
                                });
                              }}
                            >
                              <option key={0} value={-1}>
                                {"Select a catering pack"}
                              </option>
                              {mostPreferredCateringPacks.map((pack, index) => {
                                return (
                                  <option
                                    style={{ whiteSpace: "pre-line" }}
                                    key={index}
                                    value={index}
                                  >
                                    {pack.package_name +
                                      "\n$" +
                                      pack.package_price +
                                      "/person" +
                                      "(For min " +
                                      pack.package_people +
                                      " people )"}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          The selected vendor hasn't uploaded any catering packs
                        </Col>
                      </Row>
                    )}

                    {!this.isEmpty(selectedCateringPack) ? (
                      <>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            1st Catering Pack Name
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {selectedCateringPack.package_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Catering Pack Description
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {selectedCateringPack.package_description}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Price per person
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {"$" + selectedCateringPack.package_price}
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    {!this.isEmpty(mostPreferredCateringPack) ? (
                      <>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            2nd Catering Pack Name
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {mostPreferredCateringPack.package_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Catering Pack Description
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {mostPreferredCateringPack.package_description}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Price per person
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {"$" + mostPreferredCateringPack.package_price}
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    {!this.isEmpty(valueForMoneyCateringPack) ? (
                      <>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            3rd Catering Pack Name
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {valueForMoneyCateringPack.package_name}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Catering Pack Description
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {valueForMoneyCateringPack.package_description}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6} lg={6}>
                            Price per person
                          </Col>
                          <Col xs={6} md={6} lg={6}>
                            {"$" + valueForMoneyCateringPack.package_price}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <p>Dear {selectedBooking.name}</p>
                  <p>
                    Thank you for your request. We have contacted some of the
                    best food trucks and based on the your requirements we found
                    the best quote for you. Following are the details -{" "}
                  </p>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Catering Pack Name
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {selectedCateringPack.package_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Catering Pack Description
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {selectedCateringPack.package_description}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Number of Guests
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {Number(selectedBooking.number_of_guests)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Sub Total
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {"$" + Number(sub_total).toFixed(2)}
                    </Col>
                  </Row>
                  {Number(min_fee) - Number(sub_total) > 0 ? (
                    <OverlayTrigger
                      placement="top-start"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          This pack is for min{" "}
                          {selectedCateringPack.package_people} people so a min
                          callout fee will be applied to your order
                        </Tooltip>
                      }
                    >
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          Min Call Out Fee:
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          {"$" + Number(min_call_out_fee).toFixed(2)}
                        </Col>
                      </Row>
                    </OverlayTrigger>
                  ) : null}
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Total:
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      {"$" + Number(min_call_out_fee + sub_total).toFixed(2)}
                    </Col>
                  </Row>
                  <p>We are holding this booking for the next 7 days.</p>
                  <p>
                    If you have any questions please do not hesitate to email me
                    back or call me on 0411738771
                  </p>
                  <p>Regards</p>
                  <p>Alana Tarev</p>
                  <p>Customer Support</p>
                  <p>WTT Team</p>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              {isLoadingCustomerResponse ? (
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {!reviewEmail ? (
                    <Button
                      style={{
                        width: "100%",
                        marginBottom: 20,
                        marginTop: 10,
                        backgroundColor: "#ef3f6d",
                      }}
                      className="button-enabled-pink-style"
                      onClick={() => {
                        if (
                          !this.isEmpty(selectedCateringPack) &&
                          vendorCateringPacks.length > 0
                        ) {
                          this.setState({ reviewEmail: true });
                        } else {
                          alert(
                            "Please select a vendor and a catering pack to send the email to the customer!"
                          );
                        }
                      }}
                    >
                      Review and Send
                    </Button>
                  ) : (
                    <>
                      <Button
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure? This will send an email to the customer with the above details"
                            )
                          ) {
                            this.sendEmailToCustomer(
                              selectedBooking,
                              selectedCateringPack,
                              mostPreferredCateringPack,
                              valueForMoneyCateringPack
                            );
                          } else {
                          }
                        }}
                      >
                        Send
                      </Button>

                      <Button
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          marginTop: 10,
                          backgroundColor: "#ef3f6d",
                        }}
                        className="button-enabled-pink-style"
                        onClick={() => {
                          this.setState({ reviewEmail: false });
                        }}
                      >
                        Back
                      </Button>
                    </>
                  )}
                </>
              )}
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminCateringScreenMultiplePacks, {
    fallback: <ErrorFallbackComponent />,
  })
);
