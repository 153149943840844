import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react/";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ProfileImage from "@daym3l/react-profile-image";
import Media from "react-bootstrap/Media";
import ImageUploader from "react-images-upload";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

function VendorCateringEditScreen(props) {
  const onDrop = (pictureFiles, pictureDataURLs) => {
    set_pack_image(pictureDataURLs);
  };
  const [pack_name, set_pack_name] = useState(props.menu.meal.package_name);
  const [pack_price, set_pack_price] = useState(props.menu.meal.package_price);
  const [min_guests, set_min_guests] = useState(props.menu.meal.package_people);
  const [pack_id, set_pack_id] = useState(props.menu.meal.id);
  const [pack_image, set_pack_image] = useState([]);
  const [description, set_description] = useState(
    props.menu.meal.package_description
  );
  const [cuisine_type, set_cuisine_type] = useState(props.menu.meal.cuisine);
  const [catering_type, set_catering_type] = useState(
    props.menu.meal.catering_pack_type
  );
  const [availableTypes, setAvailableTypes] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [availableCuisines, setAvailableCuisines] = useState([]);

  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get-available-cuisine",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setAvailableCuisines(response.availableCuisines);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const getAvailableTypes = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.operatorData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_catering_pack_type",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          setAvailableTypes(response.catering_pack_types);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const postAddCatering = async (values) => {
    values.preventDefault();
    if (
      pack_name === "" ||
      pack_price === "" ||
      min_guests === "" ||
      cuisine_type === "" ||
      description === "" ||
      cuisine_type === ""
    ) {
      alert("Please Enter all the Fields");
    } else {
      const url = config.BASE_URI + apiPaths.operatorData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "update_catering_pack",
            pack_name: pack_name,
            pack_images: pack_image,
            min_guests: min_guests,
            description: description,
            image: pack_image,
            pack_price: parseFloat(pack_price),
            cuisine_type: cuisine_type,
            catering_type: parseFloat(catering_type),
            vendor_id: props.stallId,
            catering_pack_id: pack_id,
          },
        },
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.success == true) {
            alert("Catering Pack updated!");
            window.location.reload();
          } else {
            alert("Please enter all the fields");
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          alert(
            "Editing Catering Pack was unsuccessful, Please try after some time"
          );
        });
    }
  };
  const handlePackNameChange = (event) => {
    const inputValue = event.target.value;
    set_pack_name(inputValue);
  };
  const handlePackPriceChange = (event) => {
    const inputValue = event.target.value;
    set_pack_price(inputValue);
  };
  const handlePackImageChange = (event) => {
    set_pack_image(event.target.files[0]);
  };
  const handleDescriptionChange = (event) => {
    const inputValue = event.target.value;
    set_description(inputValue);
  };
  const handleCuisineTypeChange = (event) => {
    set_cuisine_type(event.target.value);
  };
  const handleGuestChange = (event) => {
    set_min_guests(event.target.value);
  };
  const handleCateringTypeChange = (event) => {
    set_catering_type(event.target.value);
  };
  useEffect(() => {
    getAvailableCuisines();
    getAvailableTypes();
  }, []);

  return (
    <Container>
      <div className="form-style-5">
        <form onSubmit={postAddCatering}>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Pack Name:"
                type="text"
                id="pack_name"
                name="pack_name"
                value={pack_name}
                onChange={handlePackNameChange}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Pack Price:"
                type="number"
                id="pack_price"
                name="pack_price"
                value={pack_price}
                onChange={handlePackPriceChange}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={6} md={6} sm={12}>
              <select defaultValue="" onChange={handleCuisineTypeChange}>
                {availableCuisines.map((cuisine) => {
                  return (
                    <option
                      selected={cuisine_type == cuisine.cuisine ? true : false}
                      value={cuisine.cuisine}
                    >
                      {cuisine.cuisine}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Minumum Guests:"
                type="number"
                id="min_guests"
                name="min_guests"
                value={min_guests}
                onChange={handleGuestChange}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select value={catering_type} onChange={handleCateringTypeChange}>
                <option value="" disabled>
                  Select catering type
                </option>
                {availableTypes &&
                  availableTypes.map((type) => {
                    return (
                      <option
                        selected={catering_type == type.id ? true : false}
                        value={type.id}
                      >
                        {type.name}
                      </option>
                    );
                  })}
              </select>
            </Col>
          </Row>
          <label htmlFor="logoFile">Upload the Pack Image:</label>
          <ImageUploader
            withIcon={true}
            buttonText="Choose images"
            onChange={onDrop}
            withPreview={true}
            imgExtension={[".jpg", ".jpeg", ".png"]}
            maxFileSize={5242880}
            label={"Upload catering pack images. Max img size 5mb"}
            buttonStyles={{
              backgroundColor: "#2859a5",
              color: "white",
              fontWeight: "unset",
            }}
          />
          <Row
            style={{
              paddingBottom: 10,
              marginTop: 10,
              borderBottomWidth: 0,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderColor: "black",
              borderStyle: "solid",
            }}
          >
            <Col xs={12} md={12} lg={12}>
              <textarea
                placeholder="Description:"
                type="text"
                rows="4"
                cols="75"
                value={description}
                onChange={handleDescriptionChange}
              ></textarea>
            </Col>
          </Row>
          <div style={{ width: "100%", textAlign: "right" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default Sentry.withErrorBoundary(VendorCateringEditScreen, {
  fallback: <ErrorFallbackComponent />,
});
