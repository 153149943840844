import React, { Component } from "react";
import {
  CURRENCY,
  TRANSACTION_FEE,
  EVENT_FEE,
  APP_FEE,
  KITCHACO_DELIVERY_FEE,
  SQUARE_ACCESS_TOKEN,
  SQUARE_API_VERSION,
  PAYMENT_URL,
  EMAIL_ORDER_NOTIFICATIONS,
  SQUARE_APP_ID,
  LOCATION_ID,
  AWS_URL,
} from "../../../assets/Constants";
import { payment, orderNow } from "../../../actions";
import moment from "moment";
import {
  store,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../../store/index";
import { applyToken } from "../../../services/api";

import { apiPaths } from "../../../services/apiPath";
import config from "../../../services/apiConfig";
import { Button, Row, Col, Card, Container, Carousel } from "react-bootstrap";
import "./style.css";

const styles = {
  name: {
    verticalAlign: "top",
    display: "none",
    margin: 0,
    borderWidth: 1,
    borderColor: "#e8e9ea",
    fontSize: "16px",
    fontFamily: "Helvetica Neue",
    padding: "16px",
    color: "#373F4A",
    backgroundColor: "transparent",
    lineHeight: "1.15em",
    placeholderColor: "#000",
    _webkitFontSmoothing: "antialiased",
    _mozOsxFontSmoothing: "grayscale",
    marginRight: 10,
    width: 290,
  },
  leftCenter: {
    float: "left",
    textAlign: "center",
    color: "black",
  },
  blockRight: {
    display: "block",
    float: "right",
  },
  center: {
    textAlign: "center",
  },
};

export default class PaymentForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardBrand: "",
      nonce: undefined,
      googlePay: false,
      applePay: false,
      masterpass: false,
      paymentStatusState: "",
      paymentSourceState: "",
      paymentReceiptNumberState: "",
      paymentErrorState: "",
      paymentErrorDetailsState: "",
      paymentWttChargesState: 0.0,
      paymentTxnChargesState: 0.0,
      paymentTotalChargesState: 0.0,
      paymentTotalCharges: 0.0,
      deliveryCharges: 0.0,
      isPaymentLoading: false,
      isOrderSuccessfull: false,
      selectedVendor: "",
      paymentDetails: {},
    };
    this.requestCardNonce = this.requestCardNonce.bind(this);
  }

  componentWillMount() {
    this.getPaymentData();
  }

  requestCardNonce() {
    if (this.state.isPaymentLoading == false) {
      this.setState({ isPaymentLoading: true }, () => {
        this.paymentForm.requestCardNonce();
      });
    }
  }

  makePayment = (
    amount_money,
    source_id,
    idempotency_key,
    currency,
    app_fee_money
  ) => {
    let data = {
      url: PAYMENT_URL,
      token: SQUARE_ACCESS_TOKEN,
      square_api_version: SQUARE_API_VERSION,
      crossDomain: true,
      method: "POST",
      body: JSON.stringify({
        source_id: source_id,
        idempotency_key: idempotency_key,
        amount_money: {
          amount: amount_money,
          currency: currency,
        },
        app_fee_money: {
          amount: app_fee_money,
          currency: currency,
        },
        autocomplete: true,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + SQUARE_ACCESS_TOKEN,
        "Square-Version": SQUARE_API_VERSION,
      },
    };

    this.props.dispatch(payment(data)).then((response) => {
      var paymentResponse = response.payload;

      if (paymentResponse != undefined) {
        if (
          paymentResponse.payment != undefined &&
          paymentResponse.payment.status != "CANCELLED" &&
          paymentResponse.payment.status != "FAILED"
        ) {
          this.setState(
            {
              paymentStatusState: paymentResponse.payment.status,
              paymentSourceState: paymentResponse.payment.source_type,
              paymentReceiptNumberState: paymentResponse.payment.receipt_number,
              paymentWttChargesState:
                paymentResponse.payment.app_fee_money.amount / 100,
              paymentTxnChargesState:
                Math.round(TRANSACTION_FEE * amount_money * 100) / 100 / 100,
              paymentTotalChargesState:
                paymentResponse.payment.total_money.amount / 100,
              paymentError: "",
            },
            () => {
              this.confirmBooking();
            }
          );
        } else {
          var error = "Payment Error! Please try again.";
          if (paymentResponse.errors[0].code === "INVALID_EXPIRATION") {
            error = "Invalid Card Expiry. Please try again!";
          } else if (paymentResponse.errors[0].code === "GENERIC_DECLINE") {
            error = "Card Declined. Please try again!";
          } else if (paymentResponse.errors[0].code === "CVV_FAILURE") {
            error = "Invalid CVV. Please try again!";
          }
          this.setState(
            { paymentError: "FAILED", paymentErrorDetails: error },
            () => {
              window.location.reload(true);
              alert(
                "Payment failed! Please check your card details and try again"
              );
            }
          );
          alert(error);
        }
      }
    });
  };

  confirmBooking = () => {
    const {
      paymentStatusState,
      paymentSourceState,
      paymentTotalChargesState,
      paymentReceiptNumberState,
      paymentDetails,
    } = this.state;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;
    var start_date_time =
      moment(
        paymentDetails.booking_request_details.start_date,
        "DD MMM YYYY"
      ).format("YYYY-MM-DD") +
      "T" +
      moment(
        paymentDetails.booking_request_details.start_time,
        "hh:mm A"
      ).format("HH:mm");
    var end_date_time =
      moment(
        paymentDetails.booking_request_details.end_date,
        "DD MMM YYYY"
      ).format("YYYY-MM-DD") +
      "T" +
      moment(paymentDetails.booking_request_details.end_time, "hh:mm A").format(
        "HH:mm"
      );

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "confirm-booking",
          event_type: paymentDetails.booking_request_details.event_type,
          name: paymentDetails.booking_request_details.name,
          email: paymentDetails.booking_request_details.email,
          phone: paymentDetails.booking_request_details.phone,
          event_location: paymentDetails.booking_request_details.location,
          event_start_date_time: start_date_time,
          event_end_date_time: end_date_time,
          number_of_guests:
            paymentDetails.booking_request_details.number_of_guests,
          pack_id: paymentDetails.catering_pack_details.id,
          cuisine: paymentDetails.booking_request_details.cuisine,
          booking_request_id: paymentDetails.booking_request_details.id,
          payment_status: paymentStatusState,
          payment_source: paymentSourceState,
          payment_receipt_number: paymentReceiptNumberState,
          payment_total_amount: paymentTotalChargesState,
          payment_date: moment(new Date()).format("DD MMM YYYY"),
          payment_time: moment(new Date()).format("hh:mm A"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          alert(
            "Your payment was successfull and booking details have been submitted successfully. We have sent you the details on your email"
          );
          deleteFromLocalStorage("bookingName", "");
          deleteFromLocalStorage("bookingEmail", "");
          deleteFromLocalStorage("bookingLocation", "");
          deleteFromLocalStorage("bookingPhone", "");
          deleteFromLocalStorage("bookingNumberOfGuests", 0);
          deleteFromLocalStorage("bookingStartDateTime", "");
          deleteFromLocalStorage("bookingEndDateTime", "");
          deleteFromLocalStorage("bookingEventType", "");
          deleteFromLocalStorage("bookingRequestId", "");
          this.setState({
            isOrderSuccessfull: true,
            selectedVendor: response.vendor_name,
          });
        }
      });
  };

  placeOrder = async (event, paymentMethod, shouldVerify = false) => {
    if (this.state.isPaymentLoading == false) {
      this.setState({ isPaymentLoading: true });
    }
    const cardButton = document.getElementById("card-button");
    const {
      user,
      total,
      deliveryCharges,
      orderType,
      ticketState,
      productsState,
    } = this.props;
    const { paymentDetails } = this.state;
    try {
      //cardButton.disabled = true
      const token = await this.tokenize(paymentMethod);
      let source_id = token;
      let idempotency_key = "" + user + "" + Date.now();
      let currency = CURRENCY;

      let amount_money = paymentDetails.total;

      //PAYMENT STEP 3 - Convert the amount_money to cents
      amount_money = parseInt(
        (parseFloat(Math.round(amount_money * 100) / 100) * 100).toFixed(2)
      ); // rounded in cents

      // PAYMENT STEP 4 - Calculate the Kitchaco App Fee by adding txn fee and commission
      // make sure this is also in cents

      var app_fee_money = (TRANSACTION_FEE + APP_FEE) * amount_money;

      // PAYMENT STEP 5 - Round up app_fee_money upto 2 decimal places.
      app_fee_money = parseInt(Math.round(app_fee_money * 100) / 100); // rounded (no need to do cents becuase amount is already in cents);

      // PAYMENT STEP 6 - Add delivery commission if ordertype = delivery
      if (orderType == "delivery") {
        app_fee_money += KITCHACO_DELIVERY_FEE;
      }

      const paymentResults = await this.makePayment(
        amount_money,
        source_id,
        idempotency_key,
        currency,
        app_fee_money
      );
    } catch (e) {
      cardButton.disabled = false;
      console.error(e.message);
    }
  };

  componentDidMount() {
    this.onLoad();

    const config = {
      applicationId: SQUARE_APP_ID,
      locationId: LOCATION_ID,
      inputClass: "sq-input",
      autoBuild: false,
      inputStyles: [
        {
          fontSize: "16px",
          fontFamily: "Helvetica Neue",
          padding: "16px",
          color: "#373F4A",
          backgroundColor: "transparent",
          lineHeight: "1.15em",
          placeholderColor: "#000",
          _webkitFontSmoothing: "antialiased",
          _mozOsxFontSmoothing: "grayscale",
        },
      ],
      applePay: {
        elementId: "sq-apple-pay",
      },
      masterpass: {
        elementId: "sq-masterpass",
      },
      googlePay: {
        elementId: "sq-google-pay",
      },
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "• • • •  • • • •  • • • •  • • • •",
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV",
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "MM/YY",
      },
      postalCode: {
        elementId: "sq-postal-code",
        placeholder: "Post Code",
      },
      callbacks: {
        methodsSupported: (methods) => {
          if (methods.googlePay) {
            this.setState({
              googlePay: methods.googlePay,
            });
          }
          if (methods.applePay) {
            this.setState({
              applePay: methods.applePay,
            });
          }
          if (methods.masterpass) {
            this.setState({
              masterpass: methods.masterpass,
            });
          }
          return;
        },
        createPaymentRequest: () => {
          return {
            requestShippingAddress: false,
            requestBillingInfo: true,
            currencyCode: "AUD",
            countryCode: "AU",
            total: {
              label: "MERCHANT NAME",
              amount: "100",
              pending: false,
            },
            lineItems: [
              {
                label: "Subtotal",
                amount: "100",
                pending: false,
              },
            ],
          };
        },
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          const { paymentDetails } = this.state;
          if (errors) {
            errors.forEach(function(error) {});

            return;
          }
          // PAYMENT STEP 1 - Initialise
          var source_id = nonce;
          var idempotency_key = "" + paymentDetails.customer + "" + Date.now();
          var currency = CURRENCY;

          var amount_money = Number(paymentDetails.total);

          //PAYMENT STEP 3 - Convert the amount_money to cents
          amount_money = parseInt(
            (parseFloat(Math.round(amount_money * 100) / 100) * 100).toFixed(2)
          ); // rounded in cents

          // PAYMENT STEP 4 - Calculate the Kitchaco App Fee by adding txn fee and commission
          // make sure this is also in cents

          var app_fee_money = (TRANSACTION_FEE + APP_FEE) * amount_money;

          // PAYMENT STEP 5 - Round up app_fee_money upto 2 decimal places.
          app_fee_money = parseInt(Math.round(app_fee_money * 100) / 100); // rounded (no need to do cents becuase amount is already in cents);

          // PAYMENT STEP 6 - Add delivery commission if ordertype = delivery

          this.makePayment(
            amount_money,
            source_id,
            idempotency_key,
            currency,
            app_fee_money
          );

          this.setState({
            nonce: nonce,
          });
        },
        unsupportedBrowserDetected: () => {},
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case "focusClassAdded":
              break;
            case "focusClassRemoved":
              break;
            case "errorClassAdded":
              document.getElementById("error").innerHTML =
                "Please fix card information errors before continuing.";
              break;
            case "errorClassRemoved":
              document.getElementById("error").style.display = "none";
              break;
            case "cardBrandChanged":
              if (inputEvent.cardBrand !== "unknown") {
                this.setState({
                  cardBrand: inputEvent.cardBrand,
                });
              } else {
                this.setState({
                  cardBrand: "",
                });
              }
              break;
            case "postalCodeChanged":
              break;
            default:
              break;
          }
        },
        paymentFormLoaded: function() {
          document.getElementById("name").style.display = "inline-flex";
        },
      },
    };

    // this.paymentForm = new this.props.paymentForm(config);
    // this.paymentForm.build();
  }

  onLoad = async () => {
    if (!this.props.paymentForm) {
      throw new Error("Square.js failed to load properly");
    }
    let payments;
    try {
      payments = this.props.paymentForm;
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }
    let card;
    try {
      card = await this.initializeCard(payments);
    } catch (e) {
      console.error("initialize card error!", e);
      return;
    }
  };
  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach("#card-container");
    this.setState({
      card: card,
    });
    return card;
  }

  async tokenize(card) {
    const tokenResult = await card.tokenize();
    if (tokenResult.status === "OK") {
      console.log("tokenize result ", tokenResult);
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  getPaymentData = () => {
    var payment_hash = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.bookATruck;

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_payment_details",
          payment_hash: payment_hash,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({ paymentDetails: response.payment_details });
        } else {
          alert(response.error);
          // window.location.href = "/book-a-truck"
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const { isPaymentLoading, paymentDetails, isOrderSuccessfull } = this.state;

    return (
      <Container>
        {!isOrderSuccessfull ? (
          <Row>
            {!this.isEmpty(paymentDetails) ? (
              <Col xs={12} md={6} lg={6} style={{ marginTop: 20 }}>
                <Carousel>
                  {paymentDetails.catering_pack_details.images.length != 0
                    ? paymentDetails.catering_pack_details.images.map(
                        (pack) => {
                          return (
                            <Carousel.Item>
                              <Card>
                                <Card.Body
                                  style={{
                                    maxHeight: 150,
                                    overflow: "hidden",
                                    textAlign: "center",
                                  }}
                                >
                                  <Card.Img
                                    style={{ height: 150, width: "unset" }}
                                    src={AWS_URL + "/" + pack.image}
                                    alt="First slide"
                                  />
                                </Card.Body>
                              </Card>
                            </Carousel.Item>
                          );
                        }
                      )
                    : null}
                </Carousel>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Booking Request ID
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Pack Name
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.catering_pack_details.package_name}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Pack Details
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.catering_pack_details.package_description}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Cuisine
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.cuisine}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Number of Guests
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.number_of_guests}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Pack Price/Person
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    ${paymentDetails.catering_pack_details.package_price}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Sub Total
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    ${paymentDetails.sub_total}
                  </Col>
                </Row>
                {Number(paymentDetails.min_call_out_fee) > 0 ? (
                  <Row>
                    <Col xs={6} md={6} lg={6}>
                      Min Call Out Fee
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      ${paymentDetails.min_call_out_fee}
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Total
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    ${paymentDetails.total}
                  </Col>
                </Row>
                <p>&nbsp;</p>
                <h4>Customer Details</h4>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Customer Name
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.name}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Function Location
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.location}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Function Start Date Time
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.start_date}{" "} {paymentDetails.booking_request_details.start_time}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Function End Date Time
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.end_date}{" "} {paymentDetails.booking_request_details.end_time}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    Customer Phone
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    {paymentDetails.booking_request_details.phone}
                  </Col>
                </Row>
              </Col>
            ) : null}
            <Col xs={12} md={6} lg={6} style={{ marginTop: 20 }}>
              <p className="left-margin">
                Please enter your payment details below to pay and finalise the
                payment
              </p>
              <div className="checkout-container">
                <div id="form-container">
                  <div id="card-container"></div>
                  <div id="cc-field-wrapper">
                    <button
                      className="button-credit-card"
                      id="card-button"
                      onClick={(e) =>
                        this.placeOrder(e, this.state.card, false)
                      }
                    >
                      {isPaymentLoading == true ? "Loading..." : "Pay"}
                    </button>
                  </div>
                </div>

                <p style={styles.center} id="error"></p>
              </div>
            </Col>
          </Row>
        ) : (
          this.renderPaymentConfirmation()
        )}
      </Container>
    );
  }
  renderPaymentConfirmation() {
    const { paymentDetails } = this.state;
    return (
      <Card
        style={{
          height: 500,
          marginTop: 20,
          overflowY: "scroll",
          border: "none",
        }}
      >
        <Card.Body variant="bottom" style={{ padding: 5 }}>
          <Card.Title></Card.Title>
          <Row>
            <Col xs={12} style={{ textAlign: "center" }}>
              <img
                src={require("../../../assets/img/check.png")}
                className="rounded mr-2"
                alt="successfull"
              />
              <h3>
                Thank you for selecting{" "}
                {paymentDetails.booking_request_details.cuisine} Food Truck.
                Your payment is successful and we are just confirming the food
                truck availablity.
              </h3>
              <Button
                onClick={() => {
                  window.location.href = "/book-a-truck";
                }}
                block
                className="button-enabled-pink-style"
                style={{ marginTop: 20, backgroundColor: "#ef3f6d" }}
              >
                Book another truck
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
