import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import "date-fns";
import AdminNavBar from "../../components/UI/AdminNavBar/index.js";
import Footer from "../../components/UI/Footer";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import SearchBarVendor from "../../components/SearchBarVendor";
import SearchResultsListVendor from "../../components/SearchResultsListVendor";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import config from "../../services/apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from "../../services/apiPath";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import Accordion from "react-bootstrap/Accordion";

class AdminSalesReportByEvent extends Component {
  state = {
    searchVendor: "",
    filteredVendors: [],
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") != ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    adminExternalOperator:
      getFromLocalStorage("adminExternalOperator") != "undefined" &&
      getFromLocalStorage("adminExternalOperator") != "" &&
      getFromLocalStorage("adminExternalOperator") != null
        ? getFromLocalStorage("adminExternalOperator")
        : 0,
    adminSalesInvoiceFortnightPeriod:
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "undefined" &&
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "" &&
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != null
        ? getFromLocalStorage("adminSalesInvoiceFortnightPeriod")
        : 0,
    externalOperators: [],
    salesInvoiceFornightPeriod: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    salesReport: [],
    isLoading: false,
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    grossKitchacoInStoreCashSales: 0,
    grossKitchacoInStoreCardSales: 0,
    kitchaco_commission_percentage: 0,
    grossKitchacoOnlineDeliverySales: 0,
    grossKitchacoOnlinePickupSales: 0,
    grossUberEatsSales: 0,
    grossDoordashSales: 0,
    grossDeliverooSales: 0,
    grossMenulogSales: 0,
    grossStockPurchased: 0,
    gstStockPurchased: 0,
    netStockPurchased: 0,
    adminAdjustmentStockPurchased: 0,
    deliveryChargeStockPurchased: 0,
    labelMiscCharges1: "",
    grossMiscCharges1: 0,
    netMiscCharges1: 0,
    gstMiscCharges1: 0,

    isGstMiscCharges1: false,
    labelMiscCharges2: "",
    grossMiscCharges2: 0,
    netMiscCharges2: 0,
    gstMiscCharges2: 0,
    overallObject: {
      overallWttEarnings: 0,
      overallWttGst: 0,
      overallVendorPayout: 0,
      overallCashCommission: 0,
      overallCardCommission: 0,
      overallVoucherCommission: 0,
    },

    isGstMiscCharges2: false,
    labelMiscCharges3: "",
    grossMiscCharges3: 0,
    netMiscCharges3: 0,
    gstMiscCharges3: 0,

    isGstMiscCharges3: false,
    labelMiscCharges4: "",
    grossMiscCharges4: 0,
    netMiscCharges4: 0,
    gstMiscCharges4: 0,

    isGstMiscCharges4: false,
    labelMiscCharges5: "",
    grossMiscCharges5: 0,
    isGstMiscCharges5: false,
    netMiscCharges5: 0,
    gstMiscCharges5: 0,

    in_store_cash_commission: 0,
    in_store_card_commission: 0,
    online_pickup_commission: 0,
    online_delivery_commission: 0,
    uber_eats_commission: 0,
    doordash_commission: 0,
    deliveroo_commission: 0,
    menulog_commission: 0,

    sendEmailAs: "wtt",
    approved_by: "",
    approved_on: "",

    grossKitchacoInStoreCashSalesArray: [],
    grossKitchacoInStoreCardSalesArray: [],
    grossKitchacoOnlineDeliverySalesArray: [],
    grossKitchacoOnlinePickupSalesArray: [],
    grossUberEatsSalesArray: [],
    grossDoordashSalesArray: [],
    grossDeliverooSalesArray: [],
    grossMenulogSalesArray: [],
    salesApprovedInvoiceDetails: [],
    errorMessage: "Please select a fortnight period above to show reports",
    lessCashSalesSelectedCheckbox: true,
    lessCardSalesSelectedCheckbox: false,
    eventsListDuringDatePeriod: [],
  };

  componentDidMount() {
    const { isSuperAdminLoggedIn, isAccountantLoggedIn } = this.state;
    if (isSuperAdminLoggedIn === "true" || isAccountantLoggedIn === "true") {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    var endDate = moment(date).add(13, "days");
    this.setState({ endDate: endDate }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  onChangeExternalOperator(e) {
    this.setState({ adminExternalOperator: e.target.value }, () => {
      saveToLocalStorage(
        "adminExternalOperator",
        this.state.adminExternalOperator
      );
      this.getSalesReport();
    });
  }

  onChangeDatePeriod(e) {
    this.setState({ adminSalesInvoiceFortnightPeriod: e.target.value }, () => {
      const {
        salesInvoiceFornightPeriod,
        adminSalesInvoiceFortnightPeriod,
      } = this.state;
      try {
        var startDate = null;
        var endDate = null;
        // Iterate through the salesInvoiceFornightPeriod array
        for (var i = 0; i < salesInvoiceFornightPeriod.length; i++) {
          // Check if the current object's id matches adminSalesInvoiceFortnightPeriodId
          if (
            salesInvoiceFornightPeriod[i].id == adminSalesInvoiceFortnightPeriod
          ) {
            // If there's a match, store the start and end dates
            startDate = salesInvoiceFornightPeriod[i].start_date;
            endDate = salesInvoiceFornightPeriod[i].end_date;
            // Break out of the loop since we found a match
            break;
          }
        }
        this.setState({ startDate: startDate }, () => {
          saveToLocalStorage("startDate", this.state.startDate);
        });
        // var endDate = moment(date).add(6, "days");
        this.setState({ endDate: endDate }, () => {
          saveToLocalStorage("endDate", this.state.endDate);
          saveToLocalStorage(
            "adminSalesInvoiceFortnightPeriod",
            adminSalesInvoiceFortnightPeriod
          );
          this.getSalesReport();
        });
      } catch (e) {
        this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
          saveToLocalStorage(
            "adminSalesInvoiceFortnightPeriod",
            adminSalesInvoiceFortnightPeriod
          );
        });
      }
    });
  }

  getSalesReport() {
    const {
      startDate,
      endDate,
      selectedVendorId,
      adminSalesInvoiceFortnightPeriod,
    } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      query_type: "get_sales_report",
      vendor: parseInt(selectedVendorId),
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      event: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success) {
          this.setState(
            {
              salesReport: dataResponse.sales_data,
              grossStockPurchased: 0,
              netStockPurchased: 0,
              gstStockPurchased: 0,
              grossKitchacoOnlinePickupSales:
                dataResponse.gross_kitchaco_online_pickup_sales,
              adminAdjustmentStockPurchased: 0,
              deliveryChargeStockPurchased: 0,
              in_store_cash_commission:
                dataResponse.commission_rate_data.in_store_cash_commission,
              in_store_card_commission:
                dataResponse.commission_rate_data.in_store_card_commission,
              online_pickup_commission:
                dataResponse.commission_rate_data.online_pickup_commission,
              online_delivery_commission:
                dataResponse.commission_rate_data.online_delivery_commission,
              uber_eats_commission: 0,
              doordash_commission: 0,
              deliveroo_commission: 0,
              menulog_commission: 0,
              eventsListDuringDatePeriod:
                dataResponse.events_list_during_date_period,
            },
            () => {
              const { salesReport } = this.state;

              var grossUberEatsSales = 0;
              var grossDoordashSales = 0;
              var grossDeliverooSales = 0;
              var grossMenulogSales = 0;
              for (var i = 0; i < salesReport.length; i++) {
                const {
                  grossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlinePickupSalesArray,
                  grossKitchacoOnlineDeliverySalesArray,
                  grossUberEatsSalesArray,
                  grossDoordashSalesArray,
                  grossDeliverooSalesArray,
                  grossMenulogSalesArray,
                } = this.state;
                var deliveroo_adjustment = 0;
                if (!isNaN(salesReport[i].deliveroo_adjustments)) {
                  deliveroo_adjustment = salesReport[i].deliveroo_adjustments;
                }
                grossUberEatsSales = 0;
                grossDoordashSales = 0;
                grossDeliverooSales = 0;
                grossMenulogSales = 0;
                var tempGrossKitchacoInStoreCashSalesArray = grossKitchacoInStoreCashSalesArray;
                var tempGrossKitchacoInStoreCardSalesArray = grossKitchacoInStoreCardSalesArray;
                var tempGrossOnlinePickupSalesArray = grossKitchacoOnlinePickupSalesArray;
                var tempGrossOnlineDeliverySalesArray = grossKitchacoOnlineDeliverySalesArray;
                var tempGrossUberEatsSalesArray = grossUberEatsSalesArray;
                var tempGrossDoordashSalesArray = grossDoordashSalesArray;
                var tempGrossDeliverooSalesArray = grossDeliverooSalesArray;
                var tempGrossMenulogSalesArray = grossMenulogSalesArray;

                tempGrossKitchacoInStoreCashSalesArray[i] =
                  salesReport[i].in_store_cash_sales;
                tempGrossKitchacoInStoreCardSalesArray[i] =
                  salesReport[i].in_store_card_sales;
                tempGrossOnlinePickupSalesArray[i] =
                  salesReport[i].online_pickup_sales;
                tempGrossOnlineDeliverySalesArray[i] =
                  salesReport[i].online_delivery_sales;
                tempGrossUberEatsSalesArray[i] =
                  salesReport[i].uber_total_sales;
                tempGrossDoordashSalesArray[i] =
                  salesReport[i].doordash_total_sales;
                tempGrossDeliverooSalesArray[i] =
                  Number(salesReport[i].deliveroo_total_sales) +
                  Number(salesReport[i].deliveroo_adjustments);
                tempGrossMenulogSalesArray[i] =
                  salesReport[i].menulog_total_sales;
              }
              this.setState({
                grossUberEatsSales: grossUberEatsSales,
                grossDoordashSales: grossDoordashSales,
                grossDeliverooSales: grossDeliverooSales,
                grossMenulogSales: grossMenulogSales,
                grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
                grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
                grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
                grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
                grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
                grossDoordashSalesArray: tempGrossDoordashSalesArray,
                grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
                grossMenulogSalesArray: tempGrossMenulogSalesArray,
              });

              if (
                dataResponse.sales_data[0].status != "unavailable" &&
                dataResponse.sales_data[0].status != "generated"
              ) {
                this.setState(
                  {
                    grossKitchacoInStoreCashSales: Number(
                      dataResponse.sales_data[0].in_store_cash_sales
                    ).toFixed(2),
                    grossKitchacoInStoreCardSales: Number(
                      dataResponse.sales_data[0].in_store_card_sales
                    ).toFixed(2),
                    grossKitchacoOnlinePickupSales: Number(
                      dataResponse.sales_data[0].total_voucher_sale
                    ).toFixed(2),
                    grossKitchacoOnlineDeliverySales: 0,
                    // totalRevenueForLocation: Number(dataResponse.sales_data[0].total_revenue).toFixed(2),
                    grossKitchacoInStoreCashFee: Number(
                      dataResponse.sales_data[0].wtt_cash_commission
                    ).toFixed(2),
                    grossKitchacoInStoreCardFee: Number(
                      dataResponse.sales_data[0].wtt_card_commission
                    ).toFixed(2),
                    grossKitchacoOnlinePickupFee: Number(
                      dataResponse.sales_data[0].wtt_voucher_commission
                    ).toFixed(2),
                    approved_by: dataResponse.approved_by,
                    approved_on: dataResponse.approved_on,
                    labelMiscCharges1:
                      dataResponse.sales_data[0].label_misc_charges1,
                    grossMiscCharges1:
                      dataResponse.sales_data[0].gross_misc_charges1,
                    gstMiscCharges1:
                      dataResponse.sales_data[0].gst_misc_charges1,
                    netMiscCharges1:
                      dataResponse.sales_data[0].net_misc_charges1,

                    labelMiscCharges2:
                      dataResponse.sales_data[0].label_misc_charges2,
                    grossMiscCharges2:
                      dataResponse.sales_data[0].gross_misc_charges2,
                    gstMiscCharges2:
                      dataResponse.sales_data[0].gst_misc_charges2,
                    netMiscCharges2:
                      dataResponse.sales_data[0].net_misc_charges2,

                    labelMiscCharges3:
                      dataResponse.sales_data[0].label_misc_charges3,
                    grossMiscCharges3:
                      dataResponse.sales_data[0].gross_misc_charges3,
                    gstMiscCharges3:
                      dataResponse.sales_data[0].gst_misc_charges3,
                    netMiscCharges3:
                      dataResponse.sales_data[0].net_misc_charges3,

                    labelMiscCharges4:
                      dataResponse.sales_data[0].label_misc_charges4,
                    grossMiscCharges4:
                      dataResponse.sales_data[0].gross_misc_charges4,
                    gstMiscCharges4:
                      dataResponse.sales_data[0].gst_misc_charges4,
                    netMiscCharges4:
                      dataResponse.sales_data[0].net_misc_charges4,

                    labelMiscCharges5:
                      dataResponse.sales_data[0].label_misc_charges5,
                    grossMiscCharges5:
                      dataResponse.sales_data[0].gross_misc_charges5,
                    gstMiscCharges5:
                      dataResponse.sales_data[0].gst_misc_charges5,
                    netMiscCharges5:
                      dataResponse.sales_data[0].net_misc_charges5,
                    lessCardSalesSelectedCheckbox:
                      dataResponse.sales_data[0].less_in_store_card_sales,
                    lessCashSalesSelectedCheckbox:
                      dataResponse.sales_data[0].less_in_store_cash_sales,
                  },
                  () => {
                    const {
                      gstMiscCharges1,
                      gstMiscCharges2,
                      gstMiscCharges3,
                      gstMiscCharges4,
                      gstMiscCharges5,
                    } = this.state;
                    if (Number(gstMiscCharges1) > 0) {
                      this.setState({ isGstMiscCharges1: true });
                    }
                    if (Number(gstMiscCharges2) > 0) {
                      this.setState({ isGstMiscCharges2: true });
                    }
                    if (Number(gstMiscCharges3) > 0) {
                      this.setState({ isGstMiscCharges3: true });
                    }
                    if (Number(gstMiscCharges4) > 0) {
                      this.setState({ isGstMiscCharges4: true });
                    }
                    if (Number(gstMiscCharges5) > 0) {
                      this.setState({ isGstMiscCharges5: true });
                    }
                  }
                );
                const {
                  grossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlinePickupSalesArray,
                  grossKitchacoOnlineDeliverySalesArray,
                  grossUberEatsSalesArray,
                  grossDoordashSalesArray,
                  grossDeliverooSalesArray,
                  grossMenulogSalesArray,
                } = this.state;

                this.setState({
                  grossUberEatsSales: grossUberEatsSales,
                  grossDoordashSales: grossDoordashSales,
                  grossDeliverooSales: grossDeliverooSales,
                  grossMenulogSales: grossMenulogSales,
                  grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
                  grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
                  grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
                  grossDoordashSalesArray: tempGrossDoordashSalesArray,
                  grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
                  grossMenulogSalesArray: tempGrossMenulogSalesArray,
                  salesApprovedInvoiceDetails:
                    dataResponse.sales_approved_invoice_details,
                });
              } else {
                this.setState({
                  grossKitchacoInStoreCashSales:
                    dataResponse.gross_kitchaco_in_store_cash_sales,
                  grossKitchacoInStoreCardSales:
                    dataResponse.gross_kitchaco_in_store_card_sales,
                  grossKitchacoOnlinePickupSales:
                    dataResponse.gross_kitchaco_online_pickup_sales,
                  grossKitchacoOnlineDeliverySales:
                    dataResponse.gross_kitchaco_online_delivery_sales,
                  approved_by: "",
                  approved_on: "",
                  labelMiscCharges1: "",
                  grossMiscCharges1: 0,
                  netMiscCharges1: 0,
                  gstMiscCharges1: 0,

                  isGstMiscCharges1: false,
                  labelMiscCharges2: "",
                  grossMiscCharges2: 0,
                  netMiscCharges2: 0,
                  gstMiscCharges2: 0,

                  isGstMiscCharges2: false,
                  labelMiscCharges3: "",
                  grossMiscCharges3: 0,
                  netMiscCharges3: 0,
                  gstMiscCharges3: 0,

                  isGstMiscCharges3: false,
                  labelMiscCharges4: "",
                  grossMiscCharges4: 0,
                  netMiscCharges4: 0,
                  gstMiscCharges4: 0,

                  isGstMiscCharges4: false,
                  labelMiscCharges5: "",
                  grossMiscCharges5: 0,
                  isGstMiscCharges5: false,
                  netMiscCharges5: 0,
                  gstMiscCharges5: 0,
                  lessCashSalesSelectedCheckbox: true,
                  lessCardSalesSelectedCheckbox: false,
                });
              }
            }
          );
        } else {
          this.setState({
            adminSalesInvoiceFortnightPeriod: 0,
            errorMessage:
              "Report for this location and period hasn't been generated yet. Please select a different location or period or come back later for this report.",
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.vendors }, () => {
          let vendorId = "0";
          this.setState({ selectedVendorId: vendorId }, () => {
            saveToLocalStorage("selectedVendorId", vendorId);
            this.getSalesInvoiceFornightPeriod();
          });
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  getExternalOperatorData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      query_type: "external_operators",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ externalOperators: dataResponse.vendors }, () => {
            this.getSalesInvoiceFornightPeriod();
          });
        }
      })
      .catch((err) => {});
  }

  getSalesInvoiceFornightPeriod() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "sales_invoice_fortnight_period",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState(
            {
              salesInvoiceFornightPeriod:
                dataResponse.sales_invoice_fortnight_period,
            },
            () => {
              const {
                salesInvoiceFornightPeriod,
                adminSalesInvoiceFortnightPeriod,
                startDate,
                endDate,
              } = this.state;
              try {
                var start_date = moment(startDate).format("DD MMM YYYY");
                var end_date = moment(endDate).format("DD MMM YYYY");
                if (
                  salesInvoiceFornightPeriod[
                    adminSalesInvoiceFortnightPeriod - 1
                  ].start_date != start_date ||
                  salesInvoiceFornightPeriod[
                    adminSalesInvoiceFortnightPeriod - 1
                  ].end_date != end_date
                ) {
                  this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
                    saveToLocalStorage(
                      "adminSalesInvoiceFortnightPeriod",
                      adminSalesInvoiceFortnightPeriod
                    );
                  });
                }
              } catch (e) {
                this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
                  saveToLocalStorage(
                    "adminSalesInvoiceFortnightPeriod",
                    adminSalesInvoiceFortnightPeriod
                  );
                });
              }
              this.getSalesReport();
            }
          );
        }
      })
      .catch((err) => {});
  }

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  saveSalesInvoice = (
    grossTotalRevenue,
    gst,
    netUberEatsFee,
    netDoordashFee,
    netDeliverooFee,
    netMenulogFee,
    grossKitchacoInStoreCashFee,
    netKitchacoInStoreCardFee,
    netKitchacoOnlinePickupFee,
    netKitchacoOnlineDeliveryFee,
    payoutResult,
    grossTotalSalesLessExpenditure
  ) => {
    const {
      startDate,
      endDate,
      selectedVendorId,
      adminSalesInvoiceFortnightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      netStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,

      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      superAdminEmail,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      salesReport,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
      grossKitchacoInStoreCardFee,
      grossKitchacoOnlinePickupFee,
    } = this.state;

    var kitchacoTotalSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    var kitchacoOnlineSales =
      Number(grossKitchacoOnlinePickupSales) +
      Number(grossKitchacoOnlineDeliverySales);
    var kitchacoInStoreSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales);
    let currentDate = moment().format("DD MMM YYYY");
    var url = config.BASE_URI + apiPaths.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    alert(superAdminEmail);
    var raw = JSON.stringify({
      query_type: "update_and_approve_sales_report",
      vendor: parseInt(selectedVendorId),
      approved_by: "frank@amfvg.com.au",
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      kitchaco_total_sales: Number(kitchacoTotalSales).toFixed(2),
      kitchaco_online_sales: kitchacoOnlineSales,
      paid_on: currentDate,
      kitchaco_instore_sales: kitchacoInStoreSales,
      uber_total_sales: grossUberEatsSales,
      deliveroo_total_sales: grossDeliverooSales,
      doordash_total_sales: grossDoordashSales,
      menulog_total_sales: grossMenulogSales,
      total_revenue: Number(grossTotalRevenue).toFixed(2),
      total_gst: gst,
      stock_ex_gst: netStockPurchased,
      uber_commission_ex_gst: netUberEatsFee,
      doordash_commission_ex_gst: netDoordashFee,
      deliveroo_commission_ex_gst: netDeliverooFee,
      menulog_commission_ex_gst: netMenulogFee,
      in_store_cash_commission: grossKitchacoInStoreCashFee,
      in_store_card_commission_ex_gst: netKitchacoInStoreCardFee,
      online_pickup_commission_ex_gst: netKitchacoOnlinePickupFee,
      online_delivery_commission_ex_gst: netKitchacoOnlineDeliveryFee,
      kitchaco_in_store_cash_sales: grossKitchacoInStoreCashSales,
      kitchaco_in_store_card_sales: grossKitchacoInStoreCardSales,
      kitchaco_online_pickup_sales: grossKitchacoOnlinePickupSales,
      kitchaco_online_delivery_sales: grossKitchacoOnlineDeliverySales,
      label_misc_charges1: labelMiscCharges1,
      gross_misc_charges1: grossMiscCharges1,
      net_misc_charges1: netMiscCharges1,
      gst_misc_charges1: gstMiscCharges1,
      label_misc_charges2: labelMiscCharges2,
      gross_misc_charges2: grossMiscCharges2,
      net_misc_charges2: netMiscCharges2,
      gst_misc_charges2: gstMiscCharges2,
      label_misc_charges3: labelMiscCharges3,
      gross_misc_charges3: grossMiscCharges3,
      net_misc_charges3: netMiscCharges3,
      gst_misc_charges3: gstMiscCharges3,
      label_misc_charges4: labelMiscCharges4,
      gross_misc_charges4: grossMiscCharges4,
      net_misc_charges4: netMiscCharges4,
      gst_misc_charges4: gstMiscCharges4,
      label_misc_charges5: labelMiscCharges5,
      gross_misc_charges5: grossMiscCharges5,
      net_misc_charges5: netMiscCharges5,
      gst_misc_charges5: gstMiscCharges5,
      total_payout_inc_gst: payoutResult,
      uber_total_sales_array: grossUberEatsSalesArray,
      deliveroo_total_sales_array: grossDeliverooSalesArray,
      doordash_total_sales_array: grossDoordashSalesArray,
      menulog_total_sales_array: grossMenulogSalesArray,
      kitchaco_in_store_cash_sales_array: grossKitchacoInStoreCashSalesArray,
      kitchaco_in_store_card_sales_array: grossKitchacoInStoreCardSalesArray,
      kitchaco_online_pickup_sales_array: grossKitchacoOnlinePickupSalesArray,
      kitchaco_online_delivery_sales_array: grossKitchacoOnlineDeliverySalesArray,
      sales_report: salesReport,
      sales_approved_invoice_details: this.state.salesApprovedInvoiceDetails,
      total_earnings: grossTotalSalesLessExpenditure,
      less_in_store_cash_sales: lessCashSalesSelectedCheckbox,
      less_in_store_card_sales: lessCardSalesSelectedCheckbox,
      wtt_cash_commission: grossKitchacoInStoreCashFee,
      wtt_card_commission: grossKitchacoInStoreCardFee,
      wtt_voucher_commission: grossKitchacoOnlinePickupFee,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Approved Successfully");
          // window.location.reload()
        }
      });
  };

  sendEmail = (
    totalWttCashCommission,
    totalWttCardCommission,
    totalWttVoucherCommission,
    totalWttEarnings,
    totalWttPayout,
    totalGstOnCharges,
    eventsListDuringDatePeriod
  ) => {
    const {
      adminExternalOperator,
      startDate,
      endDate,
      adminSalesInvoiceFortnightPeriod,
      superAdminEmail,
      selectedVendorId,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      netStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,

      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      salesReport,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
    } = this.state;
    const eventMiscDetails = this.totalOverallMiscExpensesDetailsForAllEvents(
      eventsListDuringDatePeriod
    );
    var kitchacoTotalSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    var kitchacoOnlineSales =
      Number(grossKitchacoOnlinePickupSales) +
      Number(grossKitchacoOnlineDeliverySales);
    var kitchacoInStoreSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales);
    var url = config.BASE_URI + apiPaths.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const weekNumber = moment(startDate).isoWeek();
    var raw = JSON.stringify({
      query_type: "email_sales_report_v2",
      vendor: parseInt(selectedVendorId),
      approved_by: superAdminEmail,
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      vendor: parseInt(selectedVendorId),
      approved_by: "frank@amfvg.com.au",
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      totalWttCashCommission: totalWttCashCommission,
      totalWttCardCommission: totalWttCardCommission,
      totalWttVoucherCommission: totalWttVoucherCommission,
      totalWttEarnings: totalWttEarnings,
      totalWttPayout: totalWttPayout,
      totalGstOnCharges: totalGstOnCharges,
      eventsListDuringDatePeriod: eventsListDuringDatePeriod,
      week_number: weekNumber,
      eventMiscDetails: eventMiscDetails,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Email has been sent to your registered email address.");
        }
      })
      .catch((err) => {});
  };

  handleKitchacoOnlineDeliverySalesArray = (e, index) => {
    const { grossKitchacoOnlineDeliverySalesArray } = this.state;
    let tempArray = grossKitchacoOnlineDeliverySalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossKitchacoOnlineDeliverySalesArray: tempArray }, () => {
      var temp = 0;
      const { grossKitchacoOnlineDeliverySalesArray } = this.state;
      for (var i = 0; i < grossKitchacoOnlineDeliverySalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoOnlineDeliverySalesArray[i]);
      }
      this.setState({ grossKitchacoOnlineDeliverySales: temp });
    });
  };

  handleCommissionChange = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    eventData.commission_percentage_By_event = event.target.value;

    eventData.wtt_cash_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        Number(eventData.gross_vendor_cash_sales)
      ).toFixed(2) / 100;
    eventData.wtt_card_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        Number(eventData.gross_vendor_card_sales)
      ).toFixed(2) / 100;
    eventData.wtt_voucher_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        Number(eventData.gross_vendor_voucher_sales)
      ).toFixed(2) / 100;
      eventData.wtt_surge_charge_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        Number(eventData.gross_surge_charge)
      ).toFixed(2) / 100;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleWttCashSales = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    eventData.gross_vendor_cash_sales = Number(event.target.value);

    eventData.wtt_cash_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        eventData.gross_vendor_cash_sales
      ).toFixed(2) / 100;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleWttCardSales = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    eventData.gross_vendor_card_sales = Number(event.target.value);

    eventData.wtt_cash_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        eventData.gross_vendor_card_sales
      ).toFixed(2) / 100;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleWttVoucherSales = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    eventData.gross_vendor_voucher_sales = Number(event.target.value);

    eventData.wtt_voucher_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        eventData.gross_vendor_voucher_sales
      ).toFixed(2) / 100;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleWttVoucherSales = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    eventData.gross_surge_charge = Number(event.target.value);

    eventData.wtt_surge_charge_fee_event =
      (
        Number(eventData.commission_percentage_By_event) *
        eventData.gross_surge_charge
      ).toFixed(2) / 100;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleGstMiscCharges1Toggle = (index) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    this.setState((prevState) => ({
      eventsListDuringDatePeriod: prevState.eventsListDuringDatePeriod.map(
        (event, idx) => {
          if (idx === index) {
            return {
              ...event,
              isGstMiscCharges1: !eventData.isGstMiscCharges1,
              gstMiscCharges1: eventData.isGstMiscCharges1
                ? 0
                : Number(Number(eventData.grossMiscCharges1) / 11).toFixed(2),
              netMiscCharges1: eventData.isGstMiscCharges1
                ? eventData.grossMiscCharges1
                : Number(Number(eventData.grossMiscCharges1) / 1.1).toFixed(2),
            };
          }
          return event;
        }
      ),
    }));
  };

  handleLabelMiscCharges1Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];
    eventData.labelMiscCharges1 = event.target.value;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleGrossMiscCharges1Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    const newGrossMiscCharges1 = event.target.value;
    this.setState(
      {
        eventsListDuringDatePeriod: eventsListDuringDatePeriod.map(
          (event, idx) => {
            if (idx === index) {
              return { ...event, grossMiscCharges1: newGrossMiscCharges1 };
            }
            return event;
          }
        ),
      },
      () => {
        const updatedEventData = this.state.eventsListDuringDatePeriod[index];
        if (!updatedEventData.isGstMiscCharges1) {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges1: 0,
                    netMiscCharges1: updatedEventData.grossMiscCharges1,
                  };
                }
                return event;
              }
            ),
          });
        } else {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges1: Number(
                      Number(updatedEventData.grossMiscCharges1) / 11
                    ).toFixed(2),
                    netMiscCharges1: Number(
                      Number(updatedEventData.grossMiscCharges1) / 1.1
                    ).toFixed(2),
                  };
                }
                return event;
              }
            ),
          });
        }
      }
    );
  };

  handleGstMiscCharges2Toggle = (index) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    this.setState((prevState) => ({
      eventsListDuringDatePeriod: prevState.eventsListDuringDatePeriod.map(
        (event, idx) => {
          if (idx === index) {
            return {
              ...event,
              isGstMiscCharges2: !eventData.isGstMiscCharges2,
              gstMiscCharges2: eventData.isGstMiscCharges2
                ? 0
                : Number(Number(eventData.grossMiscCharges2) / 11).toFixed(2),
              netMiscCharges2: eventData.isGstMiscCharges2
                ? eventData.grossMiscCharges2
                : Number(Number(eventData.grossMiscCharges2) / 1.1).toFixed(2),
            };
          }
          return event;
        }
      ),
    }));
  };

  handleLabelMiscCharges2Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];
    eventData.labelMiscCharges2 = event.target.value;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleGrossMiscCharges2Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    const newGrossMiscCharges2 = event.target.value;
    this.setState(
      {
        eventsListDuringDatePeriod: eventsListDuringDatePeriod.map(
          (event, idx) => {
            if (idx === index) {
              return { ...event, grossMiscCharges2: newGrossMiscCharges2 };
            }
            return event;
          }
        ),
      },
      () => {
        const updatedEventData = this.state.eventsListDuringDatePeriod[index];
        if (!updatedEventData.isGstMiscCharges1) {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges2: 0,
                    netMiscCharges2: updatedEventData.grossMiscCharges1,
                  };
                }
                return event;
              }
            ),
          });
        } else {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges2: Number(
                      Number(updatedEventData.grossMiscCharges2) / 11
                    ).toFixed(2),
                    netMiscCharges2: Number(
                      Number(updatedEventData.grossMiscCharges2) / 1.1
                    ).toFixed(2),
                  };
                }
                return event;
              }
            ),
          });
        }
      }
    );
  };

  //  MISC CHARGES 3

  handleGstMiscCharges3Toggle = (index) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    this.setState((prevState) => ({
      eventsListDuringDatePeriod: prevState.eventsListDuringDatePeriod.map(
        (event, idx) => {
          if (idx === index) {
            return {
              ...event,
              isGstMiscCharges3: !eventData.isGstMiscCharges3,
              gstMiscCharges3: eventData.isGstMiscCharges3
                ? 0
                : Number(Number(eventData.grossMiscCharges3) / 11).toFixed(2),
              netMiscCharges3: eventData.isGstMiscCharges3
                ? eventData.grossMiscCharges3
                : Number(Number(eventData.grossMiscCharges3) / 1.1).toFixed(2),
            };
          }
          return event;
        }
      ),
    }));
  };

  handleLabelMiscCharges3Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];
    eventData.labelMiscCharges3 = event.target.value;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleGrossMiscCharges3Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    const newGrossMiscCharges3 = event.target.value;
    this.setState(
      {
        eventsListDuringDatePeriod: eventsListDuringDatePeriod.map(
          (event, idx) => {
            if (idx === index) {
              return { ...event, grossMiscCharges3: newGrossMiscCharges3 };
            }
            return event;
          }
        ),
      },
      () => {
        const updatedEventData = this.state.eventsListDuringDatePeriod[index];
        if (!updatedEventData.isGstMiscCharges3) {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges3: 0,
                    netMiscCharges3: updatedEventData.grossMiscCharges3,
                  };
                }
                return event;
              }
            ),
          });
        } else {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges3: Number(
                      Number(updatedEventData.grossMiscCharges3) / 11
                    ).toFixed(2),
                    netMiscCharges3: Number(
                      Number(updatedEventData.grossMiscCharges3) / 1.1
                    ).toFixed(2),
                  };
                }
                return event;
              }
            ),
          });
        }
      }
    );
  };

  //  MISC CHARGES 4

  handleGstMiscCharges4Toggle = (index) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    this.setState((prevState) => ({
      eventsListDuringDatePeriod: prevState.eventsListDuringDatePeriod.map(
        (event, idx) => {
          if (idx === index) {
            return {
              ...event,
              isGstMiscCharges4: !eventData.isGstMiscCharges4,
              gstMiscCharges4: eventData.isGstMiscCharges4
                ? 0
                : Number(Number(eventData.grossMiscCharges4) / 11).toFixed(2),
              netMiscCharges4: eventData.isGstMiscCharges4
                ? eventData.grossMiscCharges4
                : Number(Number(eventData.grossMiscCharges4) / 1.1).toFixed(2),
            };
          }
          return event;
        }
      ),
    }));
  };

  handleLabelMiscCharges4Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];
    eventData.labelMiscCharges4 = event.target.value;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleGrossMiscCharges4Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    const newGrossMiscCharges4 = event.target.value;
    this.setState(
      {
        eventsListDuringDatePeriod: eventsListDuringDatePeriod.map(
          (event, idx) => {
            if (idx === index) {
              return { ...event, grossMiscCharges4: newGrossMiscCharges4 };
            }
            return event;
          }
        ),
      },
      () => {
        const updatedEventData = this.state.eventsListDuringDatePeriod[index];
        if (!updatedEventData.isGstMiscCharges4) {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges4: 0,
                    netMiscCharges4: updatedEventData.grossMiscCharges4,
                  };
                }
                return event;
              }
            ),
          });
        } else {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges4: Number(
                      Number(updatedEventData.grossMiscCharges4) / 11
                    ).toFixed(2),
                    netMiscCharges4: Number(
                      Number(updatedEventData.grossMiscCharges4) / 1.1
                    ).toFixed(2),
                  };
                }
                return event;
              }
            ),
          });
        }
      }
    );
  };

  //  MISC CHARGES 5

  handleGstMiscCharges5Toggle = (index) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    this.setState((prevState) => ({
      eventsListDuringDatePeriod: prevState.eventsListDuringDatePeriod.map(
        (event, idx) => {
          if (idx === index) {
            return {
              ...event,
              isGstMiscCharges5: !eventData.isGstMiscCharges5,
              gstMiscCharges5: eventData.isGstMiscCharges5
                ? 0
                : Number(Number(eventData.grossMiscCharges5) / 11).toFixed(2),
              netMiscCharges5: eventData.isGstMiscCharges5
                ? eventData.grossMiscCharges5
                : Number(Number(eventData.grossMiscCharges5) / 1.1).toFixed(2),
            };
          }
          return event;
        }
      ),
    }));
  };

  handleLabelMiscCharges5Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];
    eventData.labelMiscCharges5 = event.target.value;

    this.setState({
      eventsListDuringDatePeriod: [...eventsListDuringDatePeriod],
    });
  };

  handleGrossMiscCharges5Change = (index, event) => {
    const { eventsListDuringDatePeriod } = this.state;
    const eventData = eventsListDuringDatePeriod[index];

    const newGrossMiscCharges5 = event.target.value;
    this.setState(
      {
        eventsListDuringDatePeriod: eventsListDuringDatePeriod.map(
          (event, idx) => {
            if (idx === index) {
              return { ...event, grossMiscCharges5: newGrossMiscCharges5 };
            }
            return event;
          }
        ),
      },
      () => {
        const updatedEventData = this.state.eventsListDuringDatePeriod[index];
        if (!updatedEventData.isGstMiscCharges5) {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges5: 0,
                    netMiscCharges5: updatedEventData.grossMiscCharges5,
                  };
                }
                return event;
              }
            ),
          });
        } else {
          this.setState({
            eventsListDuringDatePeriod: this.state.eventsListDuringDatePeriod.map(
              (event, idx) => {
                if (idx === index) {
                  return {
                    ...event,
                    gstMiscCharges5: Number(
                      Number(updatedEventData.grossMiscCharges5) / 11
                    ).toFixed(2),
                    netMiscCharges5: Number(
                      Number(updatedEventData.grossMiscCharges5) / 1.1
                    ).toFixed(2),
                  };
                }
                return event;
              }
            ),
          });
        }
      }
    );
  };

  calculateTotalRevenue = (eventData) => {
    const grossTotalRevenue =
      eventData.gross_vendor_voucher_sales +
      eventData.gross_vendor_card_sales +
      eventData.gross_surge_charge +
      eventData.gross_transaction_fees +
      eventData.gross_vendor_cash_sales;
    const netTotalRevenue =
      eventData.gross_vendor_voucher_sales +
      eventData.gross_vendor_card_sales +
      eventData.gross_surge_charge +
      eventData.gross_transaction_fees +
      eventData.gross_vendor_cash_sales / 1.1;
    const gstTotalRevenue =
      eventData.gross_vendor_voucher_sales +
      eventData.gross_vendor_card_sales +
      eventData.gross_vendor_cash_sales / 11;
      

    return { grossTotalRevenue, netTotalRevenue, gstTotalRevenue };
  };


  calculateTotalTransactionFee = (eventData) => {
    const grossTotalTransactionFee =
      eventData.gross_transaction_fees 
      
    const netTransactionFee =
      eventData.gross_transaction_fees / 1.1;
    const gstTransactionFee =
      eventData.gross_transaction_fees / 11;
      

    return { grossTotalTransactionFee, netTransactionFee, gstTransactionFee };
  };


  calculateTotalPurchasesByEvent = (eventData) => {
    const grossTotalPurchasesByEvent =
      eventData.wtt_card_fee_event +
      eventData.wtt_card_fee_event +
      eventData.wtt_surge_charge_fee_event +
      eventData.wtt_voucher_fee_event +
      eventData.grossMiscCharges1 +
      eventData.grossMiscCharges2 +
      eventData.grossMiscCharges3 +
      eventData.grossMiscCharges4 +
      eventData.grossMiscCharges5;
    const netTotalPurchasesByEvent =
      eventData.wtt_card_fee_event +
      eventData.wtt_card_fee_event +
      eventData.wtt_voucher_fee_event +
      eventData.grossMiscCharges1 +
      eventData.grossMiscCharges2 +
      eventData.grossMiscCharges3 +
      eventData.grossMiscCharges4 +
      eventData.grossMiscCharges5 / 1.1;
    const gstTotalPurchasesByEvent =
      eventData.wtt_card_fee_event +
      eventData.wtt_card_fee_event +
      eventData.wtt_voucher_fee_event +
      eventData.grossMiscCharges1 +
      eventData.grossMiscCharges2 +
      eventData.grossMiscCharges3 +
      eventData.grossMiscCharges4 +
      eventData.grossMiscCharges5 / 11;

    return {
      grossTotalPurchasesByEvent,
      netTotalPurchasesByEvent,
      gstTotalPurchasesByEvent,
    };
  };

  calculateTotalGstByEvent = (eventData, index) => {
    const totalGstOnCharges =
      parseFloat(eventData.gstMiscCharges1) +
      parseFloat(eventData.gstMiscCharges2) +
      parseFloat(eventData.gstMiscCharges3) +
      parseFloat(eventData.gstMiscCharges4) +
      parseFloat(eventData.gstMiscCharges5);

    const totalGstOnCommission =
      parseFloat(eventData.wtt_card_fee_event / 11) +
      parseFloat(eventData.wtt_surge_charge_fee_event / 11) +
      parseFloat(eventData.wtt_voucher_fee_event / 11);
    const totalGstCollected =
      parseFloat(eventData.gross_vendor_voucher_sales / 11) +
      parseFloat(eventData.gross_surge_charge / 11) +
      parseFloat(eventData.gross_vendor_card_sales / 11);

    const totalGstCharged = totalGstOnCharges + totalGstOnCommission;
    const totalGstByEvent = totalGstCollected - totalGstCharged;
    eventData.total_gst_for_event = totalGstByEvent;
    return { totalGstByEvent };
  };

  calculateTotalWttEarningsByEvent = (eventData, index) => {
    const totalEarningsOnCharges =
      parseFloat(eventData.grossMiscCharges1) +
      parseFloat(eventData.grossMiscCharges2) +
      parseFloat(eventData.grossMiscCharges3) +
      parseFloat(eventData.grossMiscCharges4) +
      parseFloat(eventData.grossMiscCharges5);

    const totalCommission =
      parseFloat(eventData.wtt_card_fee_event) +
      parseFloat(eventData.wtt_voucher_fee_event) +
      parseFloat(eventData.gross_surge_charge) +
      parseFloat(eventData.wtt_cash_fee_event);

    const totalWttEarningsByEvent = totalEarningsOnCharges + totalCommission;
    eventData.total_wtt_earnings_for_event = totalWttEarningsByEvent;

    return { totalWttEarningsByEvent };
  };
  calculateTotalWttPayoutByEvent = (eventData, index) => {
    // Parse numeric values and default to 0 if not valid numbers
    const charges = [
      parseFloat(eventData.grossMiscCharges1) || 0,
      parseFloat(eventData.grossMiscCharges2) || 0,
      parseFloat(eventData.grossMiscCharges3) || 0,
      parseFloat(eventData.grossMiscCharges4) || 0,
      parseFloat(eventData.grossMiscCharges5) || 0,
    ];

    const totalCharges = charges.reduce((acc, charge) => acc + charge, 0);

    const commissionFees = [
      parseFloat(eventData.wtt_card_fee_event) || 0,
      parseFloat(eventData.wtt_voucher_fee_event) || 0,
      parseFloat(eventData.wtt_cash_fee_event) || 0,
      parseFloat(eventData.wtt_surge_charge_fee_event) || 0,
    ];

    const totalCommissionFees = commissionFees.reduce(
      (acc, fee) => acc + fee,
      0
    );

    const totalRevenueByEvent =
      parseFloat(eventData.gross_vendor_voucher_sales) +
      parseFloat(eventData.gross_vendor_card_sales);

    const totalWTTIncome = totalCharges + totalCommissionFees;
    const totalPayoutByEvent = totalRevenueByEvent - totalWTTIncome;
    eventData.total_payout_for_event = totalPayoutByEvent;
    return { totalPayoutByEvent };
  };

  calculateOverAllWttCashCommission = (eventsListDuringDatePeriod) => {
    let totalWttCashCommission = 0;
    for (var i = 0; i < eventsListDuringDatePeriod.length; i++) {
      totalWttCashCommission =
        totalWttCashCommission +
        Number(eventsListDuringDatePeriod[i].wtt_cash_fee_event);
    }

    return totalWttCashCommission;
  };

  calculateOverAllWttCardCommission = (eventsListDuringDatePeriod) => {
    let totalWttCardCommission = 0;
    for (var i = 0; i < eventsListDuringDatePeriod.length; i++) {
      totalWttCardCommission =
        totalWttCardCommission +
        Number(eventsListDuringDatePeriod[i].wtt_card_fee_event);
    }

    console.log("totalWttPayout", totalWttCardCommission);
    return totalWttCardCommission;
  };

  calculateOverAllWttVoucherCommission = (eventsListDuringDatePeriod) => {
    let totalWttVoucherCommission = 0;
    for (var i = 0; i < eventsListDuringDatePeriod.length; i++) {
      totalWttVoucherCommission =
        totalWttVoucherCommission +
        Number(eventsListDuringDatePeriod[i].wtt_voucher_fee_event);
    }
    return totalWttVoucherCommission;
  };

  calculateOverAllWttEarnings = (eventsListDuringDatePeriod) => {
    let totalWttEarnings = 0;
    for (var i = 0; i < eventsListDuringDatePeriod.length; i++) {
      totalWttEarnings =
        totalWttEarnings +
        Number(eventsListDuringDatePeriod[i].totalWttEarningsByEvent);
    }

    return totalWttEarnings;
  };

  calculateOverAllWttPayout = (eventsListDuringDatePeriod) => {
    let totalWttPayout = 0;
    for (var i = 0; i < eventsListDuringDatePeriod.length; i++) {
      totalWttPayout =
        totalWttPayout +
        Number(eventsListDuringDatePeriod[i].totalPayoutByEvent);
    }

    return totalWttPayout;
  };

  calculateOverAllGstOnCharges = (eventsListDuringDatePeriod) => {
    let totalGstOnCharges = 0;
    for (var i = 0; i < eventsListDuringDatePeriod.length; i++) {
      totalGstOnCharges =
        totalGstOnCharges +
        Number(eventsListDuringDatePeriod[i].totalGstOnCharges);
    }

    return totalGstOnCharges;
  };

  totalOverallMiscExpensesDetailsForAllEvents = (
    eventsListDuringDatePeriod
  ) => {
    let totalOverallMiscExpensesDetailsForAllEvents = [];
    eventsListDuringDatePeriod &&
      eventsListDuringDatePeriod.map((event, index) => {
        // Define a function to create a new entry for each non-empty, GST-related miscellaneous charge
        const createEntry = (label, gross, isGst, gst, net) => ({
          label,
          gross,
          isGst,
          gst,
          net,
        });

        // Check for each of the 5 miscellaneous charges and push them into the array
        if (
          event.labelMiscCharges1 &&
          event.grossMiscCharges1 &&
          event.isGstMiscCharges1
        ) {
          totalOverallMiscExpensesDetailsForAllEvents.push(
            createEntry(
              event.labelMiscCharges1,
              event.grossMiscCharges1,
              event.isGstMiscCharges1,
              event.gstMiscCharges1,
              event.netMiscCharges1
            )
          );
        }
        if (
          event.labelMiscCharges2 &&
          event.grossMiscCharges2 &&
          event.isGstMiscCharges2
        ) {
          totalOverallMiscExpensesDetailsForAllEvents.push(
            createEntry(
              event.labelMiscCharges2,
              event.grossMiscCharges2,
              event.isGstMiscCharges2,
              event.gstMiscCharges2,
              event.netMiscCharges2
            )
          );
        }
        if (
          event.labelMiscCharges3 &&
          event.grossMiscCharges3 &&
          event.isGstMiscCharges3
        ) {
          totalOverallMiscExpensesDetailsForAllEvents.push(
            createEntry(
              event.labelMiscCharges3,
              event.grossMiscCharges3,
              event.isGstMiscCharges3,
              event.gstMiscCharges3,
              event.netMiscCharges3
            )
          );
        }
        if (
          event.labelMiscCharges4 &&
          event.grossMiscCharges4 &&
          event.isGstMiscCharges4
        ) {
          totalOverallMiscExpensesDetailsForAllEvents.push(
            createEntry(
              event.labelMiscCharges4,
              event.grossMiscCharges4,
              event.isGstMiscCharges4,
              event.gstMiscCharges4,
              event.netMiscCharges4
            )
          );
        }
        if (
          event.labelMiscCharges5 &&
          event.grossMiscCharges5 &&
          event.isGstMiscCharges5
        ) {
          totalOverallMiscExpensesDetailsForAllEvents.push(
            createEntry(
              event.labelMiscCharges5,
              event.grossMiscCharges5,
              event.isGstMiscCharges5,
              event.gstMiscCharges5,
              event.netMiscCharges5
            )
          );
        }
      });
    return totalOverallMiscExpensesDetailsForAllEvents;
  };

  renderHome() {
    const {
      vendors,
      selectedVendorId,
      salesReport,
      externalOperators,
      adminExternalOperator,
      adminSalesInvoiceFortnightPeriod,
      isLoading,
      isSuperAdminLoggedIn,
      startDate,
      endDate,
      salesInvoiceFornightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      grossStockPurchased,
      gstStockPurchased,
      netStockPurchased,
      adminAdjustmentStockPurchased,
      deliveryChargeStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,
      kitchaco_commission_percentage,
      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      in_store_cash_commission,
      in_store_card_commission,
      online_pickup_commission,
      online_delivery_commission,
      uber_eats_commission,
      doordash_commission,
      deliveroo_commission,
      menulog_commission,
      approved_by,
      approved_on,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      errorMessage,
      gross_surge_charge,
      gross_transaction_fees,
      wtt_surge_charge_fee_event,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
      selectedVendorSearch,
      filteredVendors,
      eventsListDuringDatePeriod,
    } = this.state;
    const setFilteredVendors = (data) => {
      this.setState({ filteredVendors: data });
    };
    const selectedVendorP = (data) => {
      console.log(data);
      this.setState({ selectedVendorSearch: data });
    };
    const selectedVendorQ = (data) => {
      console.log(data);
      saveToLocalStorage("selectedVendorId", "" + data);
      this.setState({ selectedVendorId: "" + data }, () => {});
    };

    var grossKitchacoInStoreCashFee = 0;
    var grossKitchacoInStoreCardFee = 0;
    var grossKitchacoOnlineDeliveryFee = 0;
    var grossKitchacoOnlinePickupFee = 0;
    var grossUberEatsFee = 0;
    var grossDoordashFee = 0;
    var grossDeliverooFee = 0;
    var grossMenulogFee = 0;

    var gstKitchacoInStoreCashSales = 0;
    var gstKitchacoInStoreCardSales = 0;
    var gstKitchacoOnlineDeliverySales = 0;
    var gstKitchacoOnlinePickupSales = 0;
    var gstUberEatsSales = 0;
    var gstDoordashSales = 0;
    var gstDeliverooSales = 0;
    var gstMenulogSales = 0;

    var gstKitchacoInStoreCashFee = 0;
    var gstKitchacoInStoreCardFee = 0;
    var gstKitchacoOnlineDeliveryFee = 0;
    var gstKitchacoOnlinePickupFee = 0;
    var gstUberEatsFee = 0;
    var gstDoordashFee = 0;
    var gstDeliverooFee = 0;
    var gstMenulogFee = 0;

    var netTransactionFee = 0;
    var gstTransactionFee = 0

    var netKitchacoInStoreCashSales = 0;
    var netKitchacoInStoreCardSales = 0;
    var netKitchacoOnlineDeliverySales = 0;
    var netKitchacoOnlinePickupSales = 0;
    var netUberEatsSales = 0;
    var netDoordashSales = 0;
    var netDeliverooSales = 0;
    var netMenulogSales = 0;
    var netKitchacoInStoreCashFee = 0;
    var netKitchacoInStoreCardFee = 0;
    var netKitchacoOnlineDeliveryFee = 0;
    var netKitchacoOnlinePickupFee = 0;
    var netUberEatsFee = 0;
    var netDoordashFee = 0;
    var netDeliverooFee = 0;
    var netMenulogFee = 0;

    var grossTotalRevenue = 0;
    var netTotalRevenue = 0;
    var gstTotalRevenue = 0;

    var grossTotalRevenueWithoutCashSales = 0;
    var netTotalRevenueWithoutCashSales = 0;
    var gstTotalRevenueWithoutCashSales = 0;

    var grossTotalPurchases = 0;
    var netTotalPurchases = 0;
    var gstTotalPurchases = 0;

    var grossTotalSalesLessExpenditure = 0;
    var gstTotalSalesLessExpenditure = 0;
    var netTotalSalesLessExpenditure = 0;

    var payoutResult = 0;
    var gst = 0;
    var netPayment = 0;
    var operatorIsPaid = 0;
    var gstToBePaidToATO = 0;
    var operatorReceivesFromFrank = 0;
    var operatorPaysATO = 0;

    if (lessCardSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCardSales = Number(
        Number(grossKitchacoInStoreCardSales) / 11
      ).toFixed(2);
      netKitchacoInStoreCardSales = Number(
        Number(grossKitchacoInStoreCardSales) / 1.1
      ).toFixed(2);
    } else {
      gstKitchacoInStoreCardSales = 0;
      netKitchacoInStoreCardSales = 0;
    }

    if (lessCashSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCashSales = Number(
        Number(grossKitchacoInStoreCashSales) / 11
      ).toFixed(2);
      netKitchacoInStoreCashSales = Number(
        Number(grossKitchacoInStoreCashSales) / 1.1
      ).toFixed(2);
    } else {
      gstKitchacoInStoreCashSales = 0;
      netKitchacoInStoreCashSales = 0;
    }

    gstKitchacoOnlineDeliverySales = Number(
      Number(grossKitchacoOnlineDeliverySales) / 11
    ).toFixed(2);
    netKitchacoOnlineDeliverySales = Number(
      Number(grossKitchacoOnlineDeliverySales) / 1.1
    ).toFixed(2);

    gstKitchacoOnlinePickupSales = Number(
      Number(grossKitchacoOnlinePickupSales) / 11
    ).toFixed(2);
    netKitchacoOnlinePickupSales = Number(
      Number(grossKitchacoOnlinePickupSales) / 1.1
    ).toFixed(2);

    gstUberEatsSales = Number(Number(grossUberEatsSales) / 11).toFixed(2);
    netUberEatsSales = Number(Number(grossUberEatsSales) / 1.1).toFixed(2);

    gstDoordashSales = Number(Number(grossDoordashSales) / 11).toFixed(2);
    netDoordashSales = Number(Number(grossDoordashSales) / 1.1).toFixed(2);

    gstDeliverooSales = Number(Number(grossDeliverooSales) / 11).toFixed(2);
    netDeliverooSales = Number(Number(grossDeliverooSales) / 1.1).toFixed(2);

    gstMenulogSales = Number(Number(grossMenulogSales) / 11).toFixed(2);
    netMenulogSales = Number(Number(grossMenulogSales) / 1.1).toFixed(2);

    grossTotalRevenue =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    netTotalRevenue =
      Number(netKitchacoInStoreCashSales) +
      Number(netKitchacoInStoreCardSales) +
      Number(netKitchacoOnlineDeliverySales) +
      Number(netKitchacoOnlinePickupSales);
    gstTotalRevenue =
      Number(gstKitchacoInStoreCashSales) +
      Number(gstKitchacoInStoreCardSales) +
      Number(gstKitchacoOnlinePickupSales);

    grossTotalRevenueWithoutCashSales = grossTotalRevenue;
    netTotalRevenueWithoutCashSales = netTotalRevenue;
    gstTotalRevenueWithoutCashSales = gstTotalRevenue;

    if (lessCashSalesSelectedCheckbox == true) {
      grossTotalRevenueWithoutCashSales =
        grossTotalRevenueWithoutCashSales - grossKitchacoInStoreCashSales;
      netTotalRevenueWithoutCashSales =
        netTotalRevenueWithoutCashSales - netKitchacoInStoreCashSales;
      gstTotalRevenueWithoutCashSales =
        gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCashSales;
    }
    if (lessCardSalesSelectedCheckbox == true) {
      grossTotalRevenueWithoutCashSales =
        grossTotalRevenueWithoutCashSales -
        grossKitchacoInStoreCardSales -
        grossKitchacoOnlinePickupFee;
      netTotalRevenueWithoutCashSales =
        netTotalRevenueWithoutCashSales - netKitchacoInStoreCardSales;
      gstTotalRevenueWithoutCashSales =
        gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCardSales;
    }

    grossKitchacoInStoreCashFee =
      Number(
        Number(kitchaco_commission_percentage) *
          Number(grossKitchacoInStoreCashSales)
      ).toFixed(2) / 100;
    gstKitchacoInStoreCashFee = Number(
      Number(kitchaco_commission_percentage) / 11
    ).toFixed(2);
    netKitchacoInStoreCashFee = Number(
      Number(grossKitchacoInStoreCashFee) / 1.1
    ).toFixed(2);

    grossKitchacoInStoreCardFee =
      Number(
        Number(kitchaco_commission_percentage) *
          Number(grossKitchacoInStoreCardSales)
      ).toFixed(2) / 100;
    gstKitchacoInStoreCardFee = Number(
      Number(grossKitchacoInStoreCardFee) / 11
    ).toFixed(2);
    netKitchacoInStoreCardFee = Number(
      Number(grossKitchacoInStoreCardFee) / 1.1
    ).toFixed(2);

    grossKitchacoOnlineDeliveryFee = Number(
      Number(online_delivery_commission) *
        Number(grossKitchacoOnlineDeliverySales)
    ).toFixed(2);
    gstKitchacoOnlineDeliveryFee = Number(
      Number(grossKitchacoOnlineDeliveryFee) / 11
    ).toFixed(2);
    netKitchacoOnlineDeliveryFee = Number(
      Number(grossKitchacoOnlineDeliveryFee) / 1.1
    ).toFixed(2);

    grossKitchacoOnlinePickupFee = Number(
      Number(online_pickup_commission) * Number(grossKitchacoOnlinePickupSales)
    ).toFixed(2);
    gstKitchacoOnlinePickupFee = Number(
      Number(grossKitchacoOnlinePickupFee) / 11
    ).toFixed(2);
    netKitchacoOnlinePickupFee = Number(
      Number(grossKitchacoOnlinePickupFee) / 1.1
    ).toFixed(2);

    grossUberEatsFee = Number(
      Number(uber_eats_commission) * Number(grossUberEatsSales)
    ).toFixed(2);
    gstUberEatsFee = Number(Number(grossUberEatsFee) / 11).toFixed(2);
    netUberEatsFee = Number(Number(grossUberEatsFee) / 1.1).toFixed(2);

    grossDoordashFee = Number(
      Number(doordash_commission) * Number(grossDoordashSales)
    ).toFixed(2);
    gstDoordashFee = Number(Number(grossDoordashFee) / 11).toFixed(2);
    netDoordashFee = Number(Number(grossDoordashFee) / 1.1).toFixed(2);

    grossDeliverooFee = Number(
      Number(deliveroo_commission) * Number(grossDeliverooSales)
    ).toFixed(2);
    gstDeliverooFee = Number(Number(grossDeliverooFee) / 11).toFixed(2);
    netDeliverooFee = Number(Number(grossDeliverooFee) / 1.1).toFixed(2);

    grossMenulogFee = Number(
      Number(menulog_commission) * Number(grossMenulogSales)
    ).toFixed(2);
    gstMenulogFee = Number(Number(grossMenulogFee) / 11).toFixed(2);
    netMenulogFee = Number(Number(grossMenulogFee) / 1.1).toFixed(2);


    

    grossTotalPurchases = Number(
      Number(grossKitchacoInStoreCashFee) +
        Number(grossKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(grossMiscCharges1) +
        Number(grossMiscCharges2) +
        Number(grossMiscCharges3) +
        Number(grossMiscCharges4) +
        Number(grossMiscCharges5)
    ).toFixed(2);

    netTotalPurchases = Number(
      Number(netKitchacoInStoreCashFee) +
        Number(netKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(netMiscCharges1) +
        Number(netMiscCharges2) +
        Number(netMiscCharges3) +
        Number(netMiscCharges4) +
        Number(netMiscCharges5)
    ).toFixed(2);

    gstTotalPurchases = Number(
      Number(gstKitchacoInStoreCashFee) +
        Number(gstKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(gstMiscCharges1) +
        Number(gstMiscCharges2) +
        Number(gstMiscCharges3) +
        Number(gstMiscCharges4) +
        Number(gstMiscCharges5)
    ).toFixed(2);

    grossTotalSalesLessExpenditure = Number(
      Number(grossTotalRevenue) - Number(grossTotalPurchases)
    ).toFixed(2);
    netTotalSalesLessExpenditure = Number(
      Number(netTotalRevenue) - Number(netTotalPurchases)
    ).toFixed(2);
    gstTotalSalesLessExpenditure = Number(
      Number(gstTotalRevenue) - Number(gstTotalPurchases)
    ).toFixed(2);

    payoutResult = Number(
      Number(grossTotalRevenueWithoutCashSales) - Number(grossTotalPurchases)
    ).toFixed(2);
    netPayment = Number(Number(payoutResult) - Number(gst)).toFixed(2);
    gst = Number(Number(gstTotalRevenue) - Number(gstTotalPurchases)).toFixed(
      2
    );

    let totalWttCashCommission = 0;
    let totalWttCardCommission = 0; // Initialize comm to zero
    let totalWttVoucherCommission = 0;
    let totalWttEarnings = 0;
    let totalWttPayout = 0;
    let totalGstOnCharges = 0;

    for (var i = 0; i < eventsListDuringDatePeriod.length; i++) {
      const event = eventsListDuringDatePeriod[i];

      totalWttCardCommission += Number(event.wtt_card_fee_event);
      totalWttCashCommission += Number(event.wtt_cash_fee_event);
      totalWttVoucherCommission += Number(event.wtt_voucher_fee_event);
      totalWttEarnings += Number(event.total_wtt_earnings_for_event);
      totalWttPayout += Number(event.total_payout_for_event);
      totalGstOnCharges += Number(event.total_gst_for_event);
    }

    return (
      <>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={6} lg={6}>
                {vendors && vendors.length > 0 ? (
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>
                      Vendors {"(" + vendors.length + ")"}
                    </Form.Label>
                    <div className="App">
                      <div className="search-bar-container">
                        <SearchBarVendor
                          vendorList={vendors}
                          placeHolder={"Search Vendors"}
                          setFilteredVendors={setFilteredVendors}
                          selectedVendorId={this.state.selectedVendorId} // Pass the selected event ID
                          selectedVendorSearch={this.state.selectedVendorSearch}
                        />
                        <SearchResultsListVendor
                          filteredVendors={filteredVendors}
                          selectedVendorP={selectedVendorP}
                          selectedVendorQ={selectedVendorQ}
                        />
                      </div>
                    </div>
                    {console.log(vendors)}
                  </Form.Group>
                ) : null}
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={8} md={8} lg={8}>
                    {salesInvoiceFornightPeriod &&
                    salesInvoiceFornightPeriod.length > 0 ? (
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Label>Date Period</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          onChange={this.onChangeDatePeriod.bind(this)}
                          defaultValue={adminSalesInvoiceFortnightPeriod}
                        >
                          <option value={0}>Select Date Period</option>

                          {salesInvoiceFornightPeriod.map(
                            (salesInvoicePeriod, i) => {
                              return (
                                <option
                                  key={i}
                                  value={salesInvoiceFornightPeriod[i].id}
                                >
                                  {salesInvoiceFornightPeriod[i].start_date +
                                    " - " +
                                    salesInvoiceFornightPeriod[i].end_date}
                                </option>
                              );
                            }
                          )}
                        </Form.Control>
                      </Form.Group>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            {adminSalesInvoiceFortnightPeriod != 1 ? (
              <Row style={{ marginTop: 10 }}>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>
                    You can generate the report using the form below and email
                    it to any email id.
                  </p>
                  <div
                    style={{
                      borderWidth: 1,
                      borderColor: "grey",
                      borderStyle: "solid",
                      borderRadius: 5,
                      padding: 15,
                    }}
                  >
                    <h5>
                      <b>Revenue By Events</b>
                    </h5>

                    {eventsListDuringDatePeriod.map((eventData, index) => {
                      const {
                        grossTotalRevenue,
                        netTotalRevenue,
                        gstTotalRevenue,
                      } = this.calculateTotalRevenue(eventData);
                      const {
                        grossTotalPurchasesByEvent,
                        netTotalPurchasesByEvent,
                        gstTotalPurchasesByEvent,
                      } = this.calculateTotalPurchasesByEvent(eventData);
                      const { totalGstByEvent } = this.calculateTotalGstByEvent(
                        eventData
                      );
                      const {
                        grossTotalTransactionFee,
                        netTransactionFee,
                        gstTransactionFee,
                      } = this.calculateTotalTransactionFee(eventData);
                      const {
                        totalWttEarningsByEvent,
                      } = this.calculateTotalWttEarningsByEvent(eventData);
                      const {
                        totalPayoutByEvent,
                      } = this.calculateTotalWttPayoutByEvent(eventData);

                      return (
                        <div>
                          <br></br>
                          <h5> Report for {eventData.name} </h5>
                          <div style={{ padding: 10 }}>
                            <Row>
                              <Col xs={3} md={3} lg={3}></Col>
                              <Col xs={3} md={3} lg={3}>
                                <b>Gross ($)</b>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <b>Net($)</b>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <b>GST($)</b>
                              </Col>
                            </Row>
                          </div>

                          {/* ADD */}
                          <div
                            style={{
                              borderWidth: 1,
                              borderColor: "black",
                              borderStyle: "solid",
                              padding: 10,
                            }}
                          >
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <p>Cash Sales</p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    value={Number(
                                      eventsListDuringDatePeriod[index]
                                        .gross_vendor_cash_sales
                                    ).toFixed(2)}
                                    onChange={(event) =>
                                      this.handleWttCashSales(index, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {lessCashSalesSelectedCheckbox == true
                                  ? 0
                                  : eventData.gross_vendor_cash_sales &&
                                    Number(
                                      Number(
                                        eventData.gross_vendor_cash_sales
                                      ) / 1.1
                                    ).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {lessCashSalesSelectedCheckbox == true
                                  ? 0
                                  : eventData.gross_vendor_cash_sales &&
                                    Number(
                                      Number(
                                        eventData.gross_vendor_cash_sales / 11
                                      )
                                    ).toFixed(2)}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <p>Card Sales</p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    value={Number(
                                      eventsListDuringDatePeriod[index]
                                        .gross_vendor_card_sales
                                    ).toFixed(2)}
                                    onChange={(event) =>
                                      this.handleWttCardSales(index, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {lessCardSalesSelectedCheckbox == true
                                  ? 0
                                  : eventData.gross_vendor_card_sales &&
                                    Number(
                                      Number(
                                        eventData.gross_vendor_card_sales
                                      ) / 1.1
                                    ).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {lessCardSalesSelectedCheckbox == true
                                  ? 0
                                  : eventData.gross_vendor_card_sales &&
                                    Number(
                                      Number(
                                        eventData.gross_vendor_card_sales
                                      ) / 11
                                    ).toFixed(2)}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <p> Voucher Sales</p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    value={Number(
                                      eventsListDuringDatePeriod[index]
                                        .gross_vendor_voucher_sales
                                    ).toFixed(2)}
                                    onChange={(event) =>
                                      this.handleWttVoucherSales(index, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gross_vendor_voucher_sales &&
                                  Number(
                                    Number(
                                      eventData.gross_vendor_voucher_sales / 1.1
                                    )
                                  ).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gross_vendor_voucher_sales &&
                                  Number(
                                    Number(
                                      eventData.gross_vendor_voucher_sales
                                    ) / 11
                                  ).toFixed(2)}
                              </Col>
                            </Row>


{/* Surge Charge */}
<Row>
                              <Col xs={3} md={3} lg={3}>
                                <p> Surge Charge</p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    value={Number(
                                      eventsListDuringDatePeriod[index]
                                        .gross_surge_charge
                                    ).toFixed(2)}
                                    onChange={(event) =>
                                      this.handleWttVoucherSales(index, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gross_vendor_voucher_sales &&
                                  Number(
                                    Number(
                                      eventData.gross_vendor_voucher_sales / 1.1
                                    )
                                  ).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gross_vendor_voucher_sales &&
                                  Number(
                                    Number(
                                      eventData.gross_vendor_voucher_sales
                                    ) / 11
                                  ).toFixed(2)}
                              </Col>
                            </Row>


                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <p>Wtt Commission % </p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    value={
                                      this.state.eventsListDuringDatePeriod[
                                        index
                                      ].commission_percentage_By_event
                                    }
                                    onChange={(event) =>
                                      this.handleCommissionChange(index, event)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <h5 style={{ marginBottom: 10, marginTop: 10 }}>
                              <b>Less</b>
                            </h5>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <p>Total Wtt In Store Cash Fee</p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.wtt_cash_fee_event}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.wtt_cash_fee_event / 1.1}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.wtt_cash_fee_event / 11}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <p>Total WTT In Store Card Fee</p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.wtt_card_fee_event}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(
                                  eventData.wtt_card_fee_event / 1.1
                                ).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(
                                  eventData.wtt_card_fee_event / 11
                                ).toFixed(2)}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <h6>
                                  <b>Total Purchases</b>
                                </h6>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(grossTotalPurchases).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(netTotalPurchases).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(gstTotalPurchases).toFixed(2)}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <h6>
                                  <b>Total Transaction Fee</b>
                                </h6>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(grossTotalTransactionFee).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(netTransactionFee).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(gstTransactionFee).toFixed(2)}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <h6>
                                  <b>Total Revenue</b>
                                </h6>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(grossTotalRevenue).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(netTotalRevenue).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(gstTotalRevenue).toFixed(2)}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <p>Total Wtt Voucher Fee</p>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.wtt_voucher_fee_event}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.wtt_voucher_fee_event / 1.1}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.wtt_voucher_fee_event / 11}
                              </Col>
                            </Row>

                            <p>
                              <b>Add Misc Charges Below</b>
                            </p>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Row>
                                    <Col
                                      xs={4}
                                      md={4}
                                      lg={4}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="GST?"
                                        checked={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].isGstMiscCharges1
                                        }
                                        onChange={(event) =>
                                          this.handleGstMiscCharges1Toggle(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="eg:Utility"
                                        value={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].labelMiscCharges1
                                        }
                                        onChange={(event) =>
                                          this.handleLabelMiscCharges1Change(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    placeholder="20.00"
                                    value={
                                      this.state.eventsListDuringDatePeriod[
                                        index
                                      ].grossMiscCharges1
                                    }
                                    onChange={(event) =>
                                      this.handleGrossMiscCharges1Change(
                                        index,
                                        event
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.netMiscCharges1}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gstMiscCharges1}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Row>
                                    <Col
                                      xs={4}
                                      md={4}
                                      lg={4}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="GST?"
                                        checked={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].isGstMiscCharges2
                                        }
                                        onChange={(event) =>
                                          this.handleGstMiscCharges2Toggle(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="eg:Utility"
                                        value={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].labelMiscCharges2
                                        }
                                        onChange={(event) =>
                                          this.handleLabelMiscCharges2Change(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    placeholder="20.00"
                                    value={
                                      this.state.eventsListDuringDatePeriod[
                                        index
                                      ].grossMiscCharges2
                                    }
                                    onChange={(event) =>
                                      this.handleGrossMiscCharges2Change(
                                        index,
                                        event
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.netMiscCharges2}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gstMiscCharges2}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Row>
                                    <Col
                                      xs={4}
                                      md={4}
                                      lg={4}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="GST?"
                                        checked={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].isGstMiscCharges3
                                        }
                                        onChange={(event) =>
                                          this.handleGstMiscCharges3Toggle(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="eg:Utility"
                                        value={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].labelMiscCharges3
                                        }
                                        onChange={(event) =>
                                          this.handleLabelMiscCharges3Change(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    placeholder="20.00"
                                    value={
                                      this.state.eventsListDuringDatePeriod[
                                        index
                                      ].grossMiscCharges3
                                    }
                                    onChange={(event) =>
                                      this.handleGrossMiscCharges3Change(
                                        index,
                                        event
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.netMiscCharges3}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gstMiscCharges3}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Row>
                                    <Col
                                      xs={4}
                                      md={4}
                                      lg={4}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="GST?"
                                        checked={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].isGstMiscCharges4
                                        }
                                        onChange={(event) =>
                                          this.handleGstMiscCharges4Toggle(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="eg:Utility"
                                        value={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].labelMiscCharges4
                                        }
                                        onChange={(event) =>
                                          this.handleLabelMiscCharges4Change(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    placeholder="20.00"
                                    value={
                                      this.state.eventsListDuringDatePeriod[
                                        index
                                      ].grossMiscCharges4
                                    }
                                    onChange={(event) =>
                                      this.handleGrossMiscCharges4Change(
                                        index,
                                        event
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.netMiscCharges4}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gstMiscCharges4}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Row>
                                    <Col
                                      xs={4}
                                      md={4}
                                      lg={4}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="GST?"
                                        checked={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].isGstMiscCharges5
                                        }
                                        onChange={(event) =>
                                          this.handleGstMiscCharges5Toggle(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      md={6}
                                      lg={6}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="eg:Utility"
                                        value={
                                          this.state.eventsListDuringDatePeriod[
                                            index
                                          ].labelMiscCharges5
                                        }
                                        onChange={(event) =>
                                          this.handleLabelMiscCharges5Change(
                                            index,
                                            event
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="number"
                                    placeholder="20.00"
                                    value={
                                      this.state.eventsListDuringDatePeriod[
                                        index
                                      ].grossMiscCharges5
                                    }
                                    onChange={(event) =>
                                      this.handleGrossMiscCharges5Change(
                                        index,
                                        event
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.netMiscCharges5}
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {eventData.gstMiscCharges5}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <h5>
                                  <b>Total Wtt Earnings</b>
                                </h5>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                <h5>
                                  <b>
                                    {Number(totalWttEarningsByEvent).toFixed(2)}
                                  </b>
                                </h5>
                              </Col>
                              <Col xs={3} md={3} lg={3}></Col>
                              <Col xs={3} md={3} lg={3}></Col>
                            </Row>

                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <h6>
                                  <b>Total Payout (inc GST)</b>
                                </h6>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(totalPayoutByEvent).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}></Col>
                              <Col xs={3} md={3} lg={3}></Col>
                            </Row>

                            <Row>
                              <Col xs={3} md={3} lg={3}>
                                <h6>
                                  <b>GST</b>
                                </h6>
                              </Col>
                              <Col xs={3} md={3} lg={3}>
                                {Number(totalGstByEvent).toFixed(2)}
                              </Col>
                              <Col xs={3} md={3} lg={3}></Col>
                              <Col xs={3} md={3} lg={3}></Col>
                            </Row>
                          </div>
                        </div>
                      );
                    })}

                    {/* {salesReport &&
                      salesReport.map((sales, index) => {
                        var totalRevenueForLocation =
                          Number(grossKitchacoInStoreCardSalesArray[index]) +
                          Number(grossKitchacoInStoreCashSalesArray[index]) +
                          this.state.grossKitchacoOnlinePickupSales;

                        return (
                          <div
                            key={index}
                            style={{
                              borderWidth: 1,
                              borderColor: "black",
                              borderStyle: "solid",
                              padding: 10,
                            }}
                          >
                            
                          </div>
                        );
                      })} */}

                    <Col xs={3} md={3} lg={3}>
                      <Form.Check
                        type="checkbox"
                        label="Less Cash Sales Collected?"
                        checked={lessCashSalesSelectedCheckbox}
                        onChange={() => {
                          if (
                            this.state.lessCashSalesSelectedCheckbox == true
                          ) {
                            this.setState({
                              lessCashSalesSelectedCheckbox: false,
                            });
                          } else {
                            this.setState({
                              lessCashSalesSelectedCheckbox: true,
                            });
                          }
                        }}
                      />
                    </Col>
                    <Col xs={3} md={3} lg={3}>
                      -{Number(grossKitchacoInStoreCashSales).toFixed(2)}
                    </Col>
                    <Col xs={3} md={3} lg={3}></Col>
                    <Col xs={3} md={3} lg={3}></Col>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Check
                          type="checkbox"
                          label="Less Card Sales Collected?"
                          checked={lessCardSalesSelectedCheckbox}
                          onChange={() => {
                            if (
                              this.state.lessCardSalesSelectedCheckbox == true
                            ) {
                              this.setState({
                                lessCardSalesSelectedCheckbox: false,
                              });
                            } else {
                              this.setState({
                                lessCardSalesSelectedCheckbox: true,
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        -{Number(grossKitchacoInStoreCardSales).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}></Col>
                      <Col xs={3} md={3} lg={3}></Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6>
                          <b>
                            Total Revenue Without Cash{" "}
                            {lessCardSalesSelectedCheckbox ? " and Card " : ""}{" "}
                            Sales
                          </b>
                        </h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(grossTotalRevenueWithoutCashSales).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(netTotalRevenueWithoutCashSales).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(gstTotalRevenueWithoutCashSales).toFixed(2)}
                      </Col>
                    </Row>
                    <h5 style={{ marginBottom: 10, marginTop: 10 }}>
                      <b>Less</b>
                    </h5>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Wtt In Store Cash Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttCashCommission}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttCashCommission / 1.1}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttCashCommission / 11}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total WTT In Store Card Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttCardCommission}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttCardCommission / 1.1}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttCardCommission / 11}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Wtt Voucher Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttVoucherCommission}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttVoucherCommission / 1.1}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {totalWttVoucherCommission / 11}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Stock Purchased</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossStockPurchased}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netStockPurchased}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstStockPurchased}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h5>
                          <b>Total Wtt Earnings</b>
                        </h5>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <h5>
                          <b>{Number(totalWttEarnings).toFixed(2)}</b>
                        </h5>
                      </Col>
                      <Col xs={3} md={3} lg={3}></Col>
                      <Col xs={3} md={3} lg={3}></Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6>
                          <b>Total Payout (inc GST)</b>
                        </h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(totalWttPayout).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}></Col>
                      <Col xs={3} md={3} lg={3}></Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6>
                          <b>GST</b>
                        </h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(totalGstOnCharges).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}></Col>
                      <Col xs={3} md={3} lg={3}></Col>
                    </Row>
                    <hr></hr>
                    <Row style={{ marginTop: 20 }}>
                      <Col xs={4} md={4} lg={4}>
                        <h4>Report Configuartion</h4>
                        <p>
                          {approved_by != ""
                            ? "Already Approved on " +
                              approved_on +
                              " by " +
                              approved_by
                            : ""}
                        </p>
                        <Button
                          onClick={() => {
                            this.saveSalesInvoice(
                              grossTotalRevenue,
                              gst,
                              netUberEatsFee,
                              netDoordashFee,
                              netDeliverooFee,
                              netMenulogFee,
                              grossKitchacoInStoreCashFee,
                              grossKitchacoInStoreCardFee,
                              grossKitchacoOnlinePickupFee,
                              netKitchacoInStoreCardFee,
                              netKitchacoOnlinePickupFee,
                              netKitchacoOnlineDeliveryFee,
                              payoutResult,
                              grossTotalSalesLessExpenditure
                            );
                          }}
                        >
                          {approved_by == ""
                            ? "Approve"
                            : "Update and Approve Again"}
                        </Button>
                      </Col>
                      <Col xs={4} md={4} lg={4}>
                        {approved_by != "" ? (
                          <>
                            <p>Send Email As</p>
                            <Form>
                              <div key={"inline-radio"} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Wtt"
                                  name="group1"
                                  type={"radio"}
                                  id={"inline-radio-1"}
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({ sendEmailAs: "wtt" });
                                    } else {
                                      this.setState({ sendEmailAs: "" });
                                    }
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="Multibrands"
                                  name="group1"
                                  type={"radio"}
                                  id={"inline-radio-2"}
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({ sendEmailAs: "wtt" });
                                    } else {
                                      this.setState({ sendEmailAs: "" });
                                    }
                                  }}
                                />
                              </div>
                            </Form>
                          </>
                        ) : null}
                      </Col>
                      <Col xs={4} md={4} lg={4}>
                        {approved_by != "" ? (
                          <>
                            <p>&nbsp;</p>
                            <Button
                              onClick={() => {
                                this.sendEmail(
                                  totalWttCashCommission,
                                  totalWttCardCommission,
                                  totalWttVoucherCommission,
                                  totalWttEarnings,
                                  totalWttPayout,
                                  totalGstOnCharges,
                                  eventsListDuringDatePeriod
                                );
                              }}
                            >
                              Email a copy
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => {
                              this.sendEmail(
                                totalWttCashCommission,
                                totalWttCardCommission,
                                totalWttVoucherCommission,
                                totalWttEarnings,
                                totalWttPayout,
                                totalGstOnCharges,
                                eventsListDuringDatePeriod
                              );
                            }}
                          >
                            Email a copy
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>{errorMessage} </p>
                </Col>
              </Row>
            )}
          </Container>
        )}
        <Footer />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(AdminSalesReportByEvent);
