import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import CloseIcon from "@mui/icons-material/Close";
import VendorMenuAddScreen from "../VendorMenuAddScreen";
import VendorMenuEditScreen from "../VendorMenuEditScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

class OperatorMenuScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operator_id: null,
      isLoading: false,
      tempMenuDetails: [],
      showVendorMenuAddScreen: false,
      showVendorMenuEditScreen: false,
      mealProp: [],
      menuDetails: [],
      isLoading: false,
      selectedBrandMeals: [],
      tempSelectedBrandMeals: [],
      selectedVendorId: -1,
      selectedVendorName: "",
    };
    this.abortController = new AbortController();
  }

  componentDidMount() {
    this.getMenu();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }

  getMenu() {
    const { operator_id } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_menu_for_operator_stalls",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      signal: this.abortController.signal,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
          if (dataResponse.success) {
            this.setState({
              menuDetails: dataResponse.menu_details,
              tempMenuDetails: dataResponse.menu_details,
            });
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
        }
      });
  }
  render() {
    const {
      menuDetails,
      selectedBrandMeals,
      isLoading,
      selectedVendorId,
      selectedVendorName,
    } = this.state;
    return (
      <div>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          {isLoading == false ? (
            <Row>
              <Col
                xs={12}
                md={3}
                lg={3}
                style={{
                  backgroundColor: "#f1f1f1",
                  borderRightWidth: 1,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderStyle: "solid",
                  borderColor: "black",
                  padding: 0,
                }}
              >
                <div>
                  <h3
                    style={{
                      padding: 20,
                      borderBottomWidth: 1,
                      borderTopWidth: 1,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderColor: "black",
                      borderStyle: "solid",
                    }}
                  >
                    Trucks
                  </h3>
                </div>
                {menuDetails &&
                  menuDetails.map((item, index) => {
                    return (
                      <div
                        key={item.stalls__id}
                        style={
                          selectedVendorId == item.stalls__id
                            ? {
                                backgroundColor: "white",
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                            : {
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                        }
                        onClick={() => {
                          this.setState(
                            {
                              selectedBrandMeals: item.meals,
                              tempSelectedBrandMeals: item.meals,
                              selectedVendorId: item.stalls__id,
                              selectedVendorName: item.title,
                              showVendorMenuAddScreen: false,
                              showVendorMenuEditScreen: false,
                            },
                            () =>
                              saveToLocalStorage(
                                "selectedVendorId",
                                item.stalls__id
                              )
                          );
                        }}
                      >
                        {item.stalls__title}
                      </div>
                    );
                  })}
              </Col>
              <Col xs={12} md={9} lg={9} className="mobile-padding">
                <Row className="mobile-only">
                  <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <h4>
                      <b>selectedVendorName</b>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          this.onSearch(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  
                  <Col xs={6} md={4} lg={4}>
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      onChange={(e) => {
                        this.onSort(e);
                      }}
                    >
                      <option value={1}>A - Z</option>
                      <option value={2}>Z - A</option>
                      <option value={3}>Status: Enabled - Disabled</option>
                      <option value={4}>Status: Disabled - Enabled</option>
                    </Form.Control>
                  </Col>
                  <Col xs={6} md={2} lg={2}>
                    <Button
                      style={{ margin: 10 }}
                      onClick={() => {
                        if (this.state.selectedVendorId == -1) {
                          alert(
                            "Please select ateleast 1 brand before adding the menu"
                          );
                        } else {
                          this.setState({ showVendorMenuAddScreen: true });
                        }
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                  <label>Please add a list of items you wish to sell at this stall, including category and price & Meal Images, Event Organisers won't be able to accept your application should meal images and description has not been filled </label>
                </Row>
                {this.state.showVendorMenuAddScreen &&
                  (window.location.href =
                    "/operator-add-menu:" + this.state.selectedVendorId)}
                {this.state.showVendorMenuEditScreen &&
                  (() => {
                    saveToLocalStorage(
                      "editMeal",
                      JSON.stringify(this.state.mealProp)
                    );
                    window.location.href =
                      "/operator-edit-menu:" + this.state.selectedVendorId;
                    return null; // IIFE needs to return something as it's within JSX.
                  })()}

                {selectedBrandMeals && selectedBrandMeals.length > 0 ? (
                  <Row>
                    {selectedBrandMeals.map((meal, i) => (
                      <>
                        {meal.status === "Enabled" ? (
                          !meal.meal_image && meal.description ? (
                            <p style={{ color: "red", paddingLeft: "20px" }}>
                              * Meal image is missing
                            </p>
                          ) : !meal.description && meal.meal_image ? (
                            <p style={{ color: "red", paddingLeft: "20px" }}>
                              * Meal description is missing
                            </p>
                          ) : !meal.meal_image && !meal.description ? (
                            <p style={{ color: "red", paddingLeft: "20px" }}>
                              * Meal image and description are missing
                            </p>
                          ) : null
                        ) : null}
                        <Col xs={12} md={12} lg={12} key={i}>
                          <Media
                            style={{
                              padding: 10,
                              borderBottomWidth: 1,
                              borderTopWidth: 0,
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              borderColor: "black",
                              borderStyle: "solid",
                            }}
                          >
                            <Image
                              style={{ width: 120 }}
                              src={
                                meal.meal_image &&
                                meal.meal_image !== "" &&
                                meal.meal_image !== "NULL"
                                  ? AWS_URL + "/" + meal.meal_image
                                  : require("../../assets/img/empty_logo.png")
                              }
                              alt={meal.meal_name} // Adding alt for better accessibility
                            />
                            <Media.Body style={{ marginLeft: 10 }}>
                              <Row>
                                <Col xs={12} md={6} lg={6}>
                                  <h4>{meal.meal_name}</h4>
                                </Col>
                                <Col
                                  xs={6}
                                  md={2}
                                  lg={2}
                                  style={{ padding: 0 }}
                                >
                                  <h4>${Number(meal.meal_price).toFixed(2)}</h4>
                                </Col>
                                <Col xs={6} md={4} lg={4}>
                                  <h4>{meal.status}</h4>
                                </Col>
                              </Row>

                              {meal.meal_combo.length > 0 ? (
                                <div
                                  style={{
                                    paddingBottom: 10,
                                    borderBottomWidth: 1,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                    borderColor: "black",
                                    borderStyle: "solid",
                                  }}
                                >
                                  <p style={{ marginBottom: 0 }}>
                                    <b>Combos</b>
                                  </p>
                                  {meal.meal_combo.map((combo) => {
                                    return (
                                      <Row>
                                        <Col xs={12} md={6} lg={6}>
                                          {combo.name}
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={2}
                                          lg={2}
                                          style={{ padding: 0 }}
                                        >
                                          ${Number(combo.price).toFixed(2)}
                                        </Col>
                                        <Col xs={6} md={4} lg={4}>
                                          {combo.status}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {meal.meal_subitem.length > 0 ? (
                                <div style={{ paddingBottom: 10 }}>
                                  <p style={{ marginBottom: 0 }}>
                                    <b>SubItem</b>
                                  </p>
                                  {meal.meal_subitem.map((subitem) => {
                                    return (
                                      <Row>
                                        <Col xs={12} md={6} lg={6}>
                                          {subitem.name}
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={2}
                                          lg={2}
                                          style={{ padding: 0 }}
                                        ></Col>
                                        <Col xs={6} md={4} lg={4}>
                                          {subitem.status}
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                              ) : null}
                              <Row>
                                <Col xs={12} md={6} lg={6}></Col>
                                <Col
                                  xs={6}
                                  md={2}
                                  lg={2}
                                  style={{ padding: 0 }}
                                ></Col>
                                <Col xs={6} md={4} lg={4}>
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        showVendorMenuEditScreen: true,
                                        mealProp: { meal },
                                      });
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </Col>
                              </Row>
                            </Media.Body>
                          </Media>
                        </Col>
                      </>
                    ))}
                  </Row>
                ) : (
                  <div>No results</div>
                )}
              </Col>
            </Row>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    );
  }
  onSearch = (e) => {
    let menuItems = this.state.tempSelectedBrandMeals;
    menuItems = menuItems.filter((el) => {
      return el.meal_name.search(e.target.value) != -1;
    });
    this.setState({ selectedBrandMeals: menuItems });
  };
  onSort = (e) => {
    let menuItems = this.state.tempSelectedBrandMeals;
    if (e.target.value == 1) {
      menuItems.sort((a, b) => (a.name > b.name && 1) || -1);
    }
    if (e.target.value == 2) {
      menuItems.sort((a, b) => (a.name < b.name && 1) || -1);
    }
    if (e.target.value == 3) {
      menuItems.sort((a, b) => (a.status < b.status && 1) || -1);
    }
    if (e.target.value == 4) {
      menuItems.sort((a, b) => (a.status > b.status && 1) || -1);
    }

    this.setState({ selectedBrandMeals: menuItems });
  };
}
export default Sentry.withErrorBoundary(OperatorMenuScreen, {
  fallback: <ErrorFallbackComponent />,
});
