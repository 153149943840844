import React, { useState } from "react";
import Container from "@mui/material/Container";
import StallItem from "./StallItem";
import { Box, Modal, Fade, Typography, Button } from "@mui/material";
import RegModal from "./RegModal";
import "./style1.css";
import CloseIcon from "@mui/icons-material/Close";
import RegisterStall from "../../RegisterStall";
import UploadDocs from "../../UploadDocs";
const StallGallery = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [showForm, setShowForm] = useState(false);
  const handleFormClose = () => {
    setShowForm(false);
  };
  const [showUpload, setShowUpload] = useState(false);
  const handleUploadClose = () => {
    setShowUpload(false);
  };
  const { stallList, operator } = props;
  const style2 = {
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "700px",
    height: "700px",
    overflow: "auto",
    padding: "20px",
  };
  const style = {
    position: "sticky",
    zIndex: 1,
    top: "70px",
    display: "flex",
    height: 200,
    overflow: "scroll",
    marginBottom: "4px",
  };
  const style1 = {
    border: "4px solid #fff",
    backgroundColor: "#fff",
    position: "sticky",
    zIndex: 1,
    top: "30px",
    display: "flex",
    overflow: "scroll",
    marginBottom: "4px",
  };
  return (
    <>
      <RegModal show={showModal} handleClose={handleClose} />
      <div className="css-1j22pvs">
        <div className="css-yui7e32">
          Please upload your documents before applying{" "}
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="css-yui7e3">
          Drag and Drop your preferred outlets into the events you are
          interested to apply!
        </div>
      </div>
      {/* <Button
        variant="contained"
        style={{ margin: 10 }}
        onClick={() => setShowModal(true)}
      >
        Add New Outlets
      </Button>
      <Button 
        color="primary"
        variant="contained"
        style={{ margin: 10 }}
        onClick={() => setShowForm(true)}
      >
        Add New Outlets
      </Button>
      <Button 
        color="primary"
        variant="contained"
        style={{ margin: 10 }}
        onClick={() => setShowUpload(true)}
      >
        My Documents
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showForm}
        onClose={handleFormClose}
        className="modalnvm"
      >
        <Box className="modalnvm-content">
          <CloseIcon className="plzHover" fontSize= "large" style={{ margin: 10,  float: "right"}}
            onClick={handleFormClose}/>
          <RegisterStall />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showUpload}
        onClose={handleUploadClose}
        className="modalnvm"
      >
        <Box className="modalnvm-content">
          <CloseIcon className="plzHover" fontSize= "large" style={{ margin: 10,  float: "right"}}
            onClick={handleUploadClose}/>
          <UploadDocs />
        </Box>
      </Modal> */}
      {stallList.length > 0 ? (
        <Container sx={style}>
          {stallList.map((stall, index) => (
            <StallItem item={stall} key={index} operatorInfo={operator} />
          ))}
        </Container>
      ) : (
        <div>
          <span style={{ color: "red" }}>
            Note: Please add your outlet before applying to an event. *
          </span>
        </div>
      )}
    </>
  );
};

export default StallGallery;
