import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import CloseIcon from "@mui/icons-material/Close";
import VendorMenuAddScreen from "../VendorMenuAddScreen";
import VendorMenuEditScreen from "../VendorMenuEditScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { Switch } from "@mui/material";
import CalendarView from "./CalendarView";

const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

class VendorEOI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operator_id: null,
      isCalendarView: false,
      isLoading: false,
      tempMenuDetails: [],
      showVendorMenuAddScreen: false,
      showVendorMenuEditScreen: false,
      mealProp: [],
      menuDetails: [],
      isLoading: false,
      selectedBrandMeals: [],
      tempSelectedBrandMeals: [],
      selectedVendorId: -1,
      selectedVendorName: "",
    };
    this.abortController = new AbortController();
  }

  componentDidMount() {
    this.getMenu();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }

  getMenu() {
    const { operator_id } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_menu_for_operator_stalls",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      signal: this.abortController.signal,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
          if (dataResponse.success) {
            this.setState({
              menuDetails: dataResponse.menu_details,
              tempMenuDetails: dataResponse.menu_details,
            });
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
        }
      });
  }
  render() {
    const {
      menuDetails,
      selectedBrandMeals,
      isLoading,
      selectedVendorId,
      selectedVendorName,
      isCalendarView,
    } = this.state;
    const { eventsList } = this.props;

    return (
      <div>
        <Container
          maxWidth={!isCalendarView ? 1110 : 2000}
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <div>
            Show Calendar
            <Switch
              checked={isCalendarView}
              onChange={(e) => {
                this.setState({
                  isCalendarView: e.target.checked,
                });
              }}
            />
          </div>
          {isLoading == false ? (
            <>
              <Row>
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  style={{
                    backgroundColor: "#f1f1f1",
                    borderRightWidth: 1,
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 0,
                    borderStyle: "solid",
                    borderColor: "black",
                    padding: 0,
                  }}
                >
                  <div>
                    <h3
                      style={{
                        padding: 20,
                        borderBottomWidth: 1,
                        borderTopWidth: 1,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderColor: "black",
                        borderStyle: "solid",
                      }}
                    >
                      Trucks
                    </h3>
                  </div>
                  {menuDetails &&
                    menuDetails.map((item, index) => {
                      return (
                        <div
                          key={item.stalls__id}
                          style={
                            selectedVendorId == item.stalls__id
                              ? {
                                  backgroundColor: "white",
                                  cursor: "pointer",
                                  padding: 20,
                                  borderBottomWidth: 1,
                                  borderTopWidth: 0,
                                  borderLeftWidth: 0,
                                  borderRightWidth: 0,
                                  borderColor: "black",
                                  borderStyle: "solid",
                                }
                              : {
                                  cursor: "pointer",
                                  padding: 20,
                                  borderBottomWidth: 1,
                                  borderTopWidth: 0,
                                  borderLeftWidth: 0,
                                  borderRightWidth: 0,
                                  borderColor: "black",
                                  borderStyle: "solid",
                                }
                          }
                          onClick={() => {
                            this.setState(
                              {
                                selectedBrandMeals: item.meals,
                                tempSelectedBrandMeals: item.meals,
                                selectedVendorId: item.stalls__id,
                                selectedVendorName: item.title,
                                showVendorMenuAddScreen: false,
                                showVendorMenuEditScreen: false,
                              },
                              () =>
                                saveToLocalStorage(
                                  "selectedVendorId",
                                  item.stalls__id
                                )
                            );
                          }}
                        >
                          {item.stalls__title}
                        </div>
                      );
                    })}
                </Col>
                <Col xs={12} md={9} lg={9} className="mobile-padding">
                  {isCalendarView ? (
                    <>
                      {eventsList && eventsList.length > 0 ? (
                        <CalendarView
                          eventsList={eventsList
                            .filter((event) =>
                              event.vendor
                                ?.map((vendor) => vendor.id)
                                .find((id) => id == selectedVendorId)
                            )
                            .map((event) => ({
                              ...event.eventDetails,
                              eoi_stat: event.vendor.find(
                                (v) => v.id == selectedVendorId
                              )?.eoi_stat,
                            }))}
                        />
                      ) : (
                        <div>No results</div>
                      )}
                    </>
                  ) : (
                    <>
                      {eventsList && eventsList.length > 0 ? (
                        <Row>
                          {eventsList.map((event, i) => {
                            const vendorIds =
                              event && event.vendor
                                ? event.vendor.map((vendor) => vendor.id)
                                : [];
                            const hasVendor = vendorIds.includes(
                              this.state.selectedVendorId
                            );
                            if (hasVendor) {
                              return (
                                <Col xs={12} md={12} lg={12}>
                                  <Media
                                    key={i}
                                    style={{
                                      padding: 10,
                                      borderBottomWidth: 1,
                                      borderTopWidth: 0,
                                      borderLeftWidth: 0,
                                      borderRightWidth: 0,
                                      borderColor: "black",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <Image
                                      style={{ width: 120 }}
                                      src={
                                        event.eventDetails.eoi_cover &&
                                        event.eventDetails.eoi_cover != "" &&
                                        event.eventDetails.eoi_cover != null &&
                                        event.eventDetails.eoi_cover != "NULL"
                                          ? AWS_URL +
                                            "/" +
                                            event.eventDetails.eoi_cover
                                          : require("../../assets/img/empty_logo.png")
                                      }
                                    ></Image>
                                    <Media.Body style={{ marginLeft: 10 }}>
                                      <Row>
                                        <Col xs={12} md={6} lg={6}>
                                          <h5>
                                            {event.eventDetails.eoi_event_name}
                                          </h5>
                                        </Col>
                                      </Row>
                                    </Media.Body>
                                  </Media>
                                </Col>
                              );
                            }
                          })}
                        </Row>
                      ) : (
                        <div>No results</div>
                      )}
                    </>
                  )}
                  {/* <Row className="mobile-only">
                  <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <h4>
                      <b>selectedVendorName</b>
                    </h4>
                  </Col>
                </Row> */}
                </Col>
              </Row>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    );
  }
  onSearch = (e) => {
    let menuItems = this.state.tempSelectedBrandMeals;
    menuItems = menuItems.filter((el) => {
      return el.meal_name.search(e.target.value) != -1;
    });
    this.setState({ selectedBrandMeals: menuItems });
  };
}

export default Sentry.withErrorBoundary(VendorEOI, {
  fallback: <ErrorFallbackComponent />,
});
