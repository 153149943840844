import React, { Component } from "react";
import { connect } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";
import Container from "react-bootstrap/Container";
import * as Sentry from "@sentry/react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import moment from "moment";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import Footer from "../../components/UI/Footer";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import Modal from "react-bootstrap/Modal";

class OperatorRequestStockQuote extends Component {
  state = {
    stockUserLoggedIn: getFromLocalStorage("stockLoggedIn"),
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isOperatorStockAdmin:
      getFromLocalStorage("isOperatorStockAdmin") != "undefined" &&
      getFromLocalStorage("isOperatorStockAdmin") != "" &&
      getFromLocalStorage("isOperatorStockAdmin") != null
        ? getFromLocalStorage("isOperatorStockAdmin")
        : "false",
    operatorData: getFromLocalStorage("Operator"),
    stockList: new Array(),
    stockUserData:
      getFromLocalStorage("stockUserData") &&
      JSON.parse(getFromLocalStorage("stockUserData"))
        ? JSON.parse(getFromLocalStorage("stockUserData"))
        : new Array(),
    quantitiesArray: [],
    email:
      getFromLocalStorage("stockEmail") &&
      getFromLocalStorage("stockEmail") != ""
        ? getFromLocalStorage("stockEmail")
        : "",
    password:
      getFromLocalStorage("stockPassword") &&
      getFromLocalStorage("stockPassword") != ""
        ? getFromLocalStorage("stockPassword")
        : "",
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    hasRequestedValueForMoney: false,
    hasRequestedBestQualityProducts: false,
    isPlacingOrder: false,
    reminderData: new Array(),
    gstArray: [],
    showMinOrderDeliveryChargeModal: false,
    deliveryChargeOverride: false,
    stallNameNotes: "",
    vendor_ids: [],
    selectedVendorId: "",
    events: [],
    stockBrands: [],
    selectedStockBrandId: "0",
    stockCategories: [],
    selectedStockCategoryId: "0",
    fetchStockProductType: [],
    selectedStockProductTypeId: "0",
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
  };

  login = () => {
    const { email, password } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
      query_type: "admin_login",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.admin.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          if (result.data.is_stock_user) {
            this.setState(
              { stockUserLoggedIn: "true", stockUserData: result.data },
              () => {
                saveToLocalStorage("stockLoggedIn", "true");
                saveToLocalStorage("stockEmail", email);
                saveToLocalStorage("stockPassword", password);
                saveToLocalStorage(
                  "stockUserData",
                  JSON.stringify(result.data)
                );
                this.fetchStockListFromServer();
              }
            );
          } else if (result.data.is_super_admin == true) {
            alert("Error! Please login using the super admin login page!");
          } else {
            alert("Authentication Error! Please try again.");
          }
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  placeOrder = () => {
    const {
      isPlacingOrder,
      quantitiesArray,
      stockList,
      //   stockUserData,
      gstArray,
      email,
      password,
      operatorEmail,
      operatorPassword,
      deliveryChargeOverride,
      vendor_ids,
      selectedVendorId,
      selectedEventId,
    } = this.state;
    this.setState({ isPlacingOrder: true });
    var todayDate = moment(new Date());
    var todayDay = moment(todayDate).format("dddd");

    var stockData = [];
    var sub_total = 0;

    var total_gst = 0;
    var total = 0;

    for (var key in quantitiesArray) {
      var stockitem_sub_total = 0;
      stockitem_sub_total = Number(
        Number(stockList[key].price) * Number(quantitiesArray[key])
      ).toFixed(2);
      var stockitem_total_gst = 0;
      if (stockList[key].has_gst) {
        stockitem_total_gst = Number(
          Number(0.1 * stockList[key].price) * Number(quantitiesArray[key])
        ).toFixed(3);
      }
      var stockitem_total = Number(
        Number(stockitem_sub_total) + Number(stockitem_total_gst)
      ).toFixed(3);

      sub_total = Number(sub_total) + Number(stockitem_sub_total);
      if (stockList[key].has_gst) {
        total_gst = Number(total_gst) + Number(stockitem_total_gst);
      }

      total =
        Number(total) +
        Number(stockitem_sub_total) +
        Number(stockitem_total_gst);

      var obj = {
        id: stockList[key].id,
        stock_list_id: stockList[key].id,
        name: stockList[key].name,
        qty: quantitiesArray[key],
        qty_type: stockList[key].qty_type,
        price: stockList[key].price,
        par: stockList[key].par,
        sub_total: stockitem_sub_total,
        gst: stockitem_total_gst,
        total: stockitem_total,
        brand_name: stockList[key].brand_name__name,
        category_name: stockList[key].stock_category__name,
        product_name: stockList[key].product_name__name,
      };
      stockData.push(obj);
    }

    var orderData = {
      operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
      order_date: moment(new Date()).format("DD MMM YYYY"),
      order_time: moment(new Date()).format("hh:mm A"),
      sub_total: Number(sub_total).toFixed(2),
      gst: Number(total_gst).toFixed(2),
      total: Number(total).toFixed(2),
      stockData: stockData,
    };

    if (this.state.selectedVendorId == "") {
      this.setState({ selectedVendorId: vendor_ids[0].user_ptr_id });
    }
    if (stockData.length > 0) {
      var data = {};

      //   if (email != "" && password != "") {
      //     data = { "email": email, "password": password, "orderData": orderData, "query_type": "place_order" }
      //   }
      //   else if (operatorEmail != "" && operatorPassword != "") {
      //     data = { "email": operatorEmail, "password": operatorPassword, "orderData": orderData, "query_type": "place_order" }
      //   }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "place_quote_request",
            operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
            isOperator: true,
            orderData: orderData,
            has_requested_best_quality_products: this.state
              .hasRequestedBestQualityProducts,
            has_requested_value_for_money_products: this.state
              .hasRequestedValueForMoney,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(config.BASE_URI + apiPaths.operatorData, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.setState({ isPlacingOrder: false });
          if (result.success) {
            alert("Quote requested from suppliers ");
            window.location.reload();
          } else {
            alert(result.error);
            if (result.error_code == 1) {
              this.setState({ showMinOrderDeliveryChargeModal: true });
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isPlacingOrder: false });
        });
    } else {
      this.setState({ isPlacingOrder: false });
      alert("Please select altleast 1 item to order");
    }
  };

  fetch_vendor_ids = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ vendor_ids: result.stalls });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  fetchStockListFromServer = (selectedVendorId) => {
    const {
      stockUserData,
      email,
      password,
      operatorEmail,
      operatorPassword,
      selectedEventId,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_stock_catelogue",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.operatorData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ stockList: result.data });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  handleChange(i, hasGST, price, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
    });

    if (hasGST) {
      this.setState({
        gstArray: {
          ...this.state.gstArray,
          [i]: Number(Number(e.target.value) * (0.1 * Number(price))).toFixed(
            3
          ),
        },
      });
    }
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events_for_stock",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                // this.getVendorsInEvent();
              }
              //   this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  //   Stock Quote Queries

  fetchStockListBrands = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_stocklist_brands",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              stockBrands: dataResponse.stock_brands,
            },
            () => {
              if (this.state.selectedEventId != 0) {
                // this.getVendorsInEvent();
              }
              //   this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  fetchStockCategory = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_stocklist_categories",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              stockCategories: dataResponse.stock_categories,
            },
            () => {
              if (this.state.selectedEventId != 0) {
                // this.getVendorsInEvent();
              }
              //   this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  fetchStockProductType = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_product_type",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              productTypeList: dataResponse.product_type,
            },
            () => {
              if (this.state.selectedEventId != 0) {
                // this.getVendorsInEvent();
              }
              //   this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  componentDidMount() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn,
      isOperatorStockAdmin,
    } = this.state;
    if (
      stockUserLoggedIn === "true" ||
      isOperatorLoggedIn === "true" ||
      isOperatorStockAdmin === " true"
    ) {
      this.fetchStockListFromServer();
      this.fetch_vendor_ids();
      this.getAllEvents();
      this.fetchStockListBrands();
      this.fetchStockCategory();
      this.fetchStockProductType();
    }
  }

  render() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn,
      isOperatorStockAdmin,
    } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {isOperatorLoggedIn === "true" || isOperatorStockAdmin === "true"
          ? this.renderHomePage()
          : (window.location.href = "/operator-login")}
        <Container></Container>
      </div>
    );
  }

  renderNavBar() {
    const { stockUserLoggedIn, isOperatorLoggedIn, stockUserData } = this.state;
    return (
      <VendorNavBar isOperatorLoggedIn={isOperatorLoggedIn}></VendorNavBar>
    );
  }

  renderHomePage() {
    const {
      stockList,
      isPlacingOrder,
      reminderData,
      stockUserData,
      quantitiesArray,
      deliveryChargeOverride,
      stallNameNotes,
      vendor_ids,
      selectedVendorId,
      selectedEventId,
      events,
      selectedStockBrandId,
      stockBrands,
      stockCategories,
      selectedStockCategoryId,
      productTypeList,
      selectedProductTypeId,
    } = this.state;
    var total = 0;
    var sub_total = 0;
    var total_gst = 0;

    for (var key in quantitiesArray) {
      var stockitem_sub_total = 0;
      stockitem_sub_total = Number(
        Number(stockList[key].price) * Number(quantitiesArray[key])
      ).toFixed(2);
      var stockitem_total_gst = 0;
      if (stockList[key].has_gst) {
        stockitem_total_gst = Number(
          Number(0.1 * stockList[key].price) * Number(quantitiesArray[key])
        ).toFixed(3);
      }

      sub_total = Number(sub_total) + Number(stockitem_sub_total);
      if (stockList[key].has_gst) {
        total_gst = Number(total_gst) + Number(stockitem_total_gst);
      }

      total =
        Number(total) +
        Number(stockitem_sub_total) +
        Number(stockitem_total_gst);

      if (deliveryChargeOverride == true) {
        total =
          Number(total) + Number(stockUserData["min_stock_delivery_charge"]);
      }
    }

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>WTT Stock Quote Portal</h2>
              <h3>
                Operator{" "}
                {JSON.parse(getFromLocalStorage("Operator"))[0].first_name}{" "}
                {JSON.parse(getFromLocalStorage("Operator"))[0].last_name}
              </h3>
              <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                {/* <Form.Label>Stall name</Form.Label> */}

                {/* <Form.Group className="mb-3" controlId="formEventName">
                  <Form.Label>Select Event</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedEventId}
                      onChange={(e) => {
                        this.setState({ selectedEventId: "" + e.target.value });
                        saveToLocalStorage(
                          "selectedEventId",
                          "" + e.target.value
                        );
                      }}
                    >
                      <option value={0}>Select Event</option>
                      {events.map((e) => {
                        return (
                          <option value={"" + e.id}>
                            {e.name +
                              " - " +
                              e.start_date +
                              " (" +
                              e.event_status +
                              ")"}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Form.Group> */}

                <InputGroup className="mb-3">
                  <InputGroup.Text value={stockBrands}>
                    <img
                      style={{ width: 20 }}
                      src={require("../../assets/img/search.gif")}
                    ></img>
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Search Stock Catelogue"
                    aria-label="Search Stock Catelogue"
                    onChange={(e) => {
                      var searchString = e.target.value;
                      if (searchString == "") {
                        this.setState({
                          tempBookingRequests: this.state.stockBrands,
                        });
                      } else {
                        var result = stockBrands.filter((obj) => {
                          if (
                            obj.name.search(new RegExp(searchString, "i")) != -1
                          ) {
                            return obj;
                          }
                        });
                        this.setState({ tempBookingRequests: result });
                      }
                    }}
                  />
                </InputGroup>
                {/* <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Select Stall</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={selectedVendorId}
                    onChange={(e) => {
                      this.setState(
                        { selectedVendorId: "" + e.target.value },
                        () => {
                          this.fetchStockListFromServer(e.target.value);
                          this.renderHomePage();
                        }
                      );
                      saveToLocalStorage(
                        "selectedVendorId",
                        "" + e.target.value
                      );
                    }}
                  >
                    {vendor_ids && vendor_ids.length == 1 ? (
                      <option value={vendor_ids[0].user_ptr_id}>
                        {vendor_ids[0].title}
                      </option>
                    ) : (
                      <>
                        <option value={0}>Select Stall</option>
                        {vendor_ids &&
                          vendor_ids.map((e) => {
                            return (
                              <option value={"" + e.user_ptr_id}>
                                {e.title}
                              </option>
                            );
                          })}
                      </>
                    )}
                  </Form.Control>
                </Form.Group> */}
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Select Brand</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={selectedStockBrandId}
                    onChange={(e) => {
                      this.setState(
                        { selectedStockBrandId: "" + e.target.value },
                        () => {
                          //   this.fetchStockListFromServer(e.target.value);
                          this.renderHomePage();
                        }
                      );
                      saveToLocalStorage(
                        "selectedStockBrandId",
                        "" + e.target.value
                      );
                    }}
                  >
                    {stockBrands && stockBrands.length == 1 ? (
                      <option value={stockBrands[0].name}>
                        {stockBrands[0].name}
                      </option>
                    ) : (
                      <>
                        <option value={0}>Select Brand</option>
                        {stockBrands &&
                          stockBrands.map((e) => {
                            return <option value={"" + e.id}>{e.name}</option>;
                          })}
                      </>
                    )}
                  </Form.Control>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>Select Product Category</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedStockCategoryId}
                      onChange={(e) => {
                        this.setState(
                          { selectedStockCategoryId: "" + e.target.value },
                          () => {
                            //   this.fetchStockListFromServer(e.target.value);
                            this.renderHomePage();
                          }
                        );
                        saveToLocalStorage(
                          "selectedStockCategoryId",
                          "" + e.target.value
                        );
                      }}
                    >
                      {stockCategories && stockCategories.length == 1 ? (
                        <option value={stockCategories[0].id}>
                          {stockCategories[0].name}
                        </option>
                      ) : (
                        <>
                          <option value={0}>Select Product Category</option>
                          {stockCategories &&
                            stockCategories.map((e) => {
                              return (
                                <option value={"" + e.id}>{e.name}</option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Form.Group>
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Select Product Type</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={selectedVendorId}
                    onChange={(e) => {
                      this.setState(
                        { selectedVendorId: "" + e.target.value },
                        () => {
                          this.fetchStockListFromServer(e.target.value);
                          this.renderHomePage();
                        }
                      );
                      saveToLocalStorage(
                        "selectedVendorId",
                        "" + e.target.value
                      );
                    }}
                  >
                    {productTypeList && productTypeList.length == 1 ? (
                      <option value={productTypeList[0].id}>
                        {productTypeList[0].name}
                      </option>
                    ) : (
                      <>
                        <option value={0}>Select Product Type</option>
                        {productTypeList &&
                          productTypeList.map((e) => {
                            return <option value={"" + e.id}>{e.name}</option>;
                          })}
                      </>
                    )}
                  </Form.Control>
                  <Form.Check
                    type="checkbox"
                    id="Value For Money Products"
                    label="Value For Money Products"
                    // checked={

                    // }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({ hasRequestedValueForMoney: true });
                      } else {
                        this.setState({ hasRequestedValueForMoney: false });
                      }
                    }}
                  />
                  <Form.Check
                    type="checkbox"
                    id="Best Quality Products"
                    label="Best Quality Products"
                    // checked={

                    // }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({
                          hasRequestedBestQualityProducts: true,
                        });
                      } else {
                        this.setState({
                          hasRequestedBestQualityProducts: false,
                        });
                      }
                    }}
                  />
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
          {reminderData.length > 0 ? (
            <>
              <Row>
                <Col xs={12}>
                  <h6>
                    Following items are about to be expired. Please order them
                    in your next order -{" "}
                  </h6>
                </Col>
              </Row>
              {reminderData.map((item) => {
                return (
                  <Row>
                    <Col xs={12}>
                      <h6>
                        {item["name"] + " expiring on " + item["expiring_on"]}{" "}
                      </h6>
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col xs={12}>
                  <p>&nbsp;</p>
                  <h6>
                    <b>
                      Recommended quantities are shown based on your previous
                      order over 16 week period.
                    </b>{" "}
                  </h6>
                </Col>
              </Row>
            </>
          ) : null}
          <Row className="table-header-row" style={{ marginTop: 10 }}>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Id
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Product Name
            </Col>

            <Col xs={12} md={2} lg={2} className="table-header-col">
              Qty
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Brand
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Stock Category
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Product Category
            </Col>
          </Row>
          {stockList.map((item, i) => {
            var gst = 0.0;
            if (this.state.gstArray[i]) {
              gst = this.state.gstArray[i];
            }

            return (
              <>
                <Row key={i} className="table-row">
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <h4 className="desktop-only">{item.id}</h4>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <h4>{item.name}</h4>
                  </Col>

                  <Col xs={12} md={2} lg={2} className="table-col">
                    <Form.Group>
                      <Form.Control
                        type="email"
                        placeholder="Quantity Needed"
                        id={"qty_" + i}
                        value={this.state.quantitiesArray[i]}
                        name={this.state.quantitiesArray[i]}
                        onChange={this.handleChange.bind(
                          this,
                          i,
                          item.has_gst,
                          item.price
                        )}
                      />
                      <Form.Text className="text-muted">
                        {item.qty_type}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <h4>{item.brand_name__name}</h4>
                  </Col>

                  <Col xs={12} md={2} lg={2} className="table-col">
                    <h4>{item.stock_category__name}</h4>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <h4>{item.product_name__name}</h4>
                  </Col>
                </Row>
              </>
            );
          })}
          <Row className="table-row">
            <Col xs={12} md={1} lg={1} className="table-col"></Col>
            <Col xs={12} md={3} lg={3} className="table-col"></Col>
            <Col xs={12} md={2} lg={2} className="table-col"></Col>

            <Col xs={12} md={2} lg={2} className="table-col">
              <span>
                {/* <h4>
                  <b className="desktop-only">Total:</b>
                </h4> */}
              </span>
            </Col>
            <Col xs={12} md={2} lg={2} className="table-col">
              <span>
                {/* <h4>
                  <b className="mobile-only">Total:</b> $
                  {Number(total).toFixed(2)}
                </h4> */}
              </span>
            </Col>
          </Row>
          <Row className="button-row">
            {/* <Col xs={12}>
              <p>Any order below ${Number(stockUserData["min_stock_order"]).toFixed(2)} will incur a below minimum order fee of ${Number(stockUserData["min_stock_delivery_charge"]).toFixed(2)}  </p>
            </Col> */}
            <Col xs={12}>
              <Button
                onClick={() => {
                  if (!isPlacingOrder) {
                    this.placeOrder();
                  }
                }}
              >
                {!isPlacingOrder ? "Request Quote" : "Loading..."}
              </Button>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }

  renderMinOrderDeliveryChargeModal() {
    const {
      showMinOrderDeliveryChargeModal,
      stockUserData,
      isPlacingOrder,
    } = this.state;
    return (
      <Modal
        show={showMinOrderDeliveryChargeModal}
        onHide={() => {
          this.setState({ showMinOrderDeliveryChargeModal: false });
        }}
      >
        <Modal.Header>
          <Modal.Title>
            Place order with addtional delivery charges?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: "center", width: "90%", margin: "auto" }}
        >
          <p>
            You can still place the order by paying an additional delivery
            charge of $
            {Number(stockUserData["min_stock_delivery_charge"]).toFixed(2)}.
          </p>
          <p>Do you want to add the delivery charge and place order?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (!isPlacingOrder) {
                this.placeOrder();
              }
            }}
          >
            {!isPlacingOrder
              ? "Place Order with addtional charges"
              : "Loading..."}
          </Button>

          <Button
            onClick={() => {
              this.setState({ showMinOrderDeliveryChargeModal: false });
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockUserLoggedIn: state.appReducer.stockUserLoggedIn,
    smsSent: state.appReducer.smsSent,
    showMinOrderDeliveryChargeModal:
      state.appReducer.showMinOrderDeliveryChargeModal,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorRequestStockQuote, {
    fallback: <ErrorFallbackComponent />,
  })
);
