import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Paper, Grid, Row, Col, Typography, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AWS_URL } from "../../assets/Constants";
import MenuModal from "./MenuModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ReactStars from "react-rating-stars-component";
import * as Sentry from "@sentry/react";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    margin: "auto",
    overflow: "auto",
    height: 600,
    flexDirection: "column",
    gap: "0px 20px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: 128,
    maxHeight: 128,
  },
  textBox: {
    border: "2px solid",
    boxShadow: "5px 5px 5px 5px #888888",
    borderRadius: 20,
    marginBottom: 10,
  },
  alert: {
    marginBottom: 8,
  },
}));

const EventModal = ({
  open,
  handleClose,
  vendorDetail,
  eoiID,
  confirmVendor,
  rejectVendor,
}) => {
  console.log(vendorDetail.meals);
  const [menuStatus, setMenuStatus] = useState(false);
  const [vendorRating, setVendorRating] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [statementOfTrade, setStatementOfTrade] = useState([]);

  const handleCheckboxChange = (event, documentName) => {
    if (event.target.checked) {
      setSelectedDocuments((prev) => [...prev, documentName]);
    } else {
      setSelectedDocuments((prev) =>
        prev.filter((doc) => doc !== documentName)
      );
    }
  };

  const openConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const classes = useStyles();
  console.log(vendorDetail);
  const {
    id,
    title,
    address,
    dates,
    location,
    logo,
    logo_map,
    number_of_10_amp,
    is_prices_altered_allowed,
    number_of_15_amp,
    number_of_20_amp,
    number_of_32_amp,
    is_cooler_required,
    food_refistration_docs,
    work_cover_insurance,
    working_with_childrens_check,
    fire_safety_certificate,
    motor_vehicle_insurance,
    food_safety_certificate,
    liablity_certificate,
    meals,
    electricial_certificate,
    gas_certificate,
    site_layout,
    streat_trader_file,
    other_uploads,
    truck_width,
    truck_depth,
    truck_height,
    truck_exit,
    instagram,
  } = vendorDetail;

  const getSOTForEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_sot_for_event",
          vendor_id: vendorDetail.id,
          eoi_id: eoiID,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data[0].statement_of_trade_file);
        setStatementOfTrade(response.data[0].statement_of_trade_file);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  };

  const getVendorRatingForEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendor_rating_for_event",
          vendor_id: vendorDetail.id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.ratings_data.ratings_value__sum == null) {
          setVendorRating(9 / 2);
        } else {
          setVendorRating(response.ratings_data.ratings_value__sum / 2);
        }
        console.log(vendorRating);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  };
  const alertVendorDocuments = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.organiserData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "upload_alert_for_vendors",
          vendor_id: vendorDetail.id,
          eoi_id: eoiID,
          documents: selectedDocuments,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          alert("Alerted Successfully!");
        } else {
          alert("Please try again!");
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        alert("Please try again!");
      });
  };
  const firstExample = {
    size: 30,
    isHalf: true,
    value: vendorRating,
    edit: false,
  };

  useEffect(() => {
    // getVendorRatingForEvent();
    getSOTForEvent();
  }, [vendorDetail]);

  useEffect(() => {
    console.log(vendorDetail);
    if (vendorDetail && Array.isArray(vendorDetail.meals)) {
      const isImageMissing = vendorDetail.meals.some(
        (meal) => !meal.image || meal.image.trim() === ""
      );

      if (isImageMissing) {
        console.log(false);
      }
    }
  }, [vendorDetail]);

  useEffect(() => {
    console.log(vendorRating);
    console.log(firstExample);
  }, [vendorRating]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ position: "absolute", margin: 100, top: 300 }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={classes.paper}>
        <Grid container spacing={2} className={classes.textBox}>
          <Grid item className={classes.image}>
            <img className={classes.img} alt="vendor-logo" src={logo} />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="h5">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "10px" }}>{title}</div>
                    {`${vendorDetail.truck_height} x ${vendorDetail.truck_depth} x ${vendorDetail.truck_width}`}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ReactStars
                      {...firstExample}
                      style={{ marginRight: "10px" }}
                    />
                    {vendorRating === 0 ? (
                      <Typography variant="body1">No reviews</Typography>
                    ) : null}
                  </div>
                </Typography>
                <Grid item xs={12}>
                  {vendorDetail &&
                  vendorDetail.meals &&
                  vendorDetail.meals.every(
                    (meal) => meal.description && meal.image
                  ) ? (
                    ""
                  ) : (
                    <Alert className={classes.alert} severity="error">
                      The vendor doesn't have meal images or descriptions for
                      some menu items.
                    </Alert>
                  )}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Check the Menu Detail</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <MenuModal
                        menu={vendorDetail.meals}
                        vendorId={vendorDetail.id}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs></Grid>
                <Typography variant="body1" gutterBottom>
                  Email: {vendorDetail.email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Phone: {vendorDetail.phone}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Vendor Address: {address}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Phone: {vendorDetail.phone}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Website: {vendorDetail.website}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Facebook: {vendorDetail.facebook}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Instagram: {vendorDetail.instagram}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Dates Applied: {dates}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Fire Source : {vendorDetail.fire_source}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Service Side : {vendorDetail.service_side}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Number Of 10 amps:{number_of_10_amp}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Number Of 15 amps: {number_of_15_amp}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Number Of 20 amps: {number_of_20_amp}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Number Of 32 amps: {vendorDetail.number_of_32_amp}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Cooler Status: {is_cooler_required}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Can Price be altered: {is_prices_altered_allowed}
                </Typography>
                {/* {food_refistration_docs &&
                liablity_certificate &&
                logo &&
                streat_trader_file &&
                site_layout ? (
                  <div></div>
                ) : ( */}
                <>
                  <Typography variant="body1" gutterBottom>
                    Remaind vendors to upload certificates
                  </Typography>
                  <button
                    style={{
                      background: "#ef3e6d",
                      backgroundColor: "#ef3e6d",
                      borderRadius: "6px",
                      border: "1px solid #ef3e6d",
                      display: "inline-block",
                      cursor: "pointer",
                      color: "#ffffff",
                      fontSize: "15px",
                      fontWeight: "bold",
                      padding: "6px 24px",
                      textDecoration: "none",
                    }}
                    onClick={openConfirmDialog}
                    // onClick={() => {
                    //   alertVendorDocuments();
                    // }}
                  >
                    ALERT
                  </button>
                </>
                {/* )} */}
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {food_safety_certificate ? (
                    <a
                      href={food_safety_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`Food Safety Certificate    Expiry: ${vendorDetail.gas_certificate_expiry_date}`}
                    </a>
                  ) : (
                    <>Food Safety Certificate(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {working_with_childrens_check ? (
                    <a
                      href={working_with_childrens_check}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Working With Childrens Check
                    </a>
                  ) : (
                    <>Working With Childrens Check(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {fire_safety_certificate ? (
                    <a
                      href={fire_safety_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Fire Safety Certificate
                    </a>
                  ) : (
                    <>Fire Safety Certificate(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {work_cover_insurance ? (
                    <a
                      href={work_cover_insurance}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Work Cover Insurrance
                    </a>
                  ) : (
                    <>Work Cover Insurrance (Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {statementOfTrade ? (
                    <a
                      href={AWS_URL + "/" + statementOfTrade}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Statement of Trade
                    </a>
                  ) : (
                    <>Statement of Trade(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {vendorDetail.food_refistration_docs ? (
                    <a
                      href={vendorDetail.food_refistration_docs}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Council Registration Certificate
                    </a>
                  ) : (
                    <>Council Registration Certificate(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {site_layout ? (
                    <a
                      href={site_layout}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Site Layout
                    </a>
                  ) : (
                    <>Site Layout(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {liablity_certificate ? (
                    <a
                      href={liablity_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`Liability Certificate    Expiry: ${vendorDetail.liablity_certificate_expiry_date}`}
                    </a>
                  ) : (
                    <>Liability Certificate(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {gas_certificate ? (
                    <a
                      href={gas_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gas Certificate
                    </a>
                  ) : (
                    <>Gas Certificate(Not Uploaded)</>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textDecoration: "underline" }}
                >
                  {electricial_certificate ? (
                    <a
                      href={electricial_certificate}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Electrical Certificate
                    </a>
                  ) : (
                    <>Electrical Certificate(Not Uploaded)</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Tooltip
          title={
            vendorDetail && vendorDetail.meals
              ? vendorDetail.meals.every(
                  (meal) => meal.description && meal.image
                )
                ? ""
                : "The vendor doesn't have meal images or descriptions, so you cannot select them. Please alert them to upload!"
              : "Vendor details are not available"
          }
        >
          <Button
            onClick={() => confirmVendor(vendorDetail)}
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            disabled={
              !(
                vendorDetail &&
                vendorDetail.meals &&
                vendorDetail.meals.every(
                  (meal) => meal.description && meal.image
                )
              )
            }
          >
            Confirm the request
          </Button>
        </Tooltip>

        <Button
          onClick={() => rejectVendor(vendorDetail)}
          color="primary"
          variant="contained"
          style={{ margin: 10 }}
        >
          Reject The request
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          style={{ margin: 10 }}
        >
          Close
        </Button>
        <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
          <DialogTitle>
            {"Please select the items you want to notify?"}
          </DialogTitle>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("food_refistration_docs")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "food_refistration_docs")
                  }
                />
              }
              label="Food Registration Certificate"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("liability_certificate")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "liability_certificate")
                  }
                />
              }
              label="Liability Certificate"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("gas_certificate")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "gas_certificate")
                  }
                />
              }
              label="Gas Certificate"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("measurements")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "measurements")
                  }
                />
              }
              label="Truck Measurements"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("food_safety")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "food_safety")
                  }
                />
              }
              label="Food Safety"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "fire_safety_certificate"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "fire_safety_certificate")
                  }
                />
              }
              label="Fire Permit"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "working_with_childrens_check"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "working_with_childrens_check")
                  }
                />
              }
              label="Working With Children Check"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "meal_image_and_description"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "meal_image_and_description")
                  }
                />
              }
              label="Meal Images & Descriptions"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes(
                    "test_tag_fire_equipments"
                  )}
                  onChange={(event) =>
                    handleCheckboxChange(event, "test_tag_fire_equipments")
                  }
                />
              }
              label="Test and Tag Fire Equipments"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("rego_number")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "rego_number")
                  }
                />
              }
              label="Registration Number"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("truck_images")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "truck_images")
                  }
                />
              }
              label="Truck Images"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("staff_uniform_images")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "staff_uniform_images")
                  }
                />
              }
              label="Staff Uniform Pictures"
            />
          </div>
          <div style={{ padding: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedDocuments.includes("confirm_pricing")}
                  onChange={(event) =>
                    handleCheckboxChange(event, "confirm_pricing")
                  }
                />
              }
              label="Confirm Pricing"
            />
          </div>
          <DialogActions>
            <Button onClick={closeConfirmDialog}>Cancel</Button>
            <Button
              onClick={() => {
                alertVendorDocuments();
                closeConfirmDialog();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Modal>
  );
};

export default EventModal;
