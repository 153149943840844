import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Accordion,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Sel from "react-select";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import { TextField } from "material-ui";

const accessInfo = {
  truck_manager: "Access to pre-event checklist, post-event checklist, runsheets.",
  operations: "Access to statement of trades, pre-event checklist, post-event checklist, runsheets.",
  event_coordinator: "Access to applying EOI, menu and catering applications, and statement of trades.",
  accountant: "Access to financial records.",
  stock_admin: "Access to stock management.",
};
class OperatorSubaccount extends Component {
  state = {
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    bookingRequests: [],
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],

    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    stalls_linked: [],
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    vendor_ids: [],
    selectedVendorId: "",
    stakeholder_first_name: "",
    stakeholder_last_name: "",
    stakeholder_email: "",
    stakeholder_type: [],
    stalls_linked: [],
    stakeholder_types: [
      { id: "truck_manager", name: "Truck Manager" },
      { id: "stock_admin", name: "Stock Admin" },
      { id: "workforce_admin", name: "Workforce Admin" },
      { id: "accountant", name: "Accountant" },
      { id: "operations", name: "Operations" },
      { id: "event_coordinator", name: "Event Coordinator" },
    ],
  };

  handleVendorsChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ stalls_linked: selectedIds });
  };
  handleStakeholderChange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    this.setState({ stakeholder_type: selectedIds });
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  renderAccessInfo = () => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <h5>Access Information:</h5>
        <ul>
          {Object.entries(accessInfo).map(([key, value]) => (
            <li key={key}>
              <strong>{key.replace('_', ' ').replace(/\b\w/g, c => c.toUpperCase())}:</strong> {value}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn } = this.state;
    this.fetch_vendor_ids();
    this.getDashboardData(this.state.requestState);
  }

  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState({ isSuperAdminLoggedIn: "true" }, () => {
            saveToLocalStorage("isSuperAdminLoggedIn", "true");
            saveToLocalStorage("email", email);
            saveToLocalStorage("password", password);
            this.getDashboardData(this.state.requestState);
          });
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((err) => {
        this.setState({ isLoadingVendor: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };

  getVendors = (cuisine, booking_request_id) => {
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_cuisine_specific_vendors",
          cuisine: cuisine,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          dataResponse.vendors.sort(function(a, b) {
            var nameA = a.title,
              nameB = b.title;
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.setState({
            vendors: dataResponse.vendors,
            nonCuisineVendors: dataResponse.non_cuisine_vendors,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoadingVendor: false });
      });
  };

  saveAdminNotes = (item, notes) => {
    const { email } = this.state;
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_admin_notes",
          booking_request_id: item.id,
          admin_notes: notes,
          email: email,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          alert("Saved Successfully!");
        }
      })
      .catch((err) => {
        this.setState({ isLoadingVendor: false });
      });
  };

  sendRequestToVendor(booking_request_id, user_ptr_id) {
    this.setState({ isLoadingSendingVendorRequest: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "send_request_to_vendor",
          user_ptr_id: user_ptr_id,
          booking_request_id: booking_request_id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingSendingVendorRequest: false });
        if (dataResponse.success == true) {
          alert("Sent Successfully!");
          var arr = this.state.requestSentIds;
          arr.push(user_ptr_id);
          this.setState({ requestSentIds: arr });
        }
      })
      .catch((err) => {
        this.setState({ isLoadingSendingVendorRequest: false });
      });
  }

  getDashboardData = (requestState) => {
    const { startDate, endDate, resetFilter, pageSelected } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_pos_codes_for_stall",
          vendor_id: this.state.selectedVendorId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState({ bookingRequests: dataResponse.data });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  handleAddStakeholderFormSubmit = (e) => {
    const { selectedVendorId, selectedEventId, email } = this.state;
    e.preventDefault();

    // Validate the Australian phone number using a regular expression
    // const phoneNumberRegex = /^(?:\+61)[2-478](?:[ -]?[0-9]){8}$/;
    // const isPhoneNumberValid = phoneNumberRegex.test(this.state.truck_manager_phone);

    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "create_operator_subaccount",
          email: this.state.email,
          stakeholder_first_name: this.state.stakeholder_first_name,
          stakeholder_last_name: this.state.stakeholder_last_name,
          stakeholder_email: this.state.stakeholder_email,
          stakeholder_type: this.state.stakeholder_type,
          stalls_linked: this.state.stalls_linked,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          console.log(dataResponse);
          //this.setState({ showModal: false });
          alert("Added Organiser Subaccount");
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const { isOperatorLoggedIn, stockUserLoggedIn } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {stockUserLoggedIn == "false" || isOperatorLoggedIn == "false"
          ? this.renderHome()
          : this.renderHome()}
        <Container></Container>
      </div>
    );
  }

  fetch_vendor_ids = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ vendor_ids: result.stalls });
        } else {
          alert(result.error);
        }
      });
  };

  renderNavBar() {
    const { stockUserLoggedIn, isOperatorLoggedIn, stockUserData } = this.state;
    return (
      <VendorNavBar isOperatorLoggedIn={isOperatorLoggedIn}></VendorNavBar>
    );
  }

  renderLogin() {
    const { email, password, isEventCoordinatorLoggedIn } = this.state;
    return (
      <div>
        <Container>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(text) => {
                this.setState({ email: text.target.value });
              }}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(text) => {
                this.setState({ password: text.target.value });
              }}
            />
          </Form.Group>

          <Button
            style={{
              width: "100%",
              marginBottom: 20,
              marginTop: 10,
              backgroundColor: "#ef3f6d",
            }}
            className="button-enabled-pink-style"
            onClick={() => {
              this.login();
            }}
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Container>
      </div>
    );
  }

  renderHome() {
    const {
      bookingRequests,
      availableStatus,
      isLoadingChangeBookingStatus,
      adminNotesChanged,
      isLoading,
      requestState,
      isOperatorLoggedIn,
      startDate,
      endDate,
      completed_payments,
      pageSelected,
      vendor_ids,
      selectedVendorId,
      stallNameNotes,
    } = this.state;
    const vendorsOptions = this.state.vendor_ids.map((site) => ({
      value: site.user_ptr_id,
      label: site.title,
    }));
    const stakeholderOptions = this.state.stakeholder_types.map((site) => ({
      value: site.id,
      label: site.name,
    }));

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
            {this.renderAccessInfo()}
              <Form onSubmit={this.handleAddStakeholderFormSubmit}>
                <Form.Group controlId="formAddStakeholderName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={this.state.stakeholder_first_name}
                    onChange={(e) =>
                      this.setState({ stakeholder_first_name: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formAddStakeholderName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={this.state.stakeholder_last_name}
                    onChange={(e) =>
                      this.setState({ stakeholder_last_name: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formAddStakeholderEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    value={this.state.stakeholder_email}
                    onChange={(e) =>
                      this.setState({ stakeholder_email: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStakeholderType">
                  <Form.Label>Account Type</Form.Label>
                  <Sel
                    isMulti
                    value={stakeholderOptions.filter((option) =>
                      this.state.stakeholder_type.includes(option.value)
                    )}
                    onChange={this.handleStakeholderChange}
                    options={stakeholderOptions}
                    placeholder="Select Type"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formStallsLinked">
                  <Form.Label>Linked Stalls</Form.Label>
                  <Sel
                    isMulti
                    value={vendorsOptions.filter((option) =>
                      this.state.stalls_linked.includes(option.value)
                    )}
                    onChange={this.handleVendorsChange}
                    options={vendorsOptions}
                    placeholder="Select Vendors"
                    required
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  style={{
                    backgroundColor: "#2959a5",
                    borderColor: "#2959a5",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "20px",
                    color: "#fff",
                  }}
                >
                  Create Account
                </Button>
              </Form>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorSubaccount, {
    fallback: <ErrorFallbackComponent />,
  })
);
