import React, { useState, useEffect } from "react";
import config from "../services/apiConfig";
import { apiPaths } from "../services/apiPath";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../store";
const useFecth = (userRole = null) => {
  const [vendors, setVendors] = useState([]);
  const [events, setEvents] = useState([]);
  let newVendor = [];
  function fetchList(role) {
    if (userRole == null) {
      return;
    }
    const userList = JSON.parse(getFromLocalStorage(role));

    const vendorRequestOptions = {
      method: "GET",
      redirect: "follow",
    };

    userList.map((item) => {
      const vendorUrl =
        config.BASE_URI + apiPaths.explore.trucksList + item.stalls;

      const eoiUrl = config.BASE_URI + apiPaths.adminData;

      // fetch(eventUrl, requestOptions).then((response) =>
      //   response.json().then((responseData) => {
      //     if (newEoi.some((event) => event.id === responseData.id)) {
      //       return;
      //     } else {
      //       newEoi.push(responseData);
      //       setEvents([...newEoi]);
      //     }
      //   })
      // );

      const headers = new Headers();

      headers.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_current_eoi_vendor_applications",
          },
        },
      });

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: raw,
        redirect: "follow",
      };

      fetch(eoiUrl, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setEvents(data.eoi);
        });

      fetch(vendorUrl, vendorRequestOptions).then((response) =>
        response.json().then((responseData) => {
          if (newVendor.some((vendor) => vendor.id === responseData.id)) {
            return;
          } else {
            newVendor.push(responseData);
            setVendors([...newVendor]);
          }
        })
      );
    });
  }

  useEffect(() => {
    fetchList(userRole);
  }, [userRole]);
  return { vendors, events };
};

export default useFecth;
