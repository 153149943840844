import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import "date-fns";
import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import Footer from "../../components/UI/Footer";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import SearchBarVendor from "../../components/SearchBarVendor";
import SearchResultsListVendor from "../../components/SearchResultsListVendor";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import moment from "moment";
import config from "../../services/apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from "../../services/apiPath";
import * as Sentry from "@sentry/react";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import Card from "react-bootstrap/Card";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import CsvDownloadButton from "react-json-to-csv";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

class OperatorPayouts extends Component {
  state = {
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    isOperatorAccountant:
      getFromLocalStorage("isOperatorAccountant") != "undefined" &&
      getFromLocalStorage("isOperatorAccountant") != "" &&
      getFromLocalStorage("isOperatorAccountant") != null
        ? getFromLocalStorage("isOperatorAccountant")
        : "false",
    searchVendor: "",
    filteredVendors: [],
    adminSalesInvoiceFortnightPeriod:
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "undefined" &&
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "" &&
      getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != null
        ? getFromLocalStorage("adminSalesInvoiceFortnightPeriod")
        : 0,
    externalOperators: [],
    operatorPayout: [],
    salesInvoiceFornightPeriod: [],
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    salesReport: [],
    isLoading: false,
    grossKitchacoInStoreCashSales: 0,
    grossKitchacoInStoreCardSales: 0,
    kitchaco_commission_percentage: 0,
    grossKitchacoOnlineDeliverySales: 0,
    grossKitchacoOnlinePickupSales: 0,
    grossUberEatsSales: 0,
    grossDoordashSales: 0,
    grossDeliverooSales: 0,
    grossMenulogSales: 0,
    grossStockPurchased: 0,
    gstStockPurchased: 0,
    netStockPurchased: 0,
    adminAdjustmentStockPurchased: 0,
    deliveryChargeStockPurchased: 0,
    labelMiscCharges1: "",
    grossMiscCharges1: 0,
    netMiscCharges1: 0,
    gstMiscCharges1: 0,

    isGstMiscCharges1: false,
    labelMiscCharges2: "",
    grossMiscCharges2: 0,
    netMiscCharges2: 0,
    gstMiscCharges2: 0,

    isGstMiscCharges2: false,
    labelMiscCharges3: "",
    grossMiscCharges3: 0,
    netMiscCharges3: 0,
    gstMiscCharges3: 0,

    isGstMiscCharges3: false,
    labelMiscCharges4: "",
    grossMiscCharges4: 0,
    netMiscCharges4: 0,
    gstMiscCharges4: 0,

    isGstMiscCharges4: false,
    labelMiscCharges5: "",
    grossMiscCharges5: 0,
    isGstMiscCharges5: false,
    netMiscCharges5: 0,
    gstMiscCharges5: 0,

    in_store_cash_commission: 0,
    in_store_card_commission: 0,
    online_pickup_commission: 0,
    online_delivery_commission: 0,
    uber_eats_commission: 0,
    doordash_commission: 0,
    deliveroo_commission: 0,
    menulog_commission: 0,

    sendEmailAs: "wtt",
    approved_by: "",
    approved_on: "",

    grossKitchacoInStoreCashSalesArray: [],
    grossKitchacoInStoreCardSalesArray: [],
    grossKitchacoOnlineDeliverySalesArray: [],
    grossKitchacoOnlinePickupSalesArray: [],
    grossUberEatsSalesArray: [],
    grossDoordashSalesArray: [],
    grossDeliverooSalesArray: [],
    grossMenulogSalesArray: [],
    salesApprovedInvoiceDetails: [],
    errorMessage: "Please select a fortnight period above to show reports",
    lessCashSalesSelectedCheckbox: true,
    lessCardSalesSelectedCheckbox: false,
    operatorPayouts: [],
  };

  componentDidMount() {
    this.getVendorsInEvent();
    const {
      startDate,
      endDate,
      salesInvoiceFornightPeriod,
      adminSalesInvoiceFortnightPeriod,
      isOperatorLoggedIn,
      isOperatorAccountant,
    } = this.state;
    console.log(isOperatorLoggedIn, isOperatorAccountant);
    debugger;
    if (isOperatorLoggedIn === "true" || isOperatorAccountant === "true") {
    } else {
      window.location.href = "/operator-login";
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.FilteredVendors !== this.state.FilteredVendors) {
      console.log("FilteredVendors has changed:", this.state.FilteredVendors);
    }
  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", date);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", date);
    });
  };

  onChangeExternalOperator(e) {
    this.setState({ adminExternalOperator: e.target.value }, () => {
      saveToLocalStorage(
        "adminExternalOperator",
        this.state.adminExternalOperator
      );
      this.getSalesReport();
    });
  }

  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              isOperatorLoggedIn: "" + dataResponse.data[0].is_operator,
            },
            () => {
              saveToLocalStorage(
                "isOperatorLoggedIn",
                "" + dataResponse.data[0].is_operator
              );

              saveToLocalStorage("email", email);
              saveToLocalStorage("password", password);
              if (dataResponse.data[0].is_operator != true) {
                alert("Contact Tech Support for access");
              }
            }
          );
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        Sentry.captureException(error);
        console.error(error);
      });
  };

  onChangeDatePeriod(e) {
    console.log(e.target.value);
    this.setState({ adminSalesInvoiceFortnightPeriod: e.target.value }, () => {
      const {
        salesInvoiceFornightPeriod,
        adminSalesInvoiceFortnightPeriod,
      } = this.state;
      try {
        var startDate = null;
        var endDate = null;
        // Iterate through the salesInvoiceFornightPeriod array
        for (var i = 0; i < salesInvoiceFornightPeriod.length; i++) {
          // Check if the current object's id matches adminSalesInvoiceFortnightPeriodId
          if (
            salesInvoiceFornightPeriod[i].id == adminSalesInvoiceFortnightPeriod
          ) {
            // If there's a match, store the start and end dates
            startDate = salesInvoiceFornightPeriod[i].start_date;
            endDate = salesInvoiceFornightPeriod[i].end_date;
            // Break out of the loop since we found a match
            break;
          }
        }
        this.setState({ startDate: startDate }, () => {
          saveToLocalStorage("startDate", this.state.startDate);
        });
        //var endDate = moment(date).add(6, "days");
        this.setState({ endDate: endDate }, () => {
          saveToLocalStorage("endDate", this.state.endDate);
          saveToLocalStorage(
            "adminSalesInvoiceFortnightPeriod",
            adminSalesInvoiceFortnightPeriod
          );
        });
        this.getSalesReport();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
          saveToLocalStorage(
            "adminSalesInvoiceFortnightPeriod",
            adminSalesInvoiceFortnightPeriod
          );
        });
      }
    });
  }

  getSalesReport() {
    const {
      startDate,
      endDate,
      selectedVendorId,
      adminSalesInvoiceFortnightPeriod,
    } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_payouts_for_operators",
          vendor_id: parseInt(selectedVendorId),
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
          start_date: moment(startDate).format("DD MMM YYYY"),
          end_date: moment(endDate).format("DD MMM YYYY"),
          sales_invoice_weekly_period: parseInt(
            adminSalesInvoiceFortnightPeriod
          ),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success) {
          if (dataResponse.data.length > 0) {
            this.setState({ operatorPayout: dataResponse.data[0] });
            this.setState({ operatorPayouts: dataResponse.data });
          }
          this.setState(
            {
              salesReport: dataResponse.sales_data,
              grossStockPurchased: 0,
              netStockPurchased: 0,
              gstStockPurchased: 0,
              grossKitchacoOnlinePickupSales:
                dataResponse.gross_kitchaco_online_pickup_sales,
              adminAdjustmentStockPurchased: 0,
              deliveryChargeStockPurchased: 0,
              in_store_cash_commission:
                dataResponse.commission_rate_data.in_store_cash_commission,
              in_store_card_commission:
                dataResponse.commission_rate_data.in_store_card_commission,
              online_pickup_commission:
                dataResponse.commission_rate_data.online_pickup_commission,
              online_delivery_commission:
                dataResponse.commission_rate_data.online_delivery_commission,
              uber_eats_commission: 0,
              doordash_commission: 0,
              deliveroo_commission: 0,
              menulog_commission: 0,
            },
            () => {
              const { salesReport } = this.state;

              var grossUberEatsSales = 0;
              var grossDoordashSales = 0;
              var grossDeliverooSales = 0;
              var grossMenulogSales = 0;
              for (var i = 0; i < salesReport.length; i++) {
                const {
                  grossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlinePickupSalesArray,
                  grossKitchacoOnlineDeliverySalesArray,
                  grossUberEatsSalesArray,
                  grossDoordashSalesArray,
                  grossDeliverooSalesArray,
                  grossMenulogSalesArray,
                } = this.state;
                var deliveroo_adjustment = 0;
                if (!isNaN(salesReport[i].deliveroo_adjustments)) {
                  deliveroo_adjustment = salesReport[i].deliveroo_adjustments;
                }
                grossUberEatsSales = 0;
                grossDoordashSales = 0;
                grossDeliverooSales = 0;
                grossMenulogSales = 0;
                var tempGrossKitchacoInStoreCashSalesArray = grossKitchacoInStoreCashSalesArray;
                var tempGrossKitchacoInStoreCardSalesArray = grossKitchacoInStoreCardSalesArray;
                var tempGrossOnlinePickupSalesArray = grossKitchacoOnlinePickupSalesArray;
                var tempGrossOnlineDeliverySalesArray = grossKitchacoOnlineDeliverySalesArray;
                var tempGrossUberEatsSalesArray = grossUberEatsSalesArray;
                var tempGrossDoordashSalesArray = grossDoordashSalesArray;
                var tempGrossDeliverooSalesArray = grossDeliverooSalesArray;
                var tempGrossMenulogSalesArray = grossMenulogSalesArray;

                tempGrossKitchacoInStoreCashSalesArray[i] =
                  salesReport[i].in_store_cash_sales;
                tempGrossKitchacoInStoreCardSalesArray[i] =
                  salesReport[i].in_store_card_sales;
                tempGrossOnlinePickupSalesArray[i] =
                  salesReport[i].online_pickup_sales;
                tempGrossOnlineDeliverySalesArray[i] =
                  salesReport[i].online_delivery_sales;
                tempGrossUberEatsSalesArray[i] =
                  salesReport[i].uber_total_sales;
                tempGrossDoordashSalesArray[i] =
                  salesReport[i].doordash_total_sales;
                tempGrossDeliverooSalesArray[i] =
                  Number(salesReport[i].deliveroo_total_sales) +
                  Number(salesReport[i].deliveroo_adjustments);
                tempGrossMenulogSalesArray[i] =
                  salesReport[i].menulog_total_sales;
              }
              this.setState({
                grossUberEatsSales: grossUberEatsSales,
                grossDoordashSales: grossDoordashSales,
                grossDeliverooSales: grossDeliverooSales,
                grossMenulogSales: grossMenulogSales,
                grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
                grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
                grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
                grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
                grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
                grossDoordashSalesArray: tempGrossDoordashSalesArray,
                grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
                grossMenulogSalesArray: tempGrossMenulogSalesArray,
              });

              if (
                dataResponse.sales_data[0].status != "unavailable" &&
                dataResponse.sales_data[0].status != "generated"
              ) {
                this.setState(
                  {
                    grossKitchacoInStoreCashSales: Number(
                      dataResponse.sales_data[0].in_store_cash_sales
                    ).toFixed(2),
                    grossKitchacoInStoreCardSales: Number(
                      dataResponse.sales_data[0].in_store_card_sales
                    ).toFixed(2),
                    grossKitchacoOnlinePickupSales: Number(
                      dataResponse.sales_data[0].total_voucher_sale
                    ).toFixed(2),
                    grossKitchacoOnlineDeliverySales: 0,
                    // totalRevenueForLocation: Number(dataResponse.sales_data[0].total_revenue).toFixed(2),

                    grossKitchacoInStoreCashFee: Number(
                      dataResponse.sales_data[0].wtt_cash_commission
                    ).toFixed(2),
                    grossKitchacoInStoreCardFee: Number(
                      dataResponse.sales_data[0].wtt_card_commission
                    ).toFixed(2),
                    grossKitchacoOnlinePickupFee: Number(
                      dataResponse.sales_data[0].wtt_voucher_commission
                    ).toFixed(2),
                    approved_by: dataResponse.approved_by,
                    approved_on: dataResponse.approved_on,
                    labelMiscCharges1:
                      dataResponse.sales_data[0].label_misc_charges1,
                    grossMiscCharges1:
                      dataResponse.sales_data[0].gross_misc_charges1,
                    gstMiscCharges1:
                      dataResponse.sales_data[0].gst_misc_charges1,
                    netMiscCharges1:
                      dataResponse.sales_data[0].net_misc_charges1,

                    labelMiscCharges2:
                      dataResponse.sales_data[0].label_misc_charges2,
                    grossMiscCharges2:
                      dataResponse.sales_data[0].gross_misc_charges2,
                    gstMiscCharges2:
                      dataResponse.sales_data[0].gst_misc_charges2,
                    netMiscCharges2:
                      dataResponse.sales_data[0].net_misc_charges2,

                    labelMiscCharges3:
                      dataResponse.sales_data[0].label_misc_charges3,
                    grossMiscCharges3:
                      dataResponse.sales_data[0].gross_misc_charges3,
                    gstMiscCharges3:
                      dataResponse.sales_data[0].gst_misc_charges3,
                    netMiscCharges3:
                      dataResponse.sales_data[0].net_misc_charges3,

                    labelMiscCharges4:
                      dataResponse.sales_data[0].label_misc_charges4,
                    grossMiscCharges4:
                      dataResponse.sales_data[0].gross_misc_charges4,
                    gstMiscCharges4:
                      dataResponse.sales_data[0].gst_misc_charges4,
                    netMiscCharges4:
                      dataResponse.sales_data[0].net_misc_charges4,

                    labelMiscCharges5:
                      dataResponse.sales_data[0].label_misc_charges5,
                    grossMiscCharges5:
                      dataResponse.sales_data[0].gross_misc_charges5,
                    gstMiscCharges5:
                      dataResponse.sales_data[0].gst_misc_charges5,
                    netMiscCharges5:
                      dataResponse.sales_data[0].net_misc_charges5,
                    lessCardSalesSelectedCheckbox:
                      dataResponse.sales_data[0].less_in_store_card_sales,
                    lessCashSalesSelectedCheckbox:
                      dataResponse.sales_data[0].less_in_store_cash_sales,
                  },
                  () => {
                    const {
                      gstMiscCharges1,
                      gstMiscCharges2,
                      gstMiscCharges3,
                      gstMiscCharges4,
                      gstMiscCharges5,
                    } = this.state;
                    if (Number(gstMiscCharges1) > 0) {
                      this.setState({ isGstMiscCharges1: true });
                    }
                    if (Number(gstMiscCharges2) > 0) {
                      this.setState({ isGstMiscCharges2: true });
                    }
                    if (Number(gstMiscCharges3) > 0) {
                      this.setState({ isGstMiscCharges3: true });
                    }
                    if (Number(gstMiscCharges4) > 0) {
                      this.setState({ isGstMiscCharges4: true });
                    }
                    if (Number(gstMiscCharges5) > 0) {
                      this.setState({ isGstMiscCharges5: true });
                    }
                  }
                );
                const {
                  grossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlinePickupSalesArray,
                  grossKitchacoOnlineDeliverySalesArray,
                  grossUberEatsSalesArray,
                  grossDoordashSalesArray,
                  grossDeliverooSalesArray,
                  grossMenulogSalesArray,
                } = this.state;
                // for (var i = 0; i < dataResponse.sales_approved_invoice_details.length; i++) {
                //   var sales = dataResponse.sales_approved_invoice_details[i]

                //   var tempGrossKitchacoInStoreCashSalesArray = grossKitchacoInStoreCashSalesArray
                //   var tempGrossKitchacoInStoreCardSalesArray = grossKitchacoInStoreCardSalesArray
                //   var tempGrossOnlinePickupSalesArray = grossKitchacoOnlinePickupSalesArray
                //   var tempGrossOnlineDeliverySalesArray = grossKitchacoOnlineDeliverySalesArray
                //   var tempGrossUberEatsSalesArray = grossUberEatsSalesArray
                //   var tempGrossDoordashSalesArray = grossDoordashSalesArray
                //   var tempGrossDeliverooSalesArray = grossDeliverooSalesArray
                //   var tempGrossMenulogSalesArray = grossMenulogSalesArray

                //   tempGrossKitchacoInStoreCashSalesArray[i] = sales.kitchaco_in_store_cash_sales
                //   tempGrossKitchacoInStoreCardSalesArray[i] = sales.kitchaco_in_store_card_sales
                //   tempGrossOnlinePickupSalesArray[i] = sales.kitchaco_online_pickup_sales
                //   tempGrossOnlineDeliverySalesArray[i] = sales.kitchaco_online_delivery_sales
                //   tempGrossUberEatsSalesArray[i] = sales.uber_eats_sales
                //   tempGrossDoordashSalesArray[i] = sales.doordash_sales
                //   tempGrossDeliverooSalesArray[i] = sales.deliveroo_sales
                //   tempGrossMenulogSalesArray[i] = sales.menulog_sales

                // }
                this.setState({
                  grossUberEatsSales: grossUberEatsSales,
                  grossDoordashSales: grossDoordashSales,
                  grossDeliverooSales: grossDeliverooSales,
                  grossMenulogSales: grossMenulogSales,
                  grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
                  grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
                  grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
                  grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
                  grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
                  grossDoordashSalesArray: tempGrossDoordashSalesArray,
                  grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
                  grossMenulogSalesArray: tempGrossMenulogSalesArray,
                  salesApprovedInvoiceDetails:
                    dataResponse.sales_approved_invoice_details,
                });
              } else {
                this.setState({
                  grossKitchacoInStoreCashSales:
                    dataResponse.gross_kitchaco_in_store_cash_sales,
                  grossKitchacoInStoreCardSales:
                    dataResponse.gross_kitchaco_in_store_card_sales,
                  grossKitchacoOnlinePickupSales:
                    dataResponse.gross_kitchaco_online_pickup_sales,
                  grossKitchacoOnlineDeliverySales:
                    dataResponse.gross_kitchaco_online_delivery_sales,
                  approved_by: "",
                  approved_on: "",
                  labelMiscCharges1: "",
                  grossMiscCharges1: 0,
                  netMiscCharges1: 0,
                  gstMiscCharges1: 0,

                  isGstMiscCharges1: false,
                  labelMiscCharges2: "",
                  grossMiscCharges2: 0,
                  netMiscCharges2: 0,
                  gstMiscCharges2: 0,

                  isGstMiscCharges2: false,
                  labelMiscCharges3: "",
                  grossMiscCharges3: 0,
                  netMiscCharges3: 0,
                  gstMiscCharges3: 0,

                  isGstMiscCharges3: false,
                  labelMiscCharges4: "",
                  grossMiscCharges4: 0,
                  netMiscCharges4: 0,
                  gstMiscCharges4: 0,

                  isGstMiscCharges4: false,
                  labelMiscCharges5: "",
                  grossMiscCharges5: 0,
                  isGstMiscCharges5: false,
                  netMiscCharges5: 0,
                  gstMiscCharges5: 0,
                  lessCashSalesSelectedCheckbox: true,
                  lessCardSalesSelectedCheckbox: false,
                });
              }
            }
          );
        } else {
          this.setState({
            adminSalesInvoiceFortnightPeriod: 0,
            errorMessage:
              "Report for this location and period hasn't been generated yet. Please select a different location or period or come back later for this report.",
          });
          // window.location.reload()
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.stalls }, () => {
          let vendorId = "0";
          this.setState({ selectedVendorId: vendorId }, () => {
            saveToLocalStorage("selectedVendorId", vendorId);
            this.getSalesInvoiceFornightPeriod();
          });
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getExternalOperatorData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      query_type: "external_operators",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ externalOperators: dataResponse.vendors }, () => {
            this.getSalesInvoiceFornightPeriod();
          });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  }

  getSalesInvoiceFornightPeriod() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "sales_invoice_weekly_period",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState(
            {
              salesInvoiceFornightPeriod:
                dataResponse.sales_invoice_fortnight_period,
            },
            () => {
              const {
                salesInvoiceFornightPeriod,
                adminSalesInvoiceFortnightPeriod,
                startDate,
                endDate,
              } = this.state;
              try {
                var start_date = moment(startDate).format("DD MMM YYYY");
                var end_date = moment(endDate).format("DD MMM YYYY");
                if (
                  salesInvoiceFornightPeriod[
                    adminSalesInvoiceFortnightPeriod - 1
                  ].start_date != start_date ||
                  salesInvoiceFornightPeriod[
                    adminSalesInvoiceFortnightPeriod - 1
                  ].end_date != end_date
                ) {
                  this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
                    saveToLocalStorage(
                      "adminSalesInvoiceFortnightPeriod",
                      adminSalesInvoiceFortnightPeriod
                    );
                  });
                }
              } catch (e) {
                Sentry.captureException(e);
                console.error(e);
                this.setState({ adminSalesInvoiceFortnightPeriod: 0 }, () => {
                  saveToLocalStorage(
                    "adminSalesInvoiceFortnightPeriod",
                    adminSalesInvoiceFortnightPeriod
                  );
                });
              }
              this.getSalesReport();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  }

  render() {
    const { vendorName, isOperatorLoggedIn, isOperatorAccountant } = this.state;

    return (
      <>
        {isOperatorLoggedIn === "true" || isOperatorAccountant === "true"
          ? // Render your component when either isSuperAdminLoggedIn or isAccountantLoggedIn is "true"
            this.renderMainComponent()
          : // Render the login component when neither isSuperAdminLoggedIn nor isAccountantLoggedIn is "true"
            (window.location.href = "/operator-login")}
      </>
    );
  }

  saveSalesInvoice = (
    grossTotalRevenue,
    gst,
    netUberEatsFee,
    netDoordashFee,
    netDeliverooFee,
    netMenulogFee,
    grossKitchacoInStoreCashFee,
    grossKitchacoInStoreCardFee,
    grossKitchacoOnlinePickupFee,
    netKitchacoInStoreCardFee,
    netKitchacoOnlinePickupFee,
    netKitchacoOnlineDeliveryFee,
    payoutResult,
    grossTotalSalesLessExpenditure
  ) => {
    const {
      startDate,
      endDate,
      selectedVendorId,
      adminSalesInvoiceFortnightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      netStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,

      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      salesReport,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
    } = this.state;

    var kitchacoTotalSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    var kitchacoOnlineSales =
      Number(grossKitchacoOnlinePickupSales) +
      Number(grossKitchacoOnlineDeliverySales);
    var kitchacoInStoreSales =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales);
    let currentDate = moment().format("DD MMM YYYY");
    var url = config.BASE_URI + apiPaths.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      query_type: "update_and_approve_sales_report",
      vendor: parseInt(selectedVendorId),
      approved_by: "frank@amfvg.com.au",
      start_date: moment(startDate).format("DD MMM YYYY"),
      end_date: moment(endDate).format("DD MMM YYYY"),
      sales_invoice_weekly_period: parseInt(adminSalesInvoiceFortnightPeriod),
      kitchaco_total_sales: Number(kitchacoTotalSales).toFixed(2),
      kitchaco_online_sales: kitchacoOnlineSales,
      paid_on: currentDate,
      kitchaco_instore_sales: kitchacoInStoreSales,
      uber_total_sales: grossUberEatsSales,
      deliveroo_total_sales: grossDeliverooSales,
      doordash_total_sales: grossDoordashSales,
      menulog_total_sales: grossMenulogSales,
      total_revenue: Number(grossTotalRevenue).toFixed(2),
      total_gst: gst,
      stock_ex_gst: netStockPurchased,
      uber_commission_ex_gst: netUberEatsFee,
      doordash_commission_ex_gst: netDoordashFee,
      deliveroo_commission_ex_gst: netDeliverooFee,
      menulog_commission_ex_gst: netMenulogFee,
      in_store_cash_commission: grossKitchacoInStoreCashFee,
      in_store_card_commission_ex_gst: netKitchacoInStoreCardFee,
      online_pickup_commission_ex_gst: netKitchacoOnlinePickupFee,
      online_delivery_commission_ex_gst: netKitchacoOnlineDeliveryFee,
      kitchaco_in_store_cash_sales: grossKitchacoInStoreCashSales,
      kitchaco_in_store_card_sales: grossKitchacoInStoreCardSales,
      kitchaco_online_pickup_sales: grossKitchacoOnlinePickupSales,
      kitchaco_online_delivery_sales: grossKitchacoOnlineDeliverySales,
      label_misc_charges1: labelMiscCharges1,
      gross_misc_charges1: grossMiscCharges1,
      net_misc_charges1: netMiscCharges1,
      gst_misc_charges1: gstMiscCharges1,
      label_misc_charges2: labelMiscCharges2,
      gross_misc_charges2: grossMiscCharges2,
      net_misc_charges2: netMiscCharges2,
      gst_misc_charges2: gstMiscCharges2,
      label_misc_charges3: labelMiscCharges3,
      gross_misc_charges3: grossMiscCharges3,
      net_misc_charges3: netMiscCharges3,
      gst_misc_charges3: gstMiscCharges3,
      label_misc_charges4: labelMiscCharges4,
      gross_misc_charges4: grossMiscCharges4,
      net_misc_charges4: netMiscCharges4,
      gst_misc_charges4: gstMiscCharges4,
      label_misc_charges5: labelMiscCharges5,
      gross_misc_charges5: grossMiscCharges5,
      net_misc_charges5: netMiscCharges5,
      gst_misc_charges5: gstMiscCharges5,
      total_payout_inc_gst: payoutResult,
      uber_total_sales_array: grossUberEatsSalesArray,
      deliveroo_total_sales_array: grossDeliverooSalesArray,
      doordash_total_sales_array: grossDoordashSalesArray,
      menulog_total_sales_array: grossMenulogSalesArray,
      kitchaco_in_store_cash_sales_array: grossKitchacoInStoreCashSalesArray,
      kitchaco_in_store_card_sales_array: grossKitchacoInStoreCardSalesArray,
      kitchaco_online_pickup_sales_array: grossKitchacoOnlinePickupSalesArray,
      kitchaco_online_delivery_sales_array: grossKitchacoOnlineDeliverySalesArray,
      sales_report: salesReport,
      sales_approved_invoice_details: this.state.salesApprovedInvoiceDetails,
      total_earnings: grossTotalSalesLessExpenditure,
      less_in_store_cash_sales: lessCashSalesSelectedCheckbox,
      less_in_store_card_sales: lessCardSalesSelectedCheckbox,
      wtt_cash_commission: grossKitchacoInStoreCashFee,
      wtt_card_commission: grossKitchacoInStoreCardFee,
      wtt_voucher_commission: grossKitchacoOnlinePickupFee,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Approved Successfully");
          // window.location.reload()
        }
      });
  };

  handleKitchacoOnlinePickupSalesArray = (e, index) => {
    const { grossKitchacoOnlinePickupSalesArray } = this.state;
    let tempArray = grossKitchacoOnlinePickupSalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossKitchacoOnlinePickupSalesArray: tempArray }, () => {
      var temp = 0;
      const { grossKitchacoOnlinePickupSalesArray } = this.state;
      for (var i = 0; i < grossKitchacoOnlinePickupSalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoOnlinePickupSalesArray[i]);
      }
      this.setState({ grossKitchacoOnlinePickupSales: temp });
    });
  };

  handleKitchacoOnlineDeliverySalesArray = (e, index) => {
    const { grossKitchacoOnlineDeliverySalesArray } = this.state;
    let tempArray = grossKitchacoOnlineDeliverySalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossKitchacoOnlineDeliverySalesArray: tempArray }, () => {
      var temp = 0;
      const { grossKitchacoOnlineDeliverySalesArray } = this.state;
      for (var i = 0; i < grossKitchacoOnlineDeliverySalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoOnlineDeliverySalesArray[i]);
      }
      this.setState({ grossKitchacoOnlineDeliverySales: temp });
    });
  };

  handleUberEatsSalesArray = (e, index) => {
    const { grossUberEatsSalesArray } = this.state;
    let tempArray = grossUberEatsSalesArray;
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e;
      }
    }
    this.setState({ grossUberEatsSalesArray: tempArray }, () => {
      var temp = 0;
      const { grossUberEatsSalesArray } = this.state;
      for (var i = 0; i < grossUberEatsSalesArray.length; i++) {
        temp = Number(temp) + Number(grossUberEatsSalesArray[i]);
      }
      this.setState({ grossUberEatsSales: temp });
    });
  };

  renderMainComponent() {
    const {
      operatorPayout,
      isOperatorLoggedIn,
      vendors,
      selectedVendorId,
      salesReport,
      externalOperators,
      adminExternalOperator,
      adminSalesInvoiceFortnightPeriod,
      isLoading,
      startDate,
      endDate,
      salesInvoiceFornightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      grossStockPurchased,
      gstStockPurchased,
      netStockPurchased,
      adminAdjustmentStockPurchased,
      deliveryChargeStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,
      kitchaco_commission_percentage,
      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      in_store_cash_commission,
      in_store_card_commission,
      online_pickup_commission,
      online_delivery_commission,
      uber_eats_commission,
      doordash_commission,
      deliveroo_commission,
      menulog_commission,
      approved_by,
      approved_on,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      errorMessage,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
      selectedVendorSearch,
      filteredVendors,
    } = this.state;
    console.log(operatorPayout);
    const setFilteredVendors = (data) => {
      this.setState({ filteredVendors: data });
    };
    const selectedVendorP = (data) => {
      console.log(data);
      this.setState({ selectedVendorSearch: data });
    };
    const selectedVendorQ = (data) => {
      console.log(data);
      saveToLocalStorage("selectedVendorId", "" + data);
      this.setState({ selectedVendorId: "" + data }, () => {});
    };

    var grossKitchacoInStoreCashFee = 0;
    var grossKitchacoInStoreCardFee = 0;
    var grossKitchacoOnlineDeliveryFee = 0;
    var grossKitchacoOnlinePickupFee = 0;
    var grossUberEatsFee = 0;
    var grossDoordashFee = 0;
    var grossDeliverooFee = 0;
    var grossMenulogFee = 0;

    var gstKitchacoInStoreCashSales = 0;
    var gstKitchacoInStoreCardSales = 0;
    var gstKitchacoOnlineDeliverySales = 0;
    var gstKitchacoOnlinePickupSales = 0;
    var gstUberEatsSales = 0;
    var gstDoordashSales = 0;
    var gstDeliverooSales = 0;
    var gstMenulogSales = 0;

    var gstKitchacoInStoreCashFee = 0;
    var gstKitchacoInStoreCardFee = 0;
    var gstKitchacoOnlineDeliveryFee = 0;
    var gstKitchacoOnlinePickupFee = 0;
    var gstUberEatsFee = 0;
    var gstDoordashFee = 0;
    var gstDeliverooFee = 0;
    var gstMenulogFee = 0;

    var netKitchacoInStoreCashSales = 0;
    var netKitchacoInStoreCardSales = 0;
    var netKitchacoOnlineDeliverySales = 0;
    var netKitchacoOnlinePickupSales = 0;
    var netUberEatsSales = 0;
    var netDoordashSales = 0;
    var netDeliverooSales = 0;
    var netMenulogSales = 0;
    var netKitchacoInStoreCashFee = 0;
    var netKitchacoInStoreCardFee = 0;
    var netKitchacoOnlineDeliveryFee = 0;
    var netKitchacoOnlinePickupFee = 0;
    var netUberEatsFee = 0;
    var netDoordashFee = 0;
    var netDeliverooFee = 0;
    var netMenulogFee = 0;

    var grossTotalRevenue = 0;
    var netTotalRevenue = 0;
    var gstTotalRevenue = 0;

    var grossTotalRevenueWithoutCashSales = 0;
    var netTotalRevenueWithoutCashSales = 0;
    var gstTotalRevenueWithoutCashSales = 0;

    var grossTotalPurchases = 0;
    var netTotalPurchases = 0;
    var gstTotalPurchases = 0;

    var grossTotalSalesLessExpenditure = 0;
    var gstTotalSalesLessExpenditure = 0;
    var netTotalSalesLessExpenditure = 0;

    var payoutResult = 0;
    var gst = 0;
    var netPayment = 0;
    var operatorIsPaid = 0;
    var gstToBePaidToATO = 0;
    var operatorReceivesFromFrank = 0;
    var operatorPaysATO = 0;

    if (lessCardSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCardSales = Number(
        Number(grossKitchacoInStoreCardSales) / 11
      ).toFixed(2);
      netKitchacoInStoreCardSales = Number(
        Number(grossKitchacoInStoreCardSales) / 1.1
      ).toFixed(2);
    } else {
      gstKitchacoInStoreCardSales = 0;
      netKitchacoInStoreCardSales = 0;
    }

    if (lessCashSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCashSales = Number(
        Number(grossKitchacoInStoreCashSales) / 11
      ).toFixed(2);
      netKitchacoInStoreCashSales = Number(
        Number(grossKitchacoInStoreCashSales) / 1.1
      ).toFixed(2);
    } else {
      gstKitchacoInStoreCashSales = 0;
      netKitchacoInStoreCashSales = 0;
    }

    gstKitchacoOnlineDeliverySales = Number(
      Number(grossKitchacoOnlineDeliverySales) / 11
    ).toFixed(2);
    netKitchacoOnlineDeliverySales = Number(
      Number(grossKitchacoOnlineDeliverySales) / 1.1
    ).toFixed(2);

    gstKitchacoOnlinePickupSales = Number(
      Number(grossKitchacoOnlinePickupSales) / 11
    ).toFixed(2);
    netKitchacoOnlinePickupSales = Number(
      Number(grossKitchacoOnlinePickupSales) / 1.1
    ).toFixed(2);

    gstUberEatsSales = Number(Number(grossUberEatsSales) / 11).toFixed(2);
    netUberEatsSales = Number(Number(grossUberEatsSales) / 1.1).toFixed(2);

    gstDoordashSales = Number(Number(grossDoordashSales) / 11).toFixed(2);
    netDoordashSales = Number(Number(grossDoordashSales) / 1.1).toFixed(2);

    gstDeliverooSales = Number(Number(grossDeliverooSales) / 11).toFixed(2);
    netDeliverooSales = Number(Number(grossDeliverooSales) / 1.1).toFixed(2);

    gstMenulogSales = Number(Number(grossMenulogSales) / 11).toFixed(2);
    netMenulogSales = Number(Number(grossMenulogSales) / 1.1).toFixed(2);

    grossTotalRevenue =
      Number(grossKitchacoInStoreCashSales) +
      Number(grossKitchacoInStoreCardSales) +
      Number(grossKitchacoOnlineDeliverySales) +
      Number(grossKitchacoOnlinePickupSales);
    netTotalRevenue =
      Number(netKitchacoInStoreCashSales) +
      Number(netKitchacoInStoreCardSales) +
      Number(netKitchacoOnlineDeliverySales) +
      Number(netKitchacoOnlinePickupSales);
    gstTotalRevenue =
      Number(gstKitchacoInStoreCashSales) +
      Number(gstKitchacoInStoreCardSales) +
      Number(gstKitchacoOnlinePickupSales);

    grossTotalRevenueWithoutCashSales = grossTotalRevenue;
    netTotalRevenueWithoutCashSales = netTotalRevenue;
    gstTotalRevenueWithoutCashSales = gstTotalRevenue;

    if (lessCashSalesSelectedCheckbox == true) {
      grossTotalRevenueWithoutCashSales =
        grossTotalRevenueWithoutCashSales - grossKitchacoInStoreCashSales;
      netTotalRevenueWithoutCashSales =
        netTotalRevenueWithoutCashSales - netKitchacoInStoreCashSales;
      gstTotalRevenueWithoutCashSales =
        gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCashSales;
    }
    if (lessCardSalesSelectedCheckbox == true) {
      grossTotalRevenueWithoutCashSales =
        grossTotalRevenueWithoutCashSales -
        grossKitchacoInStoreCardSales -
        grossKitchacoOnlinePickupFee;
      netTotalRevenueWithoutCashSales =
        netTotalRevenueWithoutCashSales - netKitchacoInStoreCardSales;
      gstTotalRevenueWithoutCashSales =
        gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCardSales;
    }

    grossKitchacoInStoreCashFee =
      Number(
        Number(kitchaco_commission_percentage) *
          Number(grossKitchacoInStoreCashSales)
      ).toFixed(2) / 100;
    gstKitchacoInStoreCashFee = Number(
      Number(kitchaco_commission_percentage) / 11
    ).toFixed(2);
    netKitchacoInStoreCashFee = Number(
      Number(grossKitchacoInStoreCashFee) / 1.1
    ).toFixed(2);

    grossKitchacoInStoreCardFee =
      Number(
        Number(kitchaco_commission_percentage) *
          Number(grossKitchacoInStoreCardSales)
      ).toFixed(2) / 100;
    gstKitchacoInStoreCardFee = Number(
      Number(grossKitchacoInStoreCardFee) / 11
    ).toFixed(2);
    netKitchacoInStoreCardFee = Number(
      Number(grossKitchacoInStoreCardFee) / 1.1
    ).toFixed(2);

    grossKitchacoOnlineDeliveryFee = Number(
      Number(online_delivery_commission) *
        Number(grossKitchacoOnlineDeliverySales)
    ).toFixed(2);
    gstKitchacoOnlineDeliveryFee = Number(
      Number(grossKitchacoOnlineDeliveryFee) / 11
    ).toFixed(2);
    netKitchacoOnlineDeliveryFee = Number(
      Number(grossKitchacoOnlineDeliveryFee) / 1.1
    ).toFixed(2);

    grossKitchacoOnlinePickupFee = Number(
      Number(online_pickup_commission) * Number(grossKitchacoOnlinePickupSales)
    ).toFixed(2);
    gstKitchacoOnlinePickupFee = Number(
      Number(grossKitchacoOnlinePickupFee) / 11
    ).toFixed(2);
    netKitchacoOnlinePickupFee = Number(
      Number(grossKitchacoOnlinePickupFee) / 1.1
    ).toFixed(2);

    grossUberEatsFee = Number(
      Number(uber_eats_commission) * Number(grossUberEatsSales)
    ).toFixed(2);
    gstUberEatsFee = Number(Number(grossUberEatsFee) / 11).toFixed(2);
    netUberEatsFee = Number(Number(grossUberEatsFee) / 1.1).toFixed(2);

    grossDoordashFee = Number(
      Number(doordash_commission) * Number(grossDoordashSales)
    ).toFixed(2);
    gstDoordashFee = Number(Number(grossDoordashFee) / 11).toFixed(2);
    netDoordashFee = Number(Number(grossDoordashFee) / 1.1).toFixed(2);

    grossDeliverooFee = Number(
      Number(deliveroo_commission) * Number(grossDeliverooSales)
    ).toFixed(2);
    gstDeliverooFee = Number(Number(grossDeliverooFee) / 11).toFixed(2);
    netDeliverooFee = Number(Number(grossDeliverooFee) / 1.1).toFixed(2);

    grossMenulogFee = Number(
      Number(menulog_commission) * Number(grossMenulogSales)
    ).toFixed(2);
    gstMenulogFee = Number(Number(grossMenulogFee) / 11).toFixed(2);
    netMenulogFee = Number(Number(grossMenulogFee) / 1.1).toFixed(2);

    grossTotalPurchases = Number(
      Number(grossKitchacoInStoreCashFee) +
        Number(grossKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(grossMiscCharges1) +
        Number(grossMiscCharges2) +
        Number(grossMiscCharges3) +
        Number(grossMiscCharges4) +
        Number(grossMiscCharges5)
    ).toFixed(2);

    netTotalPurchases = Number(
      Number(netKitchacoInStoreCashFee) +
        Number(netKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(netMiscCharges1) +
        Number(netMiscCharges2) +
        Number(netMiscCharges3) +
        Number(netMiscCharges4) +
        Number(netMiscCharges5)
    ).toFixed(2);

    gstTotalPurchases = Number(
      Number(gstKitchacoInStoreCashFee) +
        Number(gstKitchacoInStoreCardFee) +
        Number(grossKitchacoOnlinePickupFee) +
        Number(gstMiscCharges1) +
        Number(gstMiscCharges2) +
        Number(gstMiscCharges3) +
        Number(gstMiscCharges4) +
        Number(gstMiscCharges5)
    ).toFixed(2);

    grossTotalSalesLessExpenditure = Number(
      Number(grossTotalRevenue) - Number(grossTotalPurchases)
    ).toFixed(2);
    netTotalSalesLessExpenditure = Number(
      Number(netTotalRevenue) - Number(netTotalPurchases)
    ).toFixed(2);
    gstTotalSalesLessExpenditure = Number(
      Number(gstTotalRevenue) - Number(gstTotalPurchases)
    ).toFixed(2);

    payoutResult = Number(
      Number(grossTotalRevenueWithoutCashSales) - Number(grossTotalPurchases)
    ).toFixed(2);
    netPayment = Number(Number(payoutResult) - Number(gst)).toFixed(2);
    gst = Number(Number(gstTotalRevenue) - Number(gstTotalPurchases)).toFixed(
      2
    );
    const jsonToXLSX = (data, filename) => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    const payoutObject = [
      {
        Vendor: operatorPayout.vendor__title,
        approved_date: operatorPayout.approved_on,
        paid_date: operatorPayout.paid_on || "Not Paid",
        total_sales: operatorPayout.total_sales,
        charge_title: operatorPayout.label_misc_charges1,
        charge: operatorPayout.gross_misc_charges1,
        gstMiscCharges1: operatorPayout.gst_misc_charges1,
        netMiscCharges1: operatorPayout.net_misc_charges1,
        labelMiscCharges2: operatorPayout.label_misc_charges2,
        grossMiscCharges2: operatorPayout.gross_misc_charges2,
        gstMiscCharges2: operatorPayout.gst_misc_charges2,
        netMiscCharges2: operatorPayout.net_misc_charges2,
        labelMiscCharges3: operatorPayout.label_misc_charges3,
        grossMiscCharges3: operatorPayout.gross_misc_charges3,
        gstMiscCharges3: operatorPayout.gst_misc_charges3,
        netMiscCharges3: operatorPayout.net_misc_charges3,
        labelMiscCharges4: operatorPayout.label_misc_charges4,
        grossMiscCharges4: operatorPayout.gross_misc_charges4,
        gstMiscCharges4: operatorPayout.gst_misc_charges4,
        netMiscCharges4: operatorPayout.net_misc_charges4,
        labelMiscCharges5: operatorPayout.label_misc_charges5,
        grossMiscCharges5: operatorPayout.gross_misc_charges5,
        gstMiscCharges5: operatorPayout.gst_misc_charges5,
        netMiscCharges5: operatorPayout.net_misc_charges5,
        totalRevenue: operatorPayout.total_revenue,
        totalGST: operatorPayout.total_gst,
        totalEarnings: operatorPayout.total_earnings,
        totalPayoutIncGST: operatorPayout.total_payout_inc_gst,
        wttCashCommission: operatorPayout.wtt_cash_commission,
        wttCardCommission: operatorPayout.wtt_card_commission,
        wttVoucherCommission: operatorPayout.wtt_voucher_commission,
      },
    ];
    return (
      <>
        <VendorNavBar isOperatorLoggedIn={isOperatorLoggedIn}></VendorNavBar>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={6} lg={6}>
                {vendors && vendors.length > 0 ? (
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>
                      Vendors {"(" + vendors.length + ")"}
                    </Form.Label>

                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedVendorId}
                      onChange={(e) => {
                        console.log(e.target.value);
                        this.setState(
                          { selectedVendorId: "" + e.target.value },
                          () => {}
                        );
                        saveToLocalStorage(
                          "selectedVendorId",
                          "" + e.target.value
                        );
                        this.getSalesReport();
                      }}
                    >
                      {vendors && vendors.length == 1 ? (
                        <option value={vendors[0].user_ptr_id}>
                          {vendors[0].title}
                        </option>
                      ) : (
                        <>
                          <option value={0}>Select Vendor</option>
                          {vendors &&
                            vendors.map((e) => {
                              return (
                                <option value={"" + e.user_ptr_id}>
                                  {e.title}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                ) : null}
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={8} md={8} lg={8}>
                    {salesInvoiceFornightPeriod &&
                    salesInvoiceFornightPeriod.length > 0 ? (
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Label>Date Period</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          onChange={(e) => {
                            const selectedPeriodId = parseInt(
                              e.target.value,
                              10
                            ); // Convert to a number
                            const selectedPeriod = salesInvoiceFornightPeriod.find(
                              (period) => period.id === selectedPeriodId
                            );

                            if (selectedPeriod) {
                              const startDate = moment(
                                selectedPeriod.start_date
                              ).format("DD MMM YYYY");
                              const endDate = moment(
                                selectedPeriod.end_date
                              ).format("DD MMM YYYY");

                              this.setStartDate(startDate);
                              this.setEndDate(endDate);
                            }

                            this.setState(
                              {
                                adminSalesInvoiceFortnightPeriod:
                                  "" + e.target.value,
                              },
                              () => {
                                saveToLocalStorage(
                                  "adminSalesInvoiceFortnightPeriod",
                                  this.state.adminSalesInvoiceFortnightPeriod
                                );
                                this.getSalesReport(); // Called after the state update is complete
                              }
                            );
                          }}
                          defaultValue={adminSalesInvoiceFortnightPeriod}
                        >
                          <option value={0}>Select Date Period</option>
                          {salesInvoiceFornightPeriod.map(
                            (salesInvoicePeriod, i) => (
                              <option key={i} value={salesInvoicePeriod.id}>
                                {salesInvoicePeriod.start_date +
                                  " - " +
                                  salesInvoicePeriod.end_date}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            {adminSalesInvoiceFortnightPeriod != 1 ? (
              <Row style={{ marginTop: 10 }}>
                <Col xs={12} md={12} lg={12}>
                  {this.state.operatorPayouts.map((payout, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {"Payout ID" +
                            payout.id +
                            "   Event: " +
                            payout.event__name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          key={index}
                          className="payout-details"
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            padding: "15px",
                            marginBottom: "20px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <h3
                            style={{
                              borderBottom: "1px solid #ccc",
                              paddingBottom: "10px",
                            }}
                          >
                            {payout.vendor_name}
                          </h3>

                          <div style={{ marginBottom: "15px" }}>
                            <strong>Event and Vendor Information:</strong>
                            <p>
                              Event Organizer:{" "}
                              {payout.event_organiser__first_name +
                                " " +
                                payout.event_organiser__last_name}
                            </p>
                            <p>Vendor: {payout.vendor__title}</p>
                            <p>Event: {payout.event__name}</p>
                          </div>

                          <div style={{ marginBottom: "15px" }}>
                            <strong>Approval Information:</strong>
                            <p>Approved By: {payout.approved_by}</p>
                            <p>Payout Status: {payout.payout_status}</p>
                            <p>Timestamp: {payout.time_stamp}</p>
                          </div>

                          <div style={{ marginBottom: "15px" }}>
                            <strong>Commission Information:</strong>
                            <p>
                              Total WTT Commission:%{" "}
                              {payout.total_wtt_commission}
                            </p>
                            <p>
                              Total WTT Card Commission:{" "}
                              {payout.total_wtt_card_commission}
                            </p>
                            <p>
                              Total WTT Cash Commission:{" "}
                              {payout.total_wtt_cash_commission}
                            </p>
                            <p>
                              Total WTT Voucher Commission:{" "}
                              {payout.total_wtt_voucher_commission}
                            </p>
                          </div>

                          <div style={{ marginBottom: "15px" }}>
                            <strong>Miscellaneous Charges:</strong>
                            {payout.label_misc_charges1 && (
                              <p>
                                {payout.label_misc_charges1}:{" "}
                                {payout.gross_misc_charges1}
                              </p>
                            )}
                            {payout.label_misc_charges2 && (
                              <p>
                                {payout.label_misc_charges2}:{" "}
                                {payout.gross_misc_charges2}
                              </p>
                            )}
                            {payout.label_misc_charges3 && (
                              <p>
                                {payout.label_misc_charges3}:{" "}
                                {payout.gross_misc_charges3}
                              </p>
                            )}
                            {payout.label_misc_charges4 && (
                              <p>
                                {payout.label_misc_charges4}:{" "}
                                {payout.gross_misc_charges4}
                              </p>
                            )}
                            {payout.label_misc_charges5 && (
                              <p>
                                {payout.label_misc_charges5}:{" "}
                                {payout.gross_misc_charges5}
                              </p>
                            )}
                          </div>

                          <div style={{ marginBottom: "15px" }}>
                            <strong>Sales Information:</strong>
                            <p>Cash Sales: {payout.wtt_cash_sales}</p>
                            <p>Card Sales: {payout.wtt_card_sales}</p>
                            <p>Voucher Sales: {payout.wtt_voucher_sales}</p>
                          </div>

                          <div style={{ marginBottom: "15px" }}>
                            <strong>Financial Totals:</strong>
                            <p>Total Payout: {payout.total_payout}</p>
                            <p>Total GST: {payout.total_gst}</p>
                            <p>Total Revenue: {payout.total_wtt_payout}</p>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <div>
                    <button
                      style={{
                        background: "#2959a5",
                        backgroundColor: "#2959a5",
                        borderRadius: "6px",
                        border: "1px solid #2959a5",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "6px 24px",
                        textDecoration: "none",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        jsonToXLSX(this.state.operatorPayouts, "payouts")
                      }
                    >
                      DOWNLOAD EXCEL REPORT
                    </button>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>{errorMessage} </p>
                </Col>
              </Row>
            )}
          </Container>
        )}
        <Footer />
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorPayouts, {
    fallback: <ErrorFallbackComponent />,
  })
);
