import React, { useState, useEffect } from "react";
import RegModal from "./RegModal";
import NewModal from "./NewModal";
import { Box, Modal, Button, Typography, IconButton } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { ItemTypes } from "../constant/Constant";
import RegisterStall from "../../RegisterStall";
import EditStall from "../../EditStall";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { apiPaths } from "../../../services/apiPath";
import config from "../../../services/apiConfig";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../../store";
import { useDraggable } from "@dnd-kit/core";

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
};

const StallItem = (props) => {
  const { item, operatorInfo } = props;
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [eventOrganiserName, setEventOrganiserName] = useState("");
  const [eventName, setEventName] = useState("");
  const [certificate, setCertificate] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showShareCateringModal, setShowCateringShareModal] = useState(false);
  const [cateringFunctionType, setCateringFunctionType] = useState([
    "Wedding",
    "Birthday",
    "Corporate",
    "Other",
  ]);
  const [selectedFunctionType, setSelectedFunctionType] = useState("");
  const [availableTypes, setAvailableTypes] = useState([]);
  const [selectedCateringPack, setSelectedCateringPack] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [location, setLocation] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [endEventStartDate, setEndEventStartDate] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [startDateTimeValue, setStartDateTimeValue] = useState("");
  const [endDateTimeValue, setEndDateTimeValue] = useState("");
  const [customerJobLocation, setCustomerJobLocation] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormClose = () => setShowForm(false);
  const handleClose = () => setShowModal(false);

  const history = useHistory();

  useEffect(() => {
    get_catering_packeg();
  }, [selectedFunctionType]);

  const navigateToVendor = (vendorId) => {
    history.push(`/vendor/${vendorId}`);
  };

  const get_catering_packeg = () => {
    if (selectedFunctionType) {
      const url = config.BASE_URI + apiPaths.operatorData;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          payload: {
            body: {
              query_type: "get_packs_for_truck",
              pack_type: selectedFunctionType,
              vendor_id: item.id,
            },
          },
        }),
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success === true) {
            setAvailableTypes(response.packs);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleSendCatering = async () => {
    setIsLoading(true);
    const operator_id = JSON.parse(getFromLocalStorage("Operator"))[0].id;
    const formData = new FormData();
    formData.append("email", customerEmail);
    formData.append("customer_name", customerName);
    formData.append("number_of_people", numberOfPeople);
    formData.append("stall_id", item.id);
    formData.append("operator_id", operator_id);
    formData.append("pack_id", selectedCateringPack.id);
    formData.append("start_date_time", startDateTimeValue);
    formData.append("end_date_time", endDateTimeValue);
    formData.append("job_location", customerJobLocation);
    formData.append("phone_number", customerPhoneNumber);

    try {
      const response = await axios.post(
        config.BASE_URI + apiPaths.shareCateringLink,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (response.data.success === true) {
        alert("Link Shared with event Organiser!");
      } else {
        alert("Please try again");
      }
    } catch (error) {
      alert("Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = async () => {
    if (!isValidEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    const formattedEventStartDate = moment(eventStartDate).format("DD MMM YYYY");
    const formattedEOIStartDate = moment(endEventStartDate).format("DD MMM YYYY");
    const operator_id = JSON.parse(getFromLocalStorage("Operator"))[0].id;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("eventName", eventName);
    formData.append("eventOrganiserName", eventOrganiserName);
    formData.append("stall_id", item.id);
    formData.append("operator_id", operator_id);
    formData.append("event_location", location);
    formData.append("eventStartDate", formattedEventStartDate);
    formData.append("endEventStartDate", formattedEOIStartDate);
    formData.append("isChecked", isChecked);
    if (certificate) {
      formData.append("statement_of_trade", certificate);
    }

    try {
      setIsLoading(true);
      const response = await axios.post(config.BASE_URI + apiPaths.shareLink, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.success === true) {
        alert("Link Shared with event Organiser!");
      } else {
        alert("Please try again");
      }
    } catch (error) {
      alert("Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.id,
    data: item,
  });

  const style = transform
    ? { transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`, position: "fixed" }
    : undefined;

  return (
    <div style={{ marginRight: "10px", width: 300 }}>
      <RegModal show={showModal} handleClose={handleClose} itemDetail={item} operator={operatorInfo} />
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={showForm} onClose={handleFormClose} className="modalnvm">
        <Box className="modalnvm-content">
          <CloseIcon className="plzHover" fontSize="large" style={{ margin: 10, float: "right" }} onClick={handleFormClose} />
          <EditStall stall_details={item} />
        </Box>
      </Modal>
      <Modal aria-labelledby="share-modal-title" aria-describedby="share-modal-description" open={showShareModal} onClose={() => setShowShareModal(false)} className="modalnvm">
        <Box className="modalnvm-content">
          <CloseIcon className="plzHover" fontSize="large" style={{ margin: 10, float: "right" }} onClick={() => setShowShareModal(false)} />
          <Typography variant="h6" component="div">Share Outlet</Typography>
          <input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="text" placeholder="Enter Event Name" value={eventName} onChange={(e) => setEventName(e.target.value)} />
          <input type="text" placeholder="Event Organiser Full Name" value={eventOrganiserName} onChange={(e) => setEventOrganiserName(e.target.value)} />
          <input type="text" placeholder="Event Location" value={location} onChange={(e) => setLocation(e.target.value)} />
          <label>Enter The Event Start Date</label>
          <input type="datetime-local" value={eventStartDate} onChange={(e) => setEventStartDate(e.target.value)} />
          <label>Enter EOI Application Date</label>
          <input type="datetime-local" value={endEventStartDate} onChange={(e) => setEndEventStartDate(e.target.value)} />
          <label>
            <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
            EOI Application Date not sure
          </label>
          <input type="file" onChange={(e) => setCertificate(e.target.files[0])} />
          <Button onClick={handleSend} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Send"}
          </Button>
        </Box>
      </Modal>
      <Modal aria-labelledby="share-modal-title" aria-describedby="share-modal-description" open={showShareCateringModal} onClose={() => setShowCateringShareModal(false)} className="modalnvm">
        <Box className="modalnvm-content">
          <CloseIcon className="plzHover" fontSize="large" style={{ margin: 10, float: "right" }} onClick={() => setShowCateringShareModal(false)} />
          <Typography variant="h6" component="div">Share Catering Proposal With Client</Typography>
          <input type="text" placeholder="Customer Name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
          <input type="email" placeholder="Customer Enter email" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
          <input type="text" placeholder="Job Location" value={customerJobLocation} onChange={(e) => setCustomerJobLocation(e.target.value)} />
          <input type="text" placeholder="Phone Number" value={customerPhoneNumber} onChange={(e) => setCustomerPhoneNumber(e.target.value)} />
          <input type="datetime-local" value={startDateTimeValue} onChange={(e) => setStartDateTimeValue(e.target.value)} />
          <input type="datetime-local" value={endDateTimeValue} onChange={(e) => setEndDateTimeValue(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="function-type-label">Function Type</InputLabel>
            <Select labelId="function-type-label" id="function-type-select" value={selectedFunctionType} label="Function Type" onChange={(e) => setSelectedFunctionType(e.target.value)}>
              {cateringFunctionType.map((type, index) => (
                <MenuItem key={index} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <input type="number" placeholder="Number Of People" value={numberOfPeople} onChange={(e) => setNumberOfPeople(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="catering-pack-label">Select Catering Pack</InputLabel>
            <Select labelId="catering-pack-label" id="catering-pack-select" value={selectedCateringPack} label="Select Catering Pack" onChange={(e) => setSelectedCateringPack(e.target.value)}>
              {availableTypes.map((type, index) => (
                <MenuItem key={index} value={type}>{type.package_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleSendCatering} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Send"}
          </Button>
        </Box>
      </Modal>
      <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
        <Card sx={{ width: 250 }} style={{ cursor: "move", backgroundColor: item.liablity_certificate === null || item.food_refistration_docs === null || item.logo === null ? "#f8d7da" : "white" }}>
          <CardMedia component="img" height="100" image={item.logo} alt={item.title} />
          <CardContent sx={{ padding: 0, textAlign: "center" }}>
            <Typography variant="h5" component="div">{item.title}</Typography>
            {(item.liablity_certificate === null || item.food_refistration_docs === null || item.logo === null) && (
              <Typography variant="body2" color="error">Please upload your Documents before applying</Typography>
            )}
          </CardContent>
          <CardActions sx={{ padding: 0, justifyContent: "space-evenly" }}>
            <IconButton aria-label="edit" onMouseDown={() => setShowForm(true)}><EditIcon /></IconButton>
            <IconButton aria-label="share" onMouseDown={(e) => { e.stopPropagation(); setShowShareModal(true); }}><ShareIcon /></IconButton>
            <IconButton aria-label="share" onMouseDown={(e) => { e.stopPropagation(); setShowCateringShareModal(true); }}><FastfoodIcon /></IconButton>
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

export default StallItem;
