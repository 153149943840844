import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../components/UI/Footer";
import AdminNavBar from "../../components/UI/AdminNavBar";
import * as XLSX from "xlsx";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import CsvDownloadButton from "react-json-to-csv";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Card,
  Modal,
} from "react-bootstrap";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import SearchBar from "../../components/SearchBar";
import SearchResultsList from "../../components/SearchResultsList";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

class AdminStockAnalytics extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    isHourlyGraphLoading: false,
    isMealFreqLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    total_vouchers_value: 0,
    totalCardOrders: 0,
    totalTransactionFees: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    subTotal: 0,
    gst: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [],
    newMealFrequency: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    averageStockOrder: 0,
    numberOfTimesStockOrdered: 0,
    stock_order_data_obj: {},
    stock_orders: {},
    total_waste_cost: 0,
    total_waste_percentage: 0,
    total_stock_cost_percentage: 0,
    total_percentage_of_stock_used: 0,
    total_cost_for_stock_used: 0,
    total_waste_sum_for_event: 0,
    waste_menu_list: [],
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    if (isSuperAdminLoggedIn === "true" || isStockAdminLoggedIn === "true") {
      this.getAllEvents();
    } else {
      window.location.href = "/admin";
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getMealFrequency = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }

    this.setState({ isMealFreqLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_by_vendor_graphs",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      timeout: 120000,
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            meal_freq: dataResponse.meal_freq,
          });
          // var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        // for (let [key, value] of Object.entries(salesByHoursObj)) {
        //   salesByHoursLabelsTemp.push(key);
        // }

        // salesByHoursLabelsTemp.sort();
        // var len = salesByHoursLabelsTemp.length;
        // for (var i = 0; i < len; i++) {
        //   var k = salesByHoursLabelsTemp[i];
        //   salesByHoursDataTemp.push(salesByHoursObj[k]);
        // }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        this.setState({ isMealFreqLoading: false });
        // this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        // this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  getHourlySales = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isHourlyGraphLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_hourly_sales",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            salesByHours: dataResponse.sorted_hours_sales,
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
        }
        // let salesBYVendorName = [];
        // let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        // for (const [key, value] of Object.entries(
        //   dataResponse.sales_by_vendor
        // )) {
        // }
        // for (let [key, value] of Object.entries(salesByHoursObj)) {
        //   salesByHoursLabelsTemp.push(key);
        // }

        // salesByHoursLabelsTemp.sort();
        // var len = salesByHoursLabelsTemp.length;
        // for (var i = 0; i < len; i++) {
        //   var k = salesByHoursLabelsTemp[i];
        //   salesByHoursDataTemp.push(salesByHoursObj[k]);
        // }

        // this.setState({ salesByVendorName: salesBYVendorName });
        // this.setState({ salesByVendorTotal: salesBYVendorTotal });
        // this.setState({ isHourlyGraphLoading: false });
        // this.getMealFrequency();
        // this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        // this.setState({ salesByHoursData: salesByHoursDataTemp });

        // // const tempData = this.createDataForGraph();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_stock_analytics",
          email: email,
          password: password,
          event: selectedEventId,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            averageStockOrder: dataResponse.average_stock_order,
            numberOfTimesStockOrdered:
              dataResponse.number_of_times_stock_ordered,
            stock_order_data_obj: dataResponse.stock_order_data_obj,
            selectedEvent: dataResponse.event,
            stock_orders: dataResponse.stock_orders,
            total_waste_cost: dataResponse.total_waste_cost,
            total_sales: dataResponse.total_sales,
            total_cost_for_stock_used: dataResponse.total_cost_for_stock_used,
            total_waste_sum_for_event: dataResponse.total_waste_sum,
            waste_menu_list: dataResponse.waste_menu_list,
          });
        } else {
          this.setState({
            averageStockOrder: 0,
            numberOfTimesStockOrdered: 0,
            stock_order_data_obj: {},
            selectedEvent: {},
            stock_orders: {},
            total_waste_cost: 0,
            total_sales: 0,
            total_cost_for_stock_used: 0,
            total_waste_sum_for_event: 0,
            waste_menu_list: [],
          });
        }
        const wastePercentage = (
          (this.state.total_waste_cost / this.state.total_sales) *
          100
        ).toFixed(2);
        const total_stock_ordered_percentage = (
          (this.state.stock_orders.total__sum / this.state.total_sales) *
          100
        ).toFixed(2);
        const total_stock_used_percentage = (
          (this.state.total_cost_for_stock_used / this.state.total_sales) *
          100
        ).toFixed(2);
        this.setState({
          total_stock_cost_percentage: total_stock_ordered_percentage,
        });
        this.setState({ total_waste_percentage: wastePercentage });
        this.setState({
          total_percentage_of_stock_used: total_stock_used_percentage,
        });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getVendorsInEvent = () => {
    const { email, password, selectedEventId } = this.state;
    if (selectedEventId == 0) {
      alert("Please select an event.");
      return;
    }
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_in_event",
          email: email,
          password: password,
          event: selectedEventId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ vendors: dataResponse.vendors }, () => {
          // console.log(this.state.vendors[0])
          let vendorId = "0";
          vendorId.toString();
          this.setState({ selectedVendorId: vendorId }, () => {
            saveToLocalStorage("selectedVendorId", vendorId);
            this.getOrders();
          });
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };
  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }

  renderHome() {
    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      averageStockOrder,
      stock_order_data_obj,
      numberOfTimesStockOrdered,
      stock_orders,
      total_stock_cost_percentage,
      total_waste_percentage,
      total_waste_cost,
      total_percentage_of_stock_used,
      total_waste_sum_for_event,
      waste_menu_list,
    } = this.state;

    if (selectedEvent) {
    }
    let date_range = this.getDateRange(
      selectedEvent.start_date,
      selectedEvent.end_date
    );

    return (
      <div>
        {!isLoading ? (
          <Container
            style={{
              borderWidth: 1,
              borderColor: "grey",
              borderStyle: "solid",
              borderRadius: 5,
              padding: 15,
            }}
          >
            <Row>
              <Col xs={12} md={6} lg={6}>
                <Form.Label>Select Event</Form.Label>
                <Form.Group
                  controlId="exampleForm.SelectCustomSizeSm"
                  className="search-dropdown"
                >
                  <div className="search-input">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={selectedEventId}
                      onChange={(e) => {
                        this.setState(
                          { selectedEventId: "" + e.target.value },
                          () => {
                            this.getVendorsInEvent();
                          }
                        );
                        saveToLocalStorage(
                          "selectedEventId",
                          "" + e.target.value
                        );
                      }}
                    >
                      <option value={0}>Select Event</option>
                      {events.map((e) => (
                        <option value={"" + e.id}>
                          {e.name +
                            " - " +
                            e.start_date +
                            " (" +
                            e.event_status +
                            ")"}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Form.Label>Select Vendor</Form.Label>
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Control
                    as="select"
                    size="sm"
                    custom
                    value={selectedVendorId}
                    onChange={(e) => {
                      this.setState(
                        { selectedVendorId: "" + e.target.value },
                        () => {
                          this.getOrders();
                        }
                      );
                      saveToLocalStorage(
                        "selectedVendorId",
                        "" + e.target.value
                      );
                    }}
                  >
                    {vendors.length == 1 ? (
                      <option value={vendors[0].user_ptr_id}>
                        {vendors[0].title}
                      </option>
                    ) : (
                      <>
                        <option value={0}>All</option>
                        {vendors &&
                          vendors.map((e) => {
                            return (
                              <option value={"" + e.user_ptr_id}>
                                {e.title}
                              </option>
                            );
                          })}
                      </>
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
              {selectedVendorId != "0" ? (
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Time</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={this.state.selectedDate}
                      onChange={(e) => {
                        this.setState({ selectedDate: e.target.value }, () => {
                          this.getOrders();
                        });
                      }}
                    >
                      <>
                        <option value={"-1"}>All Days</option>
                        {date_range.map((e, zrindex) => {
                          return <option value={e}>{e}</option>;
                        })}
                      </>
                    </Form.Control>
                  </Form.Group>
                </Col>
              ) : (
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Time</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={this.state.selectedDate}
                      onChange={(e) => {
                        this.setState({ selectedDate: e.target.value }, () => {
                          this.getOrders();
                        });
                      }}
                    >
                      <>
                        <option value={"-1"}>All Days</option>
                        {date_range.map((e, zrindex) => {
                          return <option value={e}>{e}</option>;
                        })}
                      </>
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
            </Row>
            {!isEmpty(selectedEvent) ? (
              <>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Event Name:</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>{selectedEvent.name}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Event Location:</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>{selectedEvent.location}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Event Time:</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>
                      {selectedEvent.start_date +
                        " - " +
                        selectedEvent.end_date +
                        ", " +
                        selectedEvent.start_time +
                        " - " +
                        selectedEvent.end_time}
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Average Stock Order :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>${Number(averageStockOrder).toFixed(2)}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Total Stock Ordered :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>${Number(stock_orders.total__sum).toFixed(2)}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Number Of Times Stock Ordered :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>${Number(numberOfTimesStockOrdered).toFixed(2)}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Total Waste Cost % of Event Sales:</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>{Number(total_waste_percentage).toFixed(2)} %</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Total Stock Order Cost % of Event Sales :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>{Number(total_stock_cost_percentage).toFixed(2)} % </h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Total Stock Used % of Event Sales :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>
                      {Number(total_percentage_of_stock_used).toFixed(2)} % /{" "}
                      {Number(this.state.total_cost_for_stock_used)}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Total Waste Cost :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>${total_waste_sum_for_event}</h5>
                  </Col>
                </Row>
                <div>
                  <div className="stock-order-container">
                    <h1>Stock Order Data</h1>
                    <table className="stock-order-table">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Price Per Unit</th>
                          <th>Stock Ordered</th>
                          <th>Closing Stock Qty</th>
                          <th>Waste Stock Qty</th>
                          <th>Stock Used</th>
                          <th>Remaining Stock</th>

                          <th>Total Cost For Stock Used</th>
                          <th>
                            Percentage Of Stock Used Individual Item Compared to
                            Total Event Sales
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(stock_order_data_obj).map(
                          (productName) => (
                            <tr key={productName}>
                              <td>{stock_order_data_obj[productName].name}</td>
                              <td>{stock_order_data_obj[productName].price}</td>
                              <td>
                                {
                                  stock_order_data_obj[productName]
                                    .stock_ordered
                                }
                              </td>
                              <td>
                                {
                                  stock_order_data_obj[productName]
                                    .closing_stock_qty
                                }
                              </td>
                              <td>
                                {stock_order_data_obj[productName].wasted_stock}
                              </td>
                              <td>
                                {stock_order_data_obj[productName].stock_left}
                              </td>
                              <td>
                                {
                                  stock_order_data_obj[productName]
                                    .remaining_stock
                                }
                              </td>
                              <td>
                                {Number(
                                  stock_order_data_obj[productName].price *
                                    stock_order_data_obj[productName].stock_left
                                ).toFixed(2)}
                              </td>
                              <td>
                                %
                                {Number(
                                  ((stock_order_data_obj[productName].price *
                                    stock_order_data_obj[productName]
                                      .stock_left) /
                                    this.state.total_sales) *
                                    100
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="stock-order-container">
                  <h1 className="stock-order-table">Waste Menu List</h1>
                  <table className="stock-order-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Stocked Wasted Quantity</th>
                        <th>Total Waste Cost for items</th>
                      </tr>
                    </thead>
                    <tbody>
                      {waste_menu_list.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>${item.price}</td>
                          <td>{item.stocked_wasted_qty}</td>
                          <td>${item.total_waste_cost}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Event Name:</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>{selectedEvent.name}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Event Location:</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>{selectedEvent.location}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Event Time:</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>
                      {selectedEvent.start_date +
                        " - " +
                        selectedEvent.end_date +
                        ", " +
                        selectedEvent.start_time +
                        " - " +
                        selectedEvent.end_time}
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Average Stock Order :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>${Number(averageStockOrder).toFixed(2)}</h5>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <h5>Number Of Times Stock Ordered :</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h5>${Number(numberOfTimesStockOrdered).toFixed(2)}</h5>
                  </Col>
                </Row>
              </>
            )}
            <div></div>
            <div></div>
          </Container>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }

  renderOrderStatusChangeModal() {
    const {
      isOpenOrderStatusChangeModal,
      selectedOrder,
      selectedOrderNewStatus,
      isLoadingChangeOrderStatus,
    } = this.state;

    return (
      <>
        {selectedOrder != null && selectedOrderNewStatus != "" ? (
          <Modal
            show={isOpenOrderStatusChangeModal}
            onHide={() => {
              this.setState({ isOpenOrderStatusChangeModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Change Order #{selectedOrder.id} to {selectedOrderNewStatus}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ textAlign: "center", width: "90%", margin: "auto" }}
            >
              <p>
                Are you sure you want to change Order #{selectedOrder.id} to{" "}
                {selectedOrderNewStatus}? This will also send a message to the
                customer.
              </p>
            </Modal.Body>
          </Modal>
        ) : null}
        <Footer />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminStockAnalytics, {
    fallback: <ErrorFallbackComponent />,
  })
);
