import React, { useEffect, useState } from "react";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import { useDispatch } from "react-redux";
import Footer from "../../components/UI/Footer";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
} from "react-bootstrap";
import { hasEventExpired } from "../../helper";
import { AWS_URL } from "../../assets/Constants";

import { apiPaths } from "../../services/apiPath";

import config from "../../services/apiConfig";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { store } from "../../store";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import "./style.css";
import { isAfter, isBefore, isToday, startOfDay } from "date-fns";
import NotFound from "./components/not-found";

const EventsByVenueScreen = (props) => {
  const dispatch = useDispatch();

  const { appReducer } = store.getState();
  const { eventTicket, products } = appReducer;
  const { loggedIn, username, userId } = props;

  const venueId = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );

  const [isLoading, setIsLoading] = useState(false);
  const [pastEvents, setPastEvents] = useState([]);
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);

  const getAllEvents = () => {
    setIsLoading(true);

    const stringTimeToHrsMins = (time) => {
      const timeParts = time.split(" ");
      let hour = parseInt(timeParts[0].split(":")[0]);
      let minute = parseInt(timeParts[0].split(":")[1]);
      let ampm = timeParts[1];
      if (ampm === "PM") {
        if (hour !== 12) {
          hour += 12;
        }
      } else {
        if (hour === 12) {
          hour = 0;
        }
      }
      return [hour, minute];
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_venue_events",
          venue_id: Number(venueId),
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.devUrl + apiPaths.organiserData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        if (result.success == true) {
          const ogEvents = result.events.filter((event) => {
            const [start_hour, start_minute] = stringTimeToHrsMins(
              event.start_time
            );
            const [end_hour, end_minute] = stringTimeToHrsMins(event.end_time);

            const start_date = new Date(event.start_date);
            start_date.setHours(start_hour);
            start_date.setMinutes(start_minute);
            const end_date = new Date(event.end_date);
            end_date.setHours(end_hour);
            end_date.setMinutes(end_minute);
            const today = new Date();
            if (isBefore(start_date, today) && isAfter(end_date, today)) {
              return true;
            } else return false;
          });
          const ftEvents = result.events.filter((event) => {
            const [start_hour, start_minute] = stringTimeToHrsMins(
              event.start_time
            );

            const start_date = new Date(event.start_date);
            start_date.setHours(start_hour);
            start_date.setMinutes(start_minute);

            const today = new Date();
            if (isAfter(start_date, today)) {
              return true;
            } else return false;
          });
          const ptEvents = result.events.filter((event) => {
            const [start_hour, start_minute] = stringTimeToHrsMins(
              event.start_time
            );

            const end_date = new Date(event.end_date);
            end_date.setHours(start_hour);
            end_date.setMinutes(start_minute);

            const today = new Date();
            if (isBefore(end_date, today)) {
              return true;
            } else return false;
          });

          setPastEvents(ptEvents);
          setOngoingEvents(ogEvents);
          setFutureEvents(ftEvents);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <div>
      <ResponsiveNavBar
        showBack={true}
        backUrl={"/events"}
        ticketState={eventTicket}
        products={products}
        loggedIn={loggedIn}
        username={username}
        userd={userId}
        dispatch={dispatch}
      ></ResponsiveNavBar>
      {!isLoading ? (
        <>
          <Container>
            <Row style={{ marginTop: 30 }}>
              <Col xs={12}>
                <h2>What's Trucking</h2>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col>
                <Accordion expanded>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3>Ongoing Events</h3>
                  </AccordionSummary>

                  <AccordionDetails>
                    {ongoingEvents && ongoingEvents.length && !isLoading ? (
                      ongoingEvents.map((item, index) => {
                        return (
                          <>
                            {index % 2 == 0 ? (
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  style={{ cursor: "pointer", marginTop: 30 }}
                                  onClick={() => {
                                    window.location.href =
                                      "/event:" + ongoingEvents[index].id;
                                  }}
                                >
                                  <Card className="card-container-events venue-event-card">
                                    <div
                                      className="cover-image"
                                      style={{ overflow: "hidden" }}
                                    >
                                      <Card.Img
                                        variant="top"
                                        src={
                                          ongoingEvents[index] &&
                                          ongoingEvents[index].cover &&
                                          ongoingEvents[index].cover != "" &&
                                          ongoingEvents[index].cover != null
                                            ? AWS_URL +
                                              "/" +
                                              ongoingEvents[index].cover
                                            : require("../../assets/img/default_cover.jpg")
                                        }
                                      />
                                    </div>
                                    <Card.Body>
                                      <Card.Title
                                        style={{
                                          fontWeight: 400,
                                          fontSize: 25,
                                        }}
                                        className=".me-5"
                                      >
                                        {ongoingEvents[index].name}
                                      </Card.Title>
                                      <Card.Subtitle
                                        className="mb-2  "
                                        style={{ marginTop: 5 }}
                                      >
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/date-icon.png")}
                                        ></img>
                                        {ongoingEvents[index].start_date} -{" "}
                                        {ongoingEvents[index].end_date}
                                      </Card.Subtitle>
                                      <Card.Subtitle className="mb-2 ">
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/time-icon.png")}
                                        ></img>
                                        {ongoingEvents[index].start_time} -{" "}
                                        {ongoingEvents[index].end_time}
                                      </Card.Subtitle>
                                      <Card.Subtitle
                                        style={{ textTransform: "capitalize" }}
                                        className="mb-2"
                                      >
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/event-type-icon.png")}
                                        ></img>
                                        {ongoingEvents[index].event_type}
                                      </Card.Subtitle>
                                      <Card.Subtitle className="mb-2 ">
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/location-icon.png")}
                                        ></img>
                                        {ongoingEvents[index].event_location}
                                      </Card.Subtitle>
                                      <Card.Text>
                                        {ongoingEvents[index].description}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  style={{ cursor: "pointer", marginTop: 30 }}
                                  onClick={() => {
                                    window.location.href =
                                      "/event:" + ongoingEvents[index + 1].id;
                                  }}
                                >
                                  {ongoingEvents[index + 1] ? (
                                    <Card className="card-container-events venue-event-card">
                                      <div
                                        className="cover-image"
                                        style={{ overflow: "hidden" }}
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={
                                            ongoingEvents[index + 1] &&
                                            ongoingEvents[index + 1].cover !=
                                              "" &&
                                            ongoingEvents[index + 1].cover !=
                                              null
                                              ? AWS_URL +
                                                "/" +
                                                ongoingEvents[index + 1].cover
                                              : require("../../assets/img/default_cover.jpg")
                                          }
                                        />
                                      </div>
                                      <Card.Body>
                                        <Card.Title
                                          style={{
                                            fontWeight: 400,
                                            fontSize: 25,
                                          }}
                                          className=".me-5"
                                        >
                                          {ongoingEvents[index + 1] &&
                                            ongoingEvents[index + 1].name}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/date-icon.png")}
                                          ></img>
                                          {ongoingEvents[index + 1].start_date}{" "}
                                          - {ongoingEvents[index + 1].end_date}
                                        </Card.Subtitle>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/time-icon.png")}
                                          ></img>
                                          {ongoingEvents[index + 1].start_time}{" "}
                                          - {ongoingEvents[index + 1].end_time}
                                        </Card.Subtitle>
                                        <Card.Subtitle
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          className="mb-2"
                                        >
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/event-type-icon.png")}
                                          ></img>
                                          {ongoingEvents[index + 1].event_type}
                                        </Card.Subtitle>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/location-icon.png")}
                                          ></img>
                                          {
                                            ongoingEvents[index + 1]
                                              .event_location
                                          }
                                        </Card.Subtitle>
                                        <Card.Text>
                                          {ongoingEvents[index + 1].description}
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  ) : null}
                                </Col>
                              </Row>
                            ) : null}
                          </>
                        );
                      })
                    ) : (
                      <NotFound
                        title="No Events Found"
                        description="We're sorry, we couldn't find any ongoing events at the moment."
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col>
                <Accordion expanded>
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3>Upcoming Events</h3>
                  </AccordionSummary>

                  <AccordionDetails>
                    {futureEvents && futureEvents.length && !isLoading ? (
                      futureEvents.map((item, index) => {
                        return (
                          <>
                            {index % 2 == 0 ? (
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  style={{ cursor: "pointer", marginTop: 30 }}
                                  onClick={() => {
                                    window.location.href =
                                      "/event:" + futureEvents[index].id;
                                  }}
                                >
                                  <Card className="card-container-events venue-event-card">
                                    <div
                                      className="cover-image"
                                      style={{ overflow: "hidden" }}
                                    >
                                      <Card.Img
                                        variant="top"
                                        src={
                                          futureEvents[index] &&
                                          futureEvents[index].cover &&
                                          futureEvents[index].cover != "" &&
                                          futureEvents[index].cover != null
                                            ? AWS_URL +
                                              "/" +
                                              futureEvents[index].cover
                                            : require("../../assets/img/default_cover.jpg")
                                        }
                                      />
                                    </div>
                                    <Card.Body>
                                      <Card.Title
                                        style={{
                                          fontWeight: 400,
                                          fontSize: 25,
                                        }}
                                        className=".me-5"
                                      >
                                        {futureEvents[index].name}
                                      </Card.Title>
                                      <Card.Subtitle
                                        className="mb-2  "
                                        style={{ marginTop: 5 }}
                                      >
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/date-icon.png")}
                                        ></img>
                                        {futureEvents[index].start_date} -{" "}
                                        {futureEvents[index].end_date}
                                      </Card.Subtitle>
                                      <Card.Subtitle className="mb-2 ">
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/time-icon.png")}
                                        ></img>
                                        {futureEvents[index].start_time} -{" "}
                                        {futureEvents[index].end_time}
                                      </Card.Subtitle>
                                      <Card.Subtitle
                                        style={{ textTransform: "capitalize" }}
                                        className="mb-2"
                                      >
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/event-type-icon.png")}
                                        ></img>
                                        {futureEvents[index].event_type}
                                      </Card.Subtitle>
                                      <Card.Subtitle className="mb-2 ">
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/location-icon.png")}
                                        ></img>
                                        {futureEvents[index].event_location}
                                      </Card.Subtitle>
                                      <Card.Text>
                                        {futureEvents[index].description}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  style={{ cursor: "pointer", marginTop: 30 }}
                                  onClick={() => {
                                    window.location.href =
                                      "/event:" + futureEvents[index + 1].id;
                                  }}
                                >
                                  {futureEvents[index + 1] ? (
                                    <Card className="card-container-events venue-event-card">
                                      <div
                                        className="cover-image"
                                        style={{ overflow: "hidden" }}
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={
                                            futureEvents[index + 1] &&
                                            futureEvents[index + 1].cover !=
                                              "" &&
                                            futureEvents[index + 1].cover !=
                                              null
                                              ? AWS_URL +
                                                "/" +
                                                futureEvents[index + 1].cover
                                              : require("../../assets/img/default_cover.jpg")
                                          }
                                        />
                                      </div>
                                      <Card.Body>
                                        <Card.Title
                                          style={{
                                            fontWeight: 400,
                                            fontSize: 25,
                                          }}
                                          className=".me-5"
                                        >
                                          {futureEvents[index + 1] &&
                                            futureEvents[index + 1].name}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/date-icon.png")}
                                          ></img>
                                          {futureEvents[index + 1].start_date} -{" "}
                                          {futureEvents[index + 1].end_date}
                                        </Card.Subtitle>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/time-icon.png")}
                                          ></img>
                                          {futureEvents[index + 1].start_time} -{" "}
                                          {futureEvents[index + 1].end_time}
                                        </Card.Subtitle>
                                        <Card.Subtitle
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          className="mb-2"
                                        >
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/event-type-icon.png")}
                                          ></img>
                                          {futureEvents[index + 1].event_type}
                                        </Card.Subtitle>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/location-icon.png")}
                                          ></img>
                                          {
                                            futureEvents[index + 1]
                                              .event_location
                                          }
                                        </Card.Subtitle>
                                        <Card.Text>
                                          {futureEvents[index + 1].description}
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  ) : null}
                                </Col>
                              </Row>
                            ) : null}
                          </>
                        );
                      })
                    ) : (
                      <NotFound
                        title="No Future Events Found"
                        description="We're sorry, we couldn't find any future events."
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>
            {pastEvents && pastEvents.length && !isLoading ? (
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <Accordion expanded>
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h3>Past Events</h3>
                    </AccordionSummary>

                    <AccordionDetails>
                      {pastEvents.map((item, index) => {
                        return (
                          <>
                            {index % 2 == 0 ? (
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  style={{ cursor: "pointer", marginTop: 30 }}
                                >
                                  <Card className="card-container-events venue-event-card">
                                    <div
                                      className="cover-image"
                                      style={{ overflow: "hidden" }}
                                    >
                                      <Card.Img
                                        variant="top"
                                        src={
                                          pastEvents[index] &&
                                          pastEvents[index].cover &&
                                          pastEvents[index].cover != "" &&
                                          pastEvents[index].cover != null
                                            ? AWS_URL +
                                              "/" +
                                              pastEvents[index].cover
                                            : require("../../assets/img/default_cover.jpg")
                                        }
                                      />
                                    </div>
                                    <Card.Body>
                                      <Card.Title
                                        style={{
                                          fontWeight: 400,
                                          fontSize: 25,
                                        }}
                                        className=".me-5"
                                      >
                                        {pastEvents[index].name}
                                      </Card.Title>
                                      <Card.Subtitle
                                        className="mb-2  "
                                        style={{ marginTop: 5 }}
                                      >
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/date-icon.png")}
                                        ></img>
                                        {pastEvents[index].start_date} -{" "}
                                        {pastEvents[index].end_date}
                                      </Card.Subtitle>
                                      <Card.Subtitle className="mb-2 ">
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/time-icon.png")}
                                        ></img>
                                        {pastEvents[index].start_time} -{" "}
                                        {pastEvents[index].end_time}
                                      </Card.Subtitle>
                                      <Card.Subtitle
                                        style={{ textTransform: "capitalize" }}
                                        className="mb-2"
                                      >
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/event-type-icon.png")}
                                        ></img>
                                        {pastEvents[index].event_type}
                                      </Card.Subtitle>
                                      <Card.Subtitle className="mb-2 ">
                                        <img
                                          className="mr-1"
                                          style={{ width: 20 }}
                                          src={require("../../assets/img/location-icon.png")}
                                        ></img>
                                        {pastEvents[index].event_location}
                                      </Card.Subtitle>
                                      <Card.Text>
                                        {pastEvents[index].description}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  style={{ cursor: "pointer", marginTop: 30 }}
                                >
                                  {pastEvents[index + 1] ? (
                                    <Card className="card-container-events venue-event-card">
                                      <div
                                        className="cover-image"
                                        style={{ overflow: "hidden" }}
                                      >
                                        <Card.Img
                                          variant="top"
                                          src={
                                            pastEvents[index + 1] &&
                                            pastEvents[index + 1].cover != "" &&
                                            pastEvents[index + 1].cover != null
                                              ? AWS_URL +
                                                "/" +
                                                pastEvents[index + 1].cover
                                              : require("../../assets/img/default_cover.jpg")
                                          }
                                        />
                                      </div>
                                      <Card.Body>
                                        <Card.Title
                                          style={{
                                            fontWeight: 400,
                                            fontSize: 25,
                                          }}
                                          className=".me-5"
                                        >
                                          {pastEvents[index + 1] &&
                                            pastEvents[index + 1].name}
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/date-icon.png")}
                                          ></img>
                                          {pastEvents[index + 1].start_date} -{" "}
                                          {pastEvents[index + 1].end_date}
                                        </Card.Subtitle>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/time-icon.png")}
                                          ></img>
                                          {pastEvents[index + 1].start_time} -{" "}
                                          {pastEvents[index + 1].end_time}
                                        </Card.Subtitle>
                                        <Card.Subtitle
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          className="mb-2"
                                        >
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/event-type-icon.png")}
                                          ></img>
                                          {pastEvents[index + 1].event_type}
                                        </Card.Subtitle>
                                        <Card.Subtitle className="mb-2 ">
                                          <img
                                            className="mr-1"
                                            style={{ width: 20 }}
                                            src={require("../../assets/img/location-icon.png")}
                                          ></img>
                                          {pastEvents[index + 1].event_location}
                                        </Card.Subtitle>
                                        <Card.Text>
                                          {pastEvents[index + 1].description}
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  ) : null}
                                </Col>
                              </Row>
                            ) : null}
                          </>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Col>
              </Row>
            ) : null}
          </Container>
          <Footer />
        </>
      ) : (
        <>
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        </>
      )}
    </div>
  );
};
export default EventsByVenueScreen;
