import React, { useState, useEffect } from "react";
import EventCard from "./EventCard";
import { apiPaths } from "../../../services/apiPath";
import config from "../../../services/apiConfig";
import { Row, Col, Container } from "react-bootstrap";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../constant/Constant";
import Paper from "@mui/material/Paper";
import "./style.css";
import EventCardMobile from "./EventCardMobile";
const EventSection = ({
  events,
  appliedEvents,
  onDrop,
  removeVendor,
  vendors,
  layout,
}) => {
  // console.log(events, appliedEvents, onDrop, removeVendor, "vendors", vendors);
  return (
    <div>
      {events.map((item, index) => {
        if (index % 2 === 0) {
          if (index < events.length - 1) {
            return (
              <Row key={item.eventDetails.id}>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  style={{ cursor: "pointer", marginTop: 30 }}
                >
                  {layout === "DESKTOP" ? (
                    <EventCard
                      key={item.eventDetails?.id}
                      style={{ margin: "10px" }}
                      event={item}
                      appliedEOI={appliedEvents[index].vendor}
                      keyIndex={index}
                      accept={item.accept}
                      onDrop={(item) => onDrop(index, item)}
                      removeVendor={removeVendor}
                      vendors={vendors}
                    />
                  ) : (
                    <EventCardMobile
                      key={item.eventDetails?.id}
                      style={{ margin: "10px" }}
                      event={item}
                      appliedEOI={appliedEvents[index].vendor}
                      keyIndex={index}
                      accept={item.accept}
                      onDrop={(item) => onDrop(index, item)}
                      removeVendor={removeVendor}
                      vendors={vendors}
                    />
                  )}
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  style={{ cursor: "pointer", marginTop: 30 }}
                >
                  {layout === "DESKTOP" ? (
                    <EventCard
                      key={item.eventDetails?.id}
                      style={{ margin: "10px" }}
                      event={events[index + 1]}
                      appliedEOI={appliedEvents[index + 1].vendor}
                      keyIndex={index + 1}
                      accept={events[index + 1].accept}
                      onDrop={(item) => onDrop(index + 1, item)}
                      removeVendor={removeVendor}
                      vendors={vendors}
                    />
                  ) : (
                    <EventCardMobile
                      key={item.eventDetails?.id}
                      style={{ margin: "10px" }}
                      event={events[index + 1]}
                      appliedEOI={appliedEvents[index + 1].vendor}
                      keyIndex={index + 1}
                      accept={events[index + 1].accept}
                      onDrop={(item) => onDrop(index + 1, item)}
                      removeVendor={removeVendor}
                      vendors={vendors}
                    />
                  )}
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={item.eventDetails.id}>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  style={{ cursor: "pointer", marginTop: 30 }}
                >
                  {layout === "DESKTOP" ? (
                    <EventCard
                      key={item.eventDetails?.id}
                      style={{ margin: "10px" }}
                      event={item}
                      appliedEOI={appliedEvents[index].vendor}
                      keyIndex={index}
                      accept={item.accept}
                      onDrop={(item) => onDrop(index, item)}
                      removeVendor={removeVendor}
                    />
                  ) : (
                    <EventCardMobile
                      key={item.eventDetails?.id}
                      style={{ margin: "10px" }}
                      event={item}
                      appliedEOI={appliedEvents[index].vendor}
                      keyIndex={index}
                      accept={item.accept}
                      onDrop={(item) => onDrop(index, item)}
                      removeVendor={removeVendor}
                    />
                  )}
                </Col>
              </Row>
            );
          }
        }
      })}
    </div>
  );
};

export default EventSection;
