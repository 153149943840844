import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import "./style.css";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import AdminNavBar from "../../components/UI/AdminNavBar/index";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";

class StockAdminApprovedOrdersScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") !== null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") !== "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") !== "" &&
      getFromLocalStorage("isAccountantLoggedIn") !== null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") !== "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") !== "" &&
      getFromLocalStorage("isStockAdminLoggedIn") !== null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") !== null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    stockList: [],
    events: [],
    selectedEvent: 0,
    showModal: false,
    showConfirmation: false,
    newVendorId: "",
    vendorToRemove: null,
    vendors: [],
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    email:
      getFromLocalStorage("stockAdminEmail") &&
      getFromLocalStorage("stockAdminEmail") !== ""
        ? getFromLocalStorage("stockAdminEmail")
        : "",
    password:
      getFromLocalStorage("stockAdminPassword") &&
      getFromLocalStorage("stockAdminPassword") !== ""
        ? getFromLocalStorage("stockAdminPassword")
        : "",
    superAdminEmail:
      getFromLocalStorage("superAdminEmail") &&
      getFromLocalStorage("superAdminEmail") !== ""
        ? getFromLocalStorage("superAdminEmail")
        : "",
    superAdminPassword:
      getFromLocalStorage("superAdminPassword") &&
      getFromLocalStorage("superAdminPassword") !== ""
        ? getFromLocalStorage("superAdminPassword")
        : "",
    isUpdatingStockList: false,
    showUpdateConfirmation: false,
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_future_events",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ events: dataResponse.events });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllVendors() {
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_vendors",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success) {
          this.setState({ vendors: dataResponse.vendors });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchStockListFromServer = () => {
    const {
      superAdminEmail,
      superAdminPassword,
      selectedEvent,
    } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          email: superAdminEmail,
          password: superAdminPassword,
          query_type: "get_vendors_in_event_for_update",
          event: parseInt(selectedEvent),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ stockList: result.vendors });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    if (isSuperAdminLoggedIn === "true" || isStockAdminLoggedIn === "true") {
      this.getKitchacoLocationData();
      this.getAllVendors();
      this.fetchStockListFromServer();
    } else {
      window.location.href = "/admin";
    }
  }

  handleEventChange = (e) => {
    this.setState({ selectedEvent: e.target.value }, () => {
      this.fetchStockListFromServer();
    });
  };

  handleVendorToggle = (index) => {
    this.setState((prevState) => {
      const newStockList = [...prevState.stockList];
      newStockList[index].selected = !newStockList[index].selected;
      return { stockList: newStockList };
    });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, newVendorId: "" });
  };

  handleShowConfirmation = (vendor) => {
    this.setState({ showConfirmation: true, vendorToRemove: vendor });
  };

  handleCloseConfirmation = () => {
    this.setState({ showConfirmation: false, vendorToRemove: null });
  };

  handleAddVendor = () => {
    const { newVendorId, selectedEvent, stockList, vendors } = this.state;
    const newVendor = vendors.find(vendor => vendor.user_ptr_id === parseInt(newVendorId));
    if (newVendor) {
      var url = config.BASE_URI + apiPaths.adminData;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "add_vendor_to_event",
            vendor_id: newVendor.user_ptr_id,
            event_id: selectedEvent,
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            this.setState({
              stockList: [...stockList, { ...newVendor, selected: false }],
              showModal: false,
              newVendorId: "",
            });
          } else {
            alert(result.error);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  handleRemoveVendor = () => {
    const { vendorToRemove, selectedEvent, stockList } = this.state;
    
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "remove_vendor_from_event",
          vendor_id: vendorToRemove.user_ptr_id,
          event_id: selectedEvent
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({
            stockList: stockList.filter(vendor => vendor !== vendorToRemove),
            showConfirmation: false,
            vendorToRemove: null,
          });
        } else {
          alert(result.error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  updateVendorList = () => {
    const {
      stockList,
      superAdminEmail,
      superAdminPassword,
    } = this.state;
    this.setState({ isUpdatingStockList: true });

    const selectedVendors = stockList.filter(vendor => !vendor.selected).map(vendor => ({
      user_ptr_id: vendor.user_ptr_id,
      title: vendor.title,
    }));

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          email: superAdminEmail,
          password: superAdminPassword,
          query_type: "update_vendors_in_event",
          vendors: selectedVendors,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isUpdatingStockList: false });
        if (result.success) {
          alert("Vendor list updated successfully!");
          this.fetchStockListFromServer(); // Refresh the vendor list
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        this.setState({ isUpdatingStockList: false });
        console.log("error", error);
      });
  };

  renderHome() {
    const {
      stockList,
      events,
      selectedEvent,
      showModal,
      showConfirmation,
      newVendorId,
      vendors,
      vendorToRemove,
      isUpdatingStockList,
    } = this.state;

    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Event System</h2>
              <h3>Event and Vendor List</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              {events.length > 0 ? (
                <Form.Group controlId="eventSelect">
                  <Form.Label>Events List</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedEvent}
                    onChange={this.handleEventChange}
                  >
                    {events.map(event => (
                      <option key={event.id} value={event.id}>
                        {event.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              ) : (
                <p>Loading events...</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {stockList.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Vendor ID</th>
                      <th>Vendor Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockList.map((vendor, index) => (
                      <tr key={vendor.user_ptr_id}>
                        
                        <td>{vendor.user_ptr_id}</td>
                        <td>{vendor.title}</td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => this.handleShowConfirmation(vendor)}
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No vendors available.</p>
              )}
            </Col>
          </Row>
          <Row className="button-row">
            <Col xs={12}>
              <Button
                variant="primary"
                onClick={this.handleShowModal}
              >
                Add Vendor
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  if (!isUpdatingStockList) {
                    this.updateVendorList();
                  }
                }}
                className="ml-2"
              >
                {!isUpdatingStockList ? "Update Vendors" : "Loading..."}
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Vendor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="newVendorSelect">
              <Form.Label>Select Vendor</Form.Label>
              <Form.Control
                as="select"
                value={newVendorId}
                onChange={(e) => this.setState({ newVendorId: e.target.value })}
              >
                <option value="">Select a vendor</option>
                {vendors.map(vendor => (
                  <option key={vendor.user_ptr_id} value={vendor.user_ptr_id}>
                    {vendor.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleAddVendor}>
              Add Vendor
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showConfirmation} onHide={this.handleCloseConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Removal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the vendor: {vendorToRemove && vendorToRemove.title}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseConfirmation}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleRemoveVendor}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(StockAdminApprovedOrdersScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
