import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react/";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Image from "react-bootstrap/Image";
import { useForm } from "react-hook-form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ProfileImage from "@daym3l/react-profile-image";
import Media from "react-bootstrap/Media";
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";

const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

function VendorMenuEditScreen(props) {
  console.log(props);
  const getImages = (base64Image, fileImage) => {
    set_meal_image(base64Image);
  };
  const [meal_name, set_meal_name] = useState(props.menu.meal.meal_name);
  const [meal_name_error, set_meal_name_error] = useState("");
  const [meal_price, set_meal_price] = useState(props.menu.meal.meal_price);
  const [meal_price_error, set_meal_price_error] = useState("");
  const [meal_image, set_meal_image] = useState(props.menu.meal.image);
  const [meal_Description, set_Description] = useState(
    props.menu.meal.description
  );
  const [cuisine_type, set_cuisine_type] = useState(props.menu.meal.category);
  const [meal_status, set_status] = useState(props.menu.meal.status);

  const [combos, setCombos] = useState(props.menu.meal.meal_combo);
  const [subitems, setSubitems] = useState(props.menu.meal.meal_subitem);
  const [openModal, setOpenModal] = useState(false);
  const [comboName, setComboName] = useState("");
  const [comboPrice, setComboPrice] = useState("");
  const [comboStatus, setComboStatus] = useState("Enabled");
  const [openModalSubitem, setOpenModalSubitem] = useState(false);
  const [subitemName, setSubitemName] = useState("");
  const [subitemStatus, setSubitemStatus] = useState("Enabled");
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModalSubitem = () => setOpenModalSubitem(true);
  const handleCloseModalSubitem = () => setOpenModalSubitem(false);
  const handleComboNameChange = (event) => setComboName(event.target.value);
  const handleComboPriceChange = (event) => setComboPrice(event.target.value);
  const handleComboStatusChange = (event) => setComboStatus(event.target.value);
  const handleSubitemNameChange = (event) => setSubitemName(event.target.value);
  const handleSubitemStatusChange = (event) =>
    setSubitemStatus(event.target.value);

  // Function to handle changes to combo fields
  const handleComboChange = (index, field, value) => {
    const updatedCombos = combos.map((combo, comboIndex) => {
      if (index === comboIndex) {
        return { ...combo, [field]: value };
      }
      return combo;
    });
    setCombos(updatedCombos);
  };
  const addNewCombo = () => {
    setCombos([
      ...combos,
      { name: "", price: "", status: "Enabled" }, // Default values for a new combo
    ]);
  };
  const addCombo = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          add_type: "Combo",
          meal_id: props.menu.meal.id,
          query_type: "add_menu_subitem_combo",
          type_name: comboName,
          type_price: comboPrice,
          type_status: comboStatus,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          // Assuming you want to add the new combo to the state for rendering
          setCombos([
            ...combos,
            {
              name: comboName,
              price: comboPrice,
              status: comboStatus,
              id: response.subid, // Assuming the API response contains an 'id' field
            },
          ]);
          handleCloseModal();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  const addSubitem = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          add_type: "SubItem",
          meal_id: props.menu.meal.id,
          query_type: "add_menu_subitem_combo",
          type_name: subitemName,
          type_price: "",
          type_status: subitemStatus,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          // Assuming you want to add the new combo to the state for rendering
          setSubitems([
            ...subitems,
            {
              name: subitemName,
              price: "",
              status: subitemStatus,
              id: response.subid, // Assuming the API response contains an 'id' field
            },
          ]);
          handleCloseModal();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Function to add a new subitem
  const addNewSubitem = () => {
    setSubitems([
      ...subitems,
      { name: "", price: "", status: "Enabled" }, // Default values for a new subitem
    ]);
  };

  // Function to handle changes to subitem fields
  const handleSubitemChange = (index, field, value) => {
    const updatedSubitems = subitems.map((subitem, subitemIndex) => {
      if (index === subitemIndex) {
        return { ...subitem, [field]: value };
      }
      return subitem;
    });
    setSubitems(updatedSubitems);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [availableCuisines, setAvailableCuisines] = useState([]);
  const getAvailableCuisines = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + apiPaths.adminData;
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_meal_categories",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setAvailableCuisines(response.meal_categories);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const postAddMeal = async (values) => {
    values.preventDefault();
    const url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_menu",
          meal_name: meal_name,
          description: meal_Description,
          image: meal_image,
          meal_price: parseFloat(meal_price),
          category: parseInt(cuisine_type),
          status: meal_status,
          vendor_id: props.stallId,
          id: props.menu.meal.id,
          meal_combo: combos,
          meal_subitem: subitems,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          alert("Cuisine added successfully!");
          window.location.href = "/apply-events/events-menu";
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        window.location.href = "/apply-events/events-menu";
      });
  };
  const handleMealNameChange = (event) => {
    set_meal_name(event.target.value);
  };
  const handleMealPriceChange = (event) => {
    set_meal_price(event.target.value);
  };
  const handleMealImageChange = (event) => {
    set_meal_image(event.target.files[0]);
  };
  const handleDescriptionChange = (event) => {
    const inputValue = event.target.value;
    set_Description(inputValue);
  };
  const handleCuisineTypeChange = (event) => {
    set_cuisine_type(event.target.value);
  };
  const handleStatusChange = (event) => {
    set_status(event.target.value);
  };
  useEffect(() => {
    getAvailableCuisines();
  }, []);

  return (
    <Container>
      <div className="form-style-5">
        <form onSubmit={postAddMeal}>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Meal Name:"
                type="text"
                id="meal_name"
                name="meal_name"
                value={meal_name}
                onChange={handleMealNameChange}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <input
                placeholder="Meal Price:"
                type="text"
                id="meal_price"
                name="meal_price"
                value={meal_price}
                onChange={handleMealPriceChange}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleStatusChange}>
                <option
                  selected={meal_status == "Enabled" ? true : false}
                  value={"Enabled"}
                >
                  Enabled
                </option>
                <option
                  selected={meal_status == "Disabled" ? true : false}
                  value={"Disabled"}
                >
                  Disabled
                </option>
              </select>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col lg={12} md={12} sm={12}>
              <select defaultValue="" onChange={handleStatusChange}>
                {availableCuisines.map((item) => {
                  return (
                    <option
                      selected={cuisine_type == item.id ? true : false}
                      value={item.id}
                    >
                      {item.title}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <label htmlFor="logoFile">Upload the Meal Image:</label>
          <ProfileImage
            camera={false}
            returnImage={(base64Image, fileImage) => {
              getImages(base64Image, fileImage);
            }}
            uploadBtnProps={{ variant: "contained", label: "Upload" }}
            defaultImage={
              meal_image &&
              meal_image != "" &&
              meal_image != null &&
              meal_image != "NULL"
                ? AWS_URL + "/" + meal_image
                : require("../../assets/img/empty_logo.png")
            }
          />
          <Row
            style={{
              paddingBottom: 10,
              marginTop: 10,
              borderBottomWidth: 0,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderColor: "black",
              borderStyle: "solid",
            }}
          >
            <Col xs={12} md={12} lg={12}>
              <textarea
                placeholder="Description:"
                type="text"
                rows="4"
                cols="75"
                value={meal_Description}
                onChange={handleDescriptionChange}
              ></textarea>
            </Col>
          </Row>
          <div>
            {/* Combos section */}
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <TextField
                  label="Combo Name"
                  value={comboName}
                  onChange={handleComboNameChange}
                  fullWidth
                />
                <TextField
                  label="Combo Price"
                  value={comboPrice}
                  onChange={handleComboPriceChange}
                  type="number"
                  fullWidth
                />
                <TextField
                  label="Status"
                  select
                  value={comboStatus}
                  onChange={handleComboStatusChange}
                  SelectProps={{
                    native: true,
                  }}
                  fullWidth
                >
                  <option value="Enabled">Enabled</option>
                  <option value="Disabled">Disabled</option>
                </TextField>
                <Button onClick={addCombo}>Add</Button>
              </Box>
            </Modal>

            <div>
              <h5>Combos</h5>
              {combos.map((combo, index) => (
                <Row key={`combo-${index}`} className="mb-3">
                  <Col>
                    <input
                      type="text"
                      value={combo.name}
                      onChange={(e) =>
                        handleComboChange(index, "name", e.target.value)
                      }
                      placeholder="Combo Name"
                      className="form-control"
                    />
                  </Col>
                  <Col>
                    <input
                      type="number"
                      value={combo.price}
                      onChange={(e) =>
                        handleComboChange(
                          index,
                          "price",
                          parseFloat(e.target.value)
                        )
                      }
                      placeholder="Combo Price"
                      className="form-control"
                    />
                  </Col>
                  <Col>
                    <select
                      value={combo.status}
                      onChange={(e) =>
                        handleComboChange(index, "status", e.target.value)
                      }
                      className="form-select"
                    >
                      <option value="Enabled">Enabled</option>
                      <option value="Disabled">Disabled</option>
                    </select>
                  </Col>
                </Row>
              ))}
              <Button onClick={handleOpenModal}>Add Combo</Button>
            </div>
            <Modal
              open={openModalSubitem}
              onClose={handleCloseModalSubitem}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <TextField
                  label="Subitem Name"
                  value={subitemName}
                  onChange={handleSubitemNameChange}
                  fullWidth
                />
                <TextField
                  label="Status"
                  select
                  value={subitemStatus}
                  onChange={handleSubitemStatusChange}
                  SelectProps={{
                    native: true,
                  }}
                  fullWidth
                >
                  <option value="Enabled">Enabled</option>
                  <option value="Disabled">Disabled</option>
                </TextField>
                <Button onClick={addSubitem}>Add</Button>
              </Box>
            </Modal>

            <h5>Subitems</h5>
            {subitems.map((subitem, index) => (
              <Row key={`subitem-${index}`}>
                <Col>
                  <input
                    type="text"
                    value={subitem.name}
                    onChange={(e) =>
                      handleSubitemChange(index, "name", e.target.value)
                    }
                    placeholder="Subitem Name"
                  />
                </Col>
                <Col>
                  <input
                    type="number"
                    value={subitem.price}
                    placeholder="Subitem Price"
                  />
                </Col>
                <Col>
                  <select
                    value={subitem.status}
                    onChange={(e) =>
                      handleSubitemChange(index, "status", e.target.value)
                    }
                  >
                    <option value="Enabled">Enabled</option>
                    <option value="Disabled">Disabled</option>
                  </select>
                </Col>
              </Row>
            ))}
            <Button onClick={handleOpenModalSubitem}>Add Subitem</Button>
          </div>

          <div style={{ width: "100%", textAlign: "right" }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default Sentry.withErrorBoundary(VendorMenuEditScreen, {
  fallback: <ErrorFallbackComponent />,
});
