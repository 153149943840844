import moment from "moment";


export const hasEventExpired = (eventDetails, type) => {
  // Ensure the current timestamp is in the correct format
  var currentTimestamp = moment().format("DD MMM YYYY hh:mm A");

  // Construct event start and end timestamps
  var eventStartTimestamp = moment(eventDetails.start_date + " " + eventDetails.start_time, "DD MMM YYYY hh:mm A");
  var eventEndTimestamp = moment(eventDetails.end_date + " " + eventDetails.end_time, "DD MMM YYYY hh:mm A");

  // Calculate differences with the current timestamp
  var diffWithEnd = eventEndTimestamp.diff(moment(currentTimestamp, "DD MMM YYYY hh:mm A"), "minutes");
  var diffWithStart = eventStartTimestamp.diff(moment(currentTimestamp, "DD MMM YYYY hh:mm A"), "minutes");

  // Adjust the end time difference for specific event types
  if (type === "food") {
    if (
      eventDetails.event_type === "catering" ||
      eventDetails.event_type === "ftims" ||
      eventDetails.event_type === "private"
    ) {
      diffWithEnd += 60;
    }
  }

  // Determine if the event has expired
  if (diffWithEnd < 0) {
    return true;
  } else {
    return false;
  }
};
