import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { AWS_URL } from "../../assets/Constants";

Font.register({
  family: "Roboto",
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const RunsheetPdfCreator = ({ item }) => {
  console.log(item);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            src={AWS_URL + item.vendor__logo}
            style={{ width: 100, height: 100, marginLeft: 20 }}
          />
          <Text
            style={{
              fontSize: 32,
              fontWeight: "strong",
              textAlign: "center",
              textDecoration: "underline",
            }}
          >
            Run Sheet {item.event_name}
          </Text>
          <Text> </Text>
          <Text> </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Job Location: {item.location}
          </Text>

          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Truck Name: {item.vendor__title}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Event Name: {item.event_name}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Event Start Date: {item.event_date}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Event Start Time: {item.start_time}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Event End Time: {item.end_time}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Power Arrengements: {item.power}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Extended Duration in hrs: {item.extend_cut_off_by}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Bump In Date/Time {item.bump_in_date}{" "}
            {" " + item.bump_in_start_time} {" -  " + item.bump_in_end_time}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Bump Out Date/Time {item.bump_out_date}{" "}
            {" " + item.bump_out_start_time} {" -  " + item.bump_out_end_time}
          </Text>

          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Beverage Notes: {item.beverage_notes}
          </Text>

          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Additional Notes: {item.other_notes}
          </Text>

          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Site Contact: {item.site_contact}
          </Text>

          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Site Contact Number: {item.site_contact_number}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: "strong",
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Power: {item.power}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default RunsheetPdfCreator;
