import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import CloseIcon from "@mui/icons-material/Close";
import VendorCateringAddScreen from "../VendorCateringAddScreen";
import VendorCateringEditScreen from "../VendorCateringEditScreen";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Slideshow from "./Slideshow.js";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import "./style.css";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
const AWS_URL = "https://wtt-aws-bucket.s3.amazonaws.com";

class OperatorCateringScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operator_id: null,
      isLoading: false,
      tempPackDetails: [],
      showVendorCateringAddScreen: false,
      showVendorCateringEditScreen: false,
      mealProp: [],
      packDetails: [],
      isLoading: false,
      selectedBrandMeals: [],
      tempSelectedBrandMeals: [],
      selectedVendorId: -1,
      selectedVendorName: "",
    };
    this.abortController = new AbortController();
  }

  componentDidMount() {
    this.getCateringPack();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }

  getCateringPack() {
    const { operator_id } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_catering_packages",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      signal: this.abortController.signal,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
          if (dataResponse.success) {
            console.log(dataResponse);
            this.setState({
              packDetails: dataResponse.menu_details,
              tempPackDetails: dataResponse.menu_details,
            });
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        if (!this.abortController.signal.aborted) {
          this.setState({ isLoading: false });
        }
      });
  }
  render() {
    const {
      packDetails,
      selectedBrandMeals,
      isLoading,
      selectedVendorId,
      selectedVendorName,
    } = this.state;
    return (
      <div>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          {isLoading == false ? (
            <Row>
              <Col
                xs={12}
                md={3}
                lg={3}
                style={{
                  backgroundColor: "#f1f1f1",
                  borderRightWidth: 1,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderStyle: "solid",
                  borderColor: "black",
                  padding: 0,
                }}
              >
                <div>
                  <h3
                    style={{
                      padding: 20,
                      borderBottomWidth: 1,
                      borderTopWidth: 1,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      borderColor: "black",
                      borderStyle: "solid",
                    }}
                  >
                    Trucks
                  </h3>
                </div>
                {packDetails &&
                  packDetails.map((item, index) => {
                    return (
                      <div
                        key={item.stalls__id}
                        style={
                          selectedVendorId == item.stalls__id
                            ? {
                                backgroundColor: "white",
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                            : {
                                cursor: "pointer",
                                padding: 20,
                                borderBottomWidth: 1,
                                borderTopWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                borderColor: "black",
                                borderStyle: "solid",
                              }
                        }
                        onClick={() => {
                          this.setState(
                            {
                              selectedBrandMeals: item.meals,
                              tempSelectedBrandMeals: item.meals,
                              selectedVendorId: item.stalls__id,
                              selectedVendorName: item.stalls__title,
                              showVendorCateringAddScreen: false,
                              showVendorCateringEditScreen: false,
                            },
                            () =>
                              saveToLocalStorage(
                                "selectedVendorId",
                                item.stalls__id
                              )
                          );
                        }}
                      >
                        {item.stalls__title}
                      </div>
                    );
                  })}
              </Col>
              <Col xs={12} md={9} lg={9} className="mobile-padding">
                <Row className="mobile-only">
                  <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <h4>
                      <b>selectedVendorName</b>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          this.onSearch(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={4} lg={4}>
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      onChange={(e) => {
                        this.onSort(e);
                      }}
                    >
                      <option value={1}>A - Z</option>
                      <option value={2}>Z - A</option>
                      <option value={3}>Status: Enabled - Disabled</option>
                      <option value={4}>Status: Disabled - Enabled</option>
                    </Form.Control>
                  </Col>
                  <Col xs={6} md={2} lg={2}>
                    <Button
                      style={{ margin: 10 }}
                      onClick={() => {
                        if (this.state.selectedVendorId == -1) {
                          alert(
                            "Please select ateleast 1 brand before adding the menu"
                          );
                        } else {
                          this.setState({ showVendorCateringAddScreen: true });
                        }
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                {this.state.showVendorCateringAddScreen && (
                  <CloseIcon
                    className="plzHover"
                    fontSize="large"
                    style={{ margin: 1, float: "right" }}
                    onClick={() =>
                      this.setState({ showVendorCateringAddScreen: false })
                    }
                  />
                )}
                {this.state.showVendorCateringAddScreen && (
                  <VendorCateringAddScreen
                    stallId={this.state.selectedVendorId}
                  />
                )}
                {this.state.showVendorCateringEditScreen && (
                  <CloseIcon
                    className="plzHover"
                    fontSize="large"
                    style={{ margin: 1, float: "right" }}
                    onClick={() =>
                      this.setState({ showVendorCateringEditScreen: false })
                    }
                  />
                )}
                {this.state.showVendorCateringEditScreen && (
                  <VendorCateringEditScreen
                    stallId={this.state.selectedVendorId}
                    menu={this.state.mealProp}
                  />
                )}
                {selectedBrandMeals && selectedBrandMeals.length > 0 ? (
                  <Row>
                    {selectedBrandMeals.map((meal, i) => {
                      return (
                        <Col xs={12} md={12} lg={12}>
                          <Media
                            key={i}
                            style={{
                              padding: 10,
                              borderBottomWidth: 1,
                              borderTopWidth: 0,
                              borderLeftWidth: 0,
                              borderRightWidth: 0,
                              borderColor: "black",
                              borderStyle: "solid",
                            }}
                          >
                            <Slideshow
                              style={{ width: 120 }}
                              images={meal.pack_images}
                            />
                            <Media.Body style={{ marginLeft: 10 }}>
                              <Row>
                                <Col xs={12} md={6} lg={6}>
                                  <h4>{meal.package_name}</h4>
                                </Col>
                                <Col
                                  xs={6}
                                  md={2}
                                  lg={2}
                                  style={{ padding: 0 }}
                                >
                                  <h4>
                                    ${Number(meal.package_price).toFixed(2)}
                                  </h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={6} lg={6}>
                                  <h5>
                                    <bold>
                                      {meal.cuisine.charAt(0).toUpperCase() +
                                        meal.cuisine.slice(1)}
                                    </bold>
                                  </h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={6} lg={6}>
                                  {meal.package_description}
                                </Col>
                                <Col
                                  xs={6}
                                  md={2}
                                  lg={2}
                                  style={{ padding: 0 }}
                                >
                                  <h5>{meal.package_people + " People"}</h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} md={6} lg={6}></Col>
                                <Col
                                  xs={6}
                                  md={2}
                                  lg={2}
                                  style={{ padding: 0 }}
                                ></Col>
                                <Col xs={6} md={4} lg={4}>
                                  <Button
                                    onClick={() => {
                                      this.setState({
                                        showVendorCateringEditScreen: true,
                                        mealProp: { meal },
                                      });
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </Col>
                              </Row>
                            </Media.Body>
                          </Media>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div>No results</div>
                )}
              </Col>
            </Row>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    );
  }
  onSearch = (e) => {
    let menuItems = this.state.tempSelectedBrandMeals;
    menuItems = menuItems.filter((el) => {
      return el.meal_name.search(e.target.value) != -1;
    });
    this.setState({ selectedBrandMeals: menuItems });
  };
}
export default Sentry.withErrorBoundary(OperatorCateringScreen, {
  fallback: <ErrorFallbackComponent />,
});
