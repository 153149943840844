import React, { Component } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
class FAQPage extends Component {
  render() {
    return (
      <div>
        <Container
          style={{
            borderWidth: 1,
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 5,
            padding: 15,
          }}
        >
          POS Management
        </Container>
      </div>
    );
  }
}

export default Sentry.withErrorBoundary(FAQPage, {
  fallback: <ErrorFallbackComponent />,
});
