import React, { useState, useEffect } from "react";
import ModalDate from "./ModalDate";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Backdrop from "@mui/material/Backdrop";
import { Box, Modal, Fade, Typography } from "@mui/material";
import "./style.css";
import { Card } from "react-bootstrap";
import CardMedia from "@mui/material/CardMedia";
import "react-phone-input-2/lib/style.css";
import { AWS_URL } from "../../../assets/Constants";
import { ComponentTypes, ItemTypes } from "../constant/Constant";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import RegModal from "./RegModal";
import config from "../../../services/apiConfig";
import { apiPaths } from "../../../services/apiPath";
import moment from "moment";
import "moment-timezone";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getFromLocalStorage } from "../../../store";
import ReadMoreReact from "read-more-react";
import Avatar from "@mui/joy/Avatar";
import AvatarGroup from "@mui/joy/AvatarGroup";
import Divider from "@mui/joy/Divider";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "../../ErrorFallBackScreen";
import { makeStyles } from "@mui/styles";
import { useDroppable } from "@dnd-kit/core";

const applySuccess = () => {
  alert("Eoi submitted successfully!");
  window.location.reload();
};

const useStyles = makeStyles({
  infoIcon: {
    color: "##ff0000",
  },
});

const style = {
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  position: "fixed",
  top: "80%",
  marginTop: "150",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "700px",
  height: "700px",
  overflow: "auto",
  padding: "20px",
};

const ComfirmationComponent = ({
  openNotice,
  setopenNotice,
  vendorList,
  event,
  url,
  setVendorList,
  isSingleDayEvent,
}) => {
  return (
    <Dialog
      open={openNotice}
      onClose={() => setopenNotice(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
      <DialogContent>
        {isSingleDayEvent ? (
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add these vendors to this event?
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-description">
            Please note: This is a multi-day event. Please make sure to review
            all event dates and select your preferred date/s available before
            submitting your application. Vendors who submit without review will
            be applying for all active event dates and committing your
            availability. Click the 'select dates' link above to review or
            continue to 'submit' to apply all dates. WTT team.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            console.log("clicked");
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const vendorId = [];
            vendorList.map((item) => {
              if (
                item.logo === null ||
                item.liablity_certificate === null ||
                item.food_refistration_docs === null
              ) {
                alert(
                  "Please upload all the documents before submitting application"
                );
                window.location.reload();
              } else if (item.meals.length === 0) {
                alert(
                  "Please add meals with images in the system before submitting application"
                );
                window.location.reload();
              } else {
                vendorId.push(item.id);
                const selectedEoiDates = localStorage.getItem(
                  "selected_eoi_dates"
                );
                const raw = JSON.stringify({
                  payload: {
                    body: {
                      query_type: "apply-for-event",
                      eoi_id: event.eventDetails.id,
                      operator_id: JSON.parse(
                        getFromLocalStorage("Operator")
                      )[0].id,
                      vendor_outlets: vendorId,
                      selected_dates: selectedEoiDates,
                    },
                  },
                });
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };
                try {
                  fetch(url, requestOptions).then((response) => {
                    if (response.status == 200) {
                      applySuccess();
                      setopenNotice(false);
                    }
                  });
                } catch (error) {}
              }
            });
          }}
        >
          Confirm
        </Button>
        <Button onClick={() => setopenNotice(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
const EventCard = (props) => {
  const classes = useStyles();
  const { event, appliedEOI, removeVendor, vendors } = props;

  const url = config.devUrl + apiPaths.vendorApplication;
  const [total_applied, setTotal_applied] = useState(0);
  const [vendorList, setVendorList] = useState([]);
  const [appliedVendorList, setAppliedVendorList] = useState([]);
  const [appliedVendorStatus, setAppliedVendorStatus] = useState([]);
  const [openNotice, setopenNotice] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const handleCardClose = () => setShowDetails(false);
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  useEffect(() => {
    // getStatus();
    setVendorList(event.vendor);
    setAppliedVendorList(appliedEOI);
    setAppliedVendorStatus(appliedEOI);
    // console.log(event);
  }, [event]);
  useEffect(() => {
    // Ensure event.eventDetails exists before attempting to call getTargetedEvents
    if (event.eventDetails?.id) {
      getTargetedEvents(event.eventDetails.id);
    }
  }, [event.eventDetails?.id]); // Depend on event.eventDetails?.id to trigger the effect

  const manualApply = () => {
    console.log("Manual Apply");
  };

  const getTargetedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.adminData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_vendors_for_applied_events",
          eoi_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const vendorList = response.event_application;

        const uniqueVendorOutlets = new Set();
        vendorList.forEach((item) => {
          if (item.vendor_outlets !== null) {
            uniqueVendorOutlets.add(item.vendor_outlets);
          }
        });
        const totalApplied = Array.from(uniqueVendorOutlets);
        setTotal_applied(totalApplied.length);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  const getStatus = (vendor_id) => {
    const url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "fetch_status_for_eoi",
          operator_id: 2,
          eoi_id: 91,
          vendor_id: vendor_id,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          const eoi_status = dataResponse.data.status; // Adjust according to your API response structure
          updateVendorStatus(vendor_id, eoi_status);
        } else {
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  const updateVendorStatus = (vendor_id, eoi_status) => {
    console.log(
      "Updating status for vendor:",
      vendor_id,
      "with status:",
      eoi_status
    );
    setAppliedVendorStatus((prevVendors) => {
      return prevVendors.map((vendor) => {
        console.log("Checking vendor:", vendor.id);
        // if (vendor.id === vendor_id) {
        //   console.log("Found matching vendor, updating status...");
        //   return { ...vendor, eoi_stat: eoi_status };
        // }
        return vendor;
      });
    });
  };

  const handleSelectVendor = (vendor) => {
    console.log("Selected vendor:", vendor);
    setSelectedVendors((prevSelected) => [...prevSelected, vendor]);
    setVendorList((prevVendors) => [...prevVendors, vendor]);
    //setOpenModal(false);
  };

  const ApplyModal = ({ open, onClose, vendors = [], handleSelectVendor }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"Vendors"}</DialogTitle>
      <DialogContent>
        {vendors.length > 0 ? (
          vendors.map((vendor, index) => (
            <Paper
              key={index}
              elevation={12}
              style={{ margin: 10, padding: 10 }}
            >
              <div>{vendor.title}</div>
              <Button
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => {
                  handleSelectVendor(vendor);
                  onClose();
                }}
              >
                Select
              </Button>
            </Paper>
          ))
        ) : (
          <div>No vendors available</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const { isOver, setNodeRef } = useDroppable({
    id: event.eventDetails.id,
    data: { ...event, componentType: ComponentTypes.EVENT },
  });

  return (
    <>
      <div ref={setNodeRef}>
        <Card
          style={{
            display: "flex",
            justifyItems: "center",
            backgroundColor: isOver ? "#d2e2fa" : "#f3f1ef",
          }}
          id={event.eventDetails.id}
        >
          {/* <div>
            {total_applied < 5 ? (
              <Alert
                severity="info"
                style={{ backgroundColor: "#c2d6c2", color: "#006600" }}
              >
                🚀 Be an early applicant - {total_applied} Applications
              </Alert>
            ) : total_applied >= 5 && total_applied <= 10 ? (
              <Alert
                severity="info"
                style={{ backgroundColor: "#ffeead", color: "#b69100" }}
              >
                ⏳ Apply Soon - {total_applied} Applications
              </Alert>
            ) : (
              <Alert
                severity="info"
                classes={{
                  icon: classes.infoIcon,
                }}
                style={{ backgroundColor: "#feb4b4", color: "#ff0000" }}
              >
                🔥 Hot Event {total_applied} Applications
              </Alert>
            )}
          </div> */}
          <img
            style={{ marginTop: 10, height: 250 }}
            className="d-block w-100"
            src={
              event.eventDetails && event.eventDetails.eoi_cover
                ? AWS_URL + "/" + event.eventDetails.eoi_cover
                : "path/to/default/image.jpg"
            }
          ></img>
          <Card.Body
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card.Title>
              {event.eventDetails && event.eventDetails.eoi_event_name
                ? event.eventDetails.eoi_event_name
                : ""}
            </Card.Title>
            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

            <Card.Text>EOI APPLICATIONS</Card.Text>
            <Stack
              direction="column"
              spacing={2}
              style={{ maxHeight: 200, overflowY: "auto" }}
            >
              {(appliedVendorList || []).map((vendor) => {
                const bg = {
                  pending: "#fff5cc",
                  accepted: "#c1f0c1",
                  rejected: "#ffc2b3",
                };
                const color = {
                  pending: "#ffcc00",
                  accepted: "#2db92d",
                  rejected: "#e62e00",
                };
                return (
                  <div>
                    <Paper
                      elevation={12}
                      style={{
                        padding: 8,
                        width: "100%",
                        display: "flex",
                        gap: 16,
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1, height: "100%" }}>
                        <img
                          src={vendor?.logo}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <h4 style={{ fontSize: 14 }}>{vendor?.title}</h4>
                        <p
                          style={{
                            fontSize: 10,
                            padding: 4,
                            borderRadius: 4,
                            backgroundColor: bg[vendor?.eoi_stat],
                            color: color[vendor?.eoi_stat],
                            borderWidth: 1,
                            borderColor: color[vendor?.eoi_stat],
                          }}
                        >
                          {vendor?.eoi_stat}
                        </p>
                      </div>
                    </Paper>
                  </div>
                );
              })}
            </Stack>
            <div style={{ marginBottom: "20px" }}></div>
            <Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => setShowDetails(true)}
            >
              View Event
            </Button>
            <div>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: 10 }}
                onClick={() => setOpenModal(true)}
              >
                Apply
              </Button>
              <ApplyModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                vendors={vendors}
                handleSelectVendor={handleSelectVendor}
              />
            </div>
            {selectedVendors.length > 0 && (
              <Button
                variant="contained"
                style={{ margin: 10, backgroundColor: "#ef3f6d" }}
                onClick={() => setopenNotice(true)}
              >
                Submit Application
              </Button>
            )}
            {event.eventDetails && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showDetails}
                onClose={handleCardClose}
              >
                <Box sx={style}>
                  <h1 style={{ textAlign: "center",  }}>
                    {event.eventDetails.eoi_event_name}
                  </h1>
                  <h3>{event.eventDetails.eoi_description}</h3>
                  <div>
                    <strong>Event Location: </strong>
                    {event.eventDetails.event_organiser_address}
                  </div>
                  <div>
                    <strong>Event Bump in Date: </strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.bump_in_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>
                  <div>
                    <strong>Event Bump Out Date: </strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.bump_out_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>
                  <div>
                    <strong>Event Start Date:</strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.event_start_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>
                  <div>
                    <strong>Event End Date: </strong>
                    {" " +
                      moment
                        .utc(event.eventDetails.event_end_date_time)
                        .tz("Australia/Melbourne")
                        .format("DD-MMM-YYYY HH:mm:ss")}
                  </div>

                  <div>
                    <strong>Email: </strong>
                    {event.eventDetails.eoi_email_id}
                  </div>

                  <div>
                    <strong>Water Access: </strong>
                    {event.eventDetails.event_water_access}
                  </div>
                  <div>
                    <strong>Service Type:</strong>
                    {event.eventDetails.services}
                  </div>
                  <div>
                    <strong>Members Commission: </strong>
                    {` ${event.eventDetails.members_commission}  %`}
                  </div>
                  <div>
                    <strong>Non Members Commission: </strong>
                    {` ${event.eventDetails.non_members_commission} %`}
                  </div>
                  <div>
                    <strong>Up Front Site Fees: </strong>
                    {event.eventDetails.site_fees}
                  </div>
                  <div>
                    <strong>Bond Amount: </strong>
                    {event.eventDetails.bond_amount}
                  </div>
                  <div>
                    <a
                      href={
                        AWS_URL +
                        "/" +
                        event.eventDetails.event_terms_and_conditions
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EOI Terms and Conditions
                    </a>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ margin: 10 }}
                      onClick={handleCardClose}
                    >
                      Close
                    </Button>
                  </div>
                </Box>
              </Modal>
            )}
          </Card.Body>
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "auto",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              textAlign: "center",
              overflowX: "scroll",
            }}
          >
            {(vendorList || []).map((vendor, index) => (
              <div key={index}>
                {" "}
                {/* Moved the key prop here */}
                <Paper elevation={12} style={{ margin: 10 }}>
                  <CardMedia
                    onClick={() => setShowModal(true)}
                    style={{
                      width: 100,
                      height: 80,
                      overflow: "hidden",
                    }}
                    image={vendor?.logo}
                    title="vendor_logo"
                  />
                  <RegModal
                    show={showModal}
                    handleClose={handleClose}
                    itemDetail={vendor}
                    viewOnly={true}
                  />
                  {event.eventDetails.is_single_date_event === false ? (
                    <ModalDate eoi_id={event.eventDetails.id} />
                  ) : (
                    <div></div>
                  )}
                  <DeleteForeverOutlinedIcon
                    onClick={() => removeVendor(index, vendor)} // Assuming 'keyIndex' should be 'index' based on context
                    style={{ position: "relative", top: "0px", right: "0px" }}
                  />
                </Paper>
              </div>
            ))}
          </div>
          <ComfirmationComponent
            openNotice={openNotice}
            setopenNotice={setopenNotice}
            vendorList={vendorList}
            event={event}
            setVendorList={setVendorList}
            url={url}
            isSingleDayEvent={event.eventDetails?.is_single_date_event || false}
          />
        </Card>
        {vendorList?.length > 0 && (
          <div className="submit-button">
            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => setopenNotice(true)}
            >
              Submit Application
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default EventCard;
